import React from 'react';
import {withRouter} from 'react-router-dom';
import './manage-donation.css';
import {connect} from 'react-redux';
import {START_LOADING, STOP_LOADING} from '../../../redux/actions/loader';
import {AccountService} from "../../../services/account.service";
import StripeService from "../../../services/stripe.service"
import {Spinner} from '../../common/reusable/spinner/spinner';


class ManageDonation extends React.Component {
    constructor(props) {
        super(props);
        const queryParams = new URLSearchParams(window.location.search)
        let email = queryParams.get('email') || ''
        const user = AccountService.userValue
        if (user) {
            email = user?.data.email
        }
        if (email === "") {
            this.props.history.push({
                pathname: "/error"
            });
        }
        StripeService.create_customer_portal_session(email).then(result => {
            if (result["login_require"]) {
                this.props.history.push({
                    pathname: "/account/login",
                    state: {fromLink: `${this.props.location.pathname}`}
                });
            } else {
                window.location.href = result["customer_portal_url"]
            }
        })
        this.props.START_LOADING();
    }

    componentDidMount() {
        this.props.STOP_LOADING()
    }

    render() {
        return (
            <div className="manage-donation-page">
                <Spinner/>
            </div>
        );
    }
}

export default withRouter(
    connect(null, {START_LOADING, STOP_LOADING})(ManageDonation)
);
