import React from 'react';
import {withRouter, Link} from 'react-router-dom';
import './donation-page.css';
import {START_LOADING, STOP_LOADING} from '../../../redux/actions/loader';
import {connect} from 'react-redux';
import {AccountService} from '../../../services/account.service';
import StripeService from '../../../services/stripe.service';
import {Formik, Form, Field} from 'formik';
import * as Yup from 'yup';


export class DonationPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            amount: 10,
            otherAmount: '',
            frequencyValue: 'payment',
            amountPrint: '10.00',
            amountError: null,
            isLogin: false,
            submitError: '',
        }
        this.props.START_LOADING();
    }

    componentDidMount() {
        const user = AccountService.userValue;
        if (user) {
            let name_arr = []
            if (user.data.firstName.length > 0) {
                name_arr.push(user.data.firstName)
            }
            if (user.data.lastName.length > 0) {
                name_arr.push(user.data.lastName)
            }
            this.setState({
                name: name_arr.join(" "),
                email: user?.data.email,
                isLogin: true
            })
        }
        this.props.STOP_LOADING();
    }

    handleRate = (amount) => {
        this.enableSubmit();
        this.setState({
            amount: parseFloat(amount).toFixed(2),
            amountPrint: parseFloat(amount).toFixed(2),
            amountError: '',
            otherAmount: '',
        })
    }

    handleFrequency = (frequencyName, frequencyValue) => {
        this.setState({
            frequencyName: frequencyName,
            frequencyValue: frequencyValue,
        })
    }

    handleOtherAmountInput = (otherAmount) => {
        if (otherAmount >= 5) {
            this.enableSubmit();
            this.setState({
                amountError: null,

            })
        } else {
            this.disableSubmit();
            this.setState({
                amountError: "Please enter at least $5.",
            })
        }
        this.setState({
            amount: otherAmount,
            otherAmount: otherAmount,
            amountPrint: parseFloat(otherAmount).toFixed(2),

        })
        if (!otherAmount) {
            this.setState({
                amountPrint: "0.00"
            })
        }
    }

    handleSubmit = async (name, email, errors) => {
        if (this.state.amount < 5) {
            this.disableSubmit();
            this.setState({
                amountError: "Please enter an amount."
            })
        } else {
            this.setState({
                amountError: '',
            })
            const param = {
                mode: this.state.frequencyValue,
                amount: parseFloat(this.state.amount).toFixed(2).toString(),
                name: name,
                email: email
            }
            const response = await StripeService.create_checkout_session(param);
            let session_url = ""
            let error_msg = ""
            try {
                session_url = response.session_url
                error_msg = response.error_msg
            } catch {
            }
            if (session_url.length > 0) {
                sessionStorage.setItem('donationAmount', parseFloat(this.state.amount).toFixed(2).toString());
                window.location.replace(session_url);
            } else {
                this.setState({
                    submitError: error_msg
                })
            }
        }
    }

    enableSubmit = () => {
        const submitButton = document.getElementById("donate-button");
        submitButton.disabled = false;
        submitButton.style.color = "white";
        submitButton.style.backgroundColor = "blue";
        submitButton.addEventListener("mouseover", () => {
            submitButton.style.backgroundColor = "green";
        });
        submitButton.addEventListener("mouseout", () => {
            submitButton.style.backgroundColor = "blue";
        });
    }

    disableSubmit = () => {
        const submitButton = document.getElementById("donate-button");
        submitButton.disabled = true;
        submitButton.style.backgroundColor = "grey";
        submitButton.addEventListener("mouseover", () => {
            submitButton.style.backgroundColor = "grey";
        });
        submitButton.addEventListener("mouseout", () => {
            submitButton.style.backgroundColor = "grey";
        });
    }

    validationSchema = () => {
        return Yup.object({
            email: Yup.string().email('Invalid email').required('Email is required'),
            name: Yup.string().required('Name is required'),
        });
    }

    render() {
        return (
            <div className="donation-page">
                <div className="donation-grid">
                    <div className="donation-grid-item item1">
                        <div className="donation-description">
                            <p className="donation-title font-size-large">ScriptureCast® - Casting a new light on the
                                Scriptures.</p>
                            <p>
                                The one comment we receive most about ScriptureCast® is this: <strong><i>"I've never
                                seen anything like this before."</i> </strong>
                                <br/> <br/>
                                We consider this to be an extreme compliment.
                                <br/> <br/>
                                Considering the massive number of books, articles, and lessons created over the last two
                                millennia that have set out to clarify and interpret the contents of the
                                scriptures, <strong><i>ScriptureCast® is unique in that its stated purpose is to let the
                                Bible interpret itself.</i></strong>
                                <br/> <br/>
                                With decades of Biblical study as its foundation, ScriptureCast's simple yet
                                straightforward format lists verses not as stand-alone quotes but as <strong><i>complete
                                concepts presented within the context of Subject, Category, and Topic </i></strong> that
                                encourage exploration and learning.
                                <br/> <br/>
                                The ScriptureCast Inc is a qualified 501(c)(3) tax-exempt organization and <strong>donations
                                are tax-deductible</strong> to the full extent of the law. No goods or services were
                                provided for this gift. Please consult your tax advisor regarding specific questions
                                about your deductions.
                                <br/> <br/>
                                If you have found ScriptureCast® helpful to you and your study of the Scriptures, please
                                consider a gift to help us ensure that ScriptureCast® is sustainable for future
                                generations.
                                <br/> <br/>
                                Thank you.
                            </p>

                            <p className="donation-quote font-size-small">
                                ".… Freely you have received; freely give."
                                <br/>
                                - Matthew 10:8 -
                            </p>
                        </div>
                    </div>

                    <div className="donation-grid-item right-section">
                        <div className="font-size-large"> Donate Now</div>

                        <div className="frequency-select">
                            <input type="radio" name="frequency" value="payment" defaultChecked onClick={() => {
                                this.handleFrequency("", "payment")
                            }}/>
                            <label htmlFor="once" className="f-radio"> ONE TIME</label>
                            <input type="radio" id="monthly" name="frequency" value="monthly" onClick={() => {
                                this.handleFrequency(" /Monthly", "subscription")
                            }}/>
                            <label htmlFor="monthly" className="f-radio"> MONTHLY</label>
                        </div>

                        <div className="rate-group">
                            <div>
                                <button className="rate numrate" onClick={() => {
                                    this.handleRate(5)
                                }}>$5
                                </button>
                            </div>
                            <div>
                                <button className="rate numrate" onClick={() => {
                                    this.handleRate(10)
                                }}>$10
                                </button>
                            </div>
                            <div>
                                <button className="rate numrate" onClick={() => {
                                    this.handleRate(50)
                                }}>$50
                                </button>
                            </div>
                            <div>
                                <button className="rate numrate" onClick={() => {
                                    this.handleRate(100)
                                }}>$100
                                </button>
                            </div>
                            {/* <div><button className="rate numrate" onClick={this.handleOtherAmount}>Other</button></div> */}
                            <div className="rate otherrate"><input id="amount-input" type="number" min="5" step="0.01"
                                                                   placeholder='Other Amount'
                                                                   value={this.state.otherAmount}
                                                                   onChange={(e) => {
                                                                       this.handleOtherAmountInput(e.currentTarget.value)
                                                                   }}
                            /></div>

                            <div className="donation-restriction font-size-small">
                                <p>To combat fraud, ScriptureCast® has temporarily restricted donations to a minimum of
                                    $5. <br/><span className="min-donate">{this.state.amountError}</span></p>
                            </div>


                        </div>

                        {this.state.isLogin &&
                            <div>
                                <button id="donate-button" className="donate-button" onClick={() => {
                                    this.handleSubmit(this.state.name, this.state.email, '')
                                }}>Donate ${this.state.amountPrint}<span>{this.state.frequencyName}</span></button>
                            </div>}

                        {!this.state.isLogin &&

                            <div className="form">
                                <Formik
                                    initialValues={{
                                        name: this.state.name,
                                        email: this.state.email
                                    }}
                                    validationSchema={this.validationSchema}
                                    onSubmit={(values, errors) => this.handleSubmit(values.name, values.email, errors)
                                    }
                                >
                                    {({errors, touched}) => (

                                        <Form>
                                            <div id="info-input">
                                                <label className="info-label" htmlFor="donor-name">Name </label> <br/>
                                                <Field className="input-field" name="name"/>
                                                {errors.name && touched.name &&
                                                    <div className="error">{errors.name}</div>}
                                                <br/>
                                                <label className="info-label" htmlFor="donor-email">Email </label> <br/>
                                                <Field className="input-field" name="email"/>
                                                {errors.email && touched.email &&
                                                    <div className="error">{errors.email}</div>}
                                            </div>
                                            <button id="donate-button" className="donate-button" type="submit">Donate
                                                ${this.state.amountPrint}<span>{this.state.frequencyName}</span>
                                            </button>
                                        </Form>

                                    )}
                                </Formik>
                            </div>}
                        {this.state.submitError && <p className="error">Cannot submit due to an error.</p>}
                        <div>
                            <p className="font-size-small">ScriptureCast® is committed to your privacy; please read
                                our <Link className='link-style1' to="/privacy-policy">privacy policy here</Link>. Your
                                payment details will be processed by <a className='link-style1'
                                                                        href="https://stripe.com/">Stripe</a>, and a
                                record of your donation will be stored by ScriptureCast®. </p>
                            <p className="font-size-small">Problems donating? Please <Link className='link-style1'
                                                                                           to="/about/contact"><span>contact us. </span></Link>
                            </p>
                        </div>

                    </div>

                </div>
            </div>
        );
    }
}

export default withRouter(
    connect(null, {START_LOADING, STOP_LOADING})(DonationPage)
);
