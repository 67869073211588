import React from 'react';
import './language-button.css';


export class LanguageButton extends React.Component {
    render() {
        const {iconSrc, country, iconAlt, onClick, isFocused} = this.props;
        return (
            <div className={`language-option button button-style2 ${isFocused}`} onClick={onClick}>
                <img src={iconSrc} className='flagicon' alt={iconAlt}/>
                <span>{country}</span>
            </div>
        );
    }
}