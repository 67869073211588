import React from 'react';
import {withRouter, Link} from 'react-router-dom';
import './spiritual-gifts-assessment-error.css';
import {connect} from 'react-redux';
import {START_LOADING, STOP_LOADING} from '../../../../redux/actions/loader';
import {BsXCircle} from "react-icons/bs";
import spiritualAssessmentData from '../spiritualGiftsAssessmentData';

class SpiritualGiftsAssessmentError extends React.Component {
    constructor(props) {
        super(props);
        this.props.START_LOADING();
        this.state = {
            translate: spiritualAssessmentData.en.error,
            language: "en",
        }
    }

    componentDidMount() {
        const searchParams = new URLSearchParams(window.location.search);
        let defaultLanguage = searchParams.get('lang');
        if (defaultLanguage) {
            if (defaultLanguage in spiritualAssessmentData) {
                this.setState({
                    language: defaultLanguage,
                    translate: spiritualAssessmentData[defaultLanguage]["error"],
                })
            }
        }
        this.props.STOP_LOADING();
    }

    render() {
        const {translate} = this.state;
        return (
            <div className="spiritual-gifts-assessment-error-page">
                <p className="confirm-icon"><BsXCircle/></p>
                <h1>{translate.TransactionFailed}</h1>
                <p className="try-again">{translate.tryagain1}<Link className='link-style1'
                                                                    to='/'>{translate.here}</Link> {translate.tryagain2}
                    {translate.or}<Link className='link-style1' to="/about/contact">{translate.contactus}</Link></p>
            </div>
        );
    }
}

export default withRouter(
    connect(null, {START_LOADING, STOP_LOADING})(SpiritualGiftsAssessmentError)
);
