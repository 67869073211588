import React from 'react';
import {withRouter} from 'react-router-dom';
import './podcasts.css';
import {connect} from 'react-redux';
import {START_LOADING, STOP_LOADING} from '../../../redux/actions/loader';
import AudioPlayer from '../../page-bible/audio-player/AudioPlayer';
import PodCastsService from '../../../services/podcasts.service';
import {Spinner} from '../../common/reusable/spinner/spinner';

class PodCasts extends React.Component {
    constructor(props) {
        super(props);
        this.props.START_LOADING();
        this.state = {
            tracks: [],
            isLoading: true
        }
    }

    async componentDidMount() {
        let track_info = await PodCastsService.getPodcastTracks();
        this.setState({
            tracks: track_info,
            isLoading: false
        });
        this.props.STOP_LOADING();
    }

    componentWillUnmount() {

    }

    render() {
        const {tracks, isLoading} = this.state;
        return (
            <div>

                {isLoading ? <Spinner/> : <AudioPlayer tracks={tracks}/>}
            </div>
        );
    }
}


export default withRouter(
    connect(null, {START_LOADING, STOP_LOADING})(PodCasts)
);

