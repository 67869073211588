import React from 'react';
import {withRouter, Link} from 'react-router-dom';
import './spiritual-gifts-assessment-checkout-page.css';
import {START_LOADING, STOP_LOADING} from '../../../../redux/actions/loader';
import {connect} from 'react-redux';
import SpiritualGiftsAssessmentService from '../../../../services/spiritual-gifts-assessment.service'
import {AccountService} from '../../../../services/account.service';
import spiritualGiftsAssessmentCheckOutTransDict from "../spiritual-gifts-assessment-checkout-page-trans.json";
import {LanguageButton} from '../../../common/fixed/language-button/language-button';

export class SpiritualGiftsAssessmentCheckoutPage extends React.Component {
    constructor(props) {
        super(props);
        this.props.START_LOADING();
        this.state = {
            name: '',
            email: '',
            accountId: '',
            emailError: false,
            nameError: false,
            error: false,
            user: '',
            defaultLanguage: "en",
            page_content_dict: spiritualGiftsAssessmentCheckOutTransDict.en,
        }
        this.trans_dict = spiritualGiftsAssessmentCheckOutTransDict;
    }

    componentDidMount() {
        const user = AccountService.userValue;
        let name = "";
        let email = "";
        let accountId = "";
        if (user) {
            let name_list = [];
            let first_name = user?.data.firstName;
            if (first_name) {
                name_list.push(first_name);
            }
            let last_name = user?.data.lastName;
            if (last_name) {
                name_list.push(last_name);
            }
            name = name_list.join(" ");
            email = user?.data.email;
            accountId = user?.data.id.toString();
            this.setState({
                user: user,
                name: name,
                email: email,
                accountId: accountId
            })
        }
        const {location} = this.props;
        const searchParams = new URLSearchParams(location.search);
        let defaultLanguage = searchParams.get('lang');
        if (defaultLanguage) {
            if (defaultLanguage in this.trans_dict) {
                this.setState({
                    defaultLanguage: defaultLanguage,
                    page_content_dict: this.trans_dict[defaultLanguage],
                })
            }
        }
        this.props.STOP_LOADING();
    }

    async handleOrder() {
        let name = this.state.name;
        let email = this.state.email;
        let accountId = this.state.accountId;
        let isInputDataValid = true;
        if (!this.validateEmail(email)) {
            isInputDataValid = false;
            this.setState({
                emailError: true,
            })
        } else {
            this.setState({
                emailError: false,
            })
        }
        if (!this.validateName(name)) {
            isInputDataValid = false;
            this.setState({
                nameError: true,
            })
        } else {
            this.setState({
                nameError: false,
            })
        }
        if (isInputDataValid) {
            let checkout_session = null;
            try {
                checkout_session = await SpiritualGiftsAssessmentService.createCheckoutSession(name, email, accountId, this.state.defaultLanguage);
            } catch {
            }
            let checkout_session_url = checkout_session?.session_url;
            if (checkout_session_url) {
                window.location.href = checkout_session_url;
                this.setState({
                    error: false,
                })
            } else {
                this.setState({
                    error: true
                })
            }
        }
    }

    validateEmail(email) {
        const validRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        const emailValidation = validRegex.test(email)
        return emailValidation;
    };

    validateName(name) {
        const nameValidation = !!name;
        return nameValidation;
    }

    handleChangeLanguage(lang) {
        let defaultLanguage = lang;
        if (defaultLanguage === 'es') {
            this.setState({
                defaultLanguage: defaultLanguage,
                page_content_dict: this.trans_dict.es
            })
        } else {
            this.setState({
                defaultLanguage: defaultLanguage,
                page_content_dict: this.trans_dict.en
            })
        }

    }

    render() {
        const {name, nameError, email, emailError, user, error, page_content_dict,defaultLanguage} = this.state;
        // const page_content_dict = this.trans_dict[defaultLanguage];
        return (
            <div className='spiritual-gifts-assessment-checkout-page'>
                <div className='spiritual-gifts-assessment-checkout-page-description'>
                    <h1>{page_content_dict.title}</h1>

                    <p>{page_content_dict.paragraph_1}</p>
                    <p>
                        {page_content_dict.paragraph_2}
                    </p>
                    <p>
                        {page_content_dict.paragraph_3} <br/>
                        {page_content_dict.paragraph_4}
                    </p>
                </div>
                <div className="spiritual-gifts-assessment-checkout-page-payment">
                    <div className='spiritual-gifts-assessment-checkout-client-info'>
                        <div>
                            <label>{page_content_dict.form.name.label}</label> <br/>
                            <input
                                className='gt-checkout-info-input'
                                type='text'
                                value={name}
                                onChange={(e) => this.setState({name: e.currentTarget.value})}
                                readOnly={!!user}
                            >
                            </input>
                            {nameError && <div className='gt-error'>{page_content_dict.form.name.err_msg}</div>}
                        </div>
                        <div>
                            <label>{page_content_dict.form.email.label}</label><br/>
                            <input
                                className='gt-checkout-info-input'
                                type='text'
                                value={email}
                                onChange={(e) => this.setState({email: e.currentTarget.value})}
                                readOnly={!!user}
                            ></input>
                            {emailError && <div className='gt-error'>{page_content_dict.form.email.err_msg}</div>}
                        </div>

                    </div>

                    <div className='spiritual-gifts-assessment-payment-grid'>
                        <div
                            className='spiritual-gifts-assessment-grid-item1'>{page_content_dict.order_info.label}</div>
                        <div
                            className='spiritual-gifts-assessment-grid-item2'>{page_content_dict.order_info.product}</div>
                        <div className='spiritual-gifts-assessment-grid-item3'>x1</div>
                        <div className='spiritual-gifts-assessment-grid-item4'>$ 3.95</div>
                        <div className='spiritual-gifts-assessment-grid-item7'></div>
                        <div className='spiritual-gifts-assessment-grid-item5'>Total</div>
                        <div className='spiritual-gifts-assessment-grid-item6'>$ 3.95</div>
                    </div>
                    <div className='spiritual-gifts-assessment-order-button'>

                        <div className='button button-style2'
                             onClick={() => this.handleOrder()}>{page_content_dict.order_info.button_name}</div>
                        {error && <div>{page_content_dict.order_info.err_msg} <Link
                            to='/about/contact'>{page_content_dict.order_info.contact_us}</Link></div>}
                    </div>
                </div>


                <div>
                    <div className="gt-checkout-language">
                        <LanguageButton
                            iconSrc="/img/english.png"
                            country="English"
                            iconAlt="EN"
                            onClick={() => this.handleChangeLanguage("en")}
                            isFocused={(defaultLanguage==="en")? "focus" :""}
                        />
                        <LanguageButton
                            iconSrc="/img/spanish.png"
                            country="Español"
                            iconAlt="ES"
                            onClick={() => this.handleChangeLanguage("es")}
                            isFocused={(defaultLanguage==="es")? "focus" :""}
                        />
                    </div>
                </div>

            </div>
        );
    }
}

export default withRouter(
    connect(null, {START_LOADING, STOP_LOADING})(SpiritualGiftsAssessmentCheckoutPage)
);
