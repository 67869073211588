const initialState = {
    isLoading: false,
    isLogin: false
};

const loaderReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'START_LOADING':
            return {...state, isLoading: true};
        case 'STOP_LOADING':
            return {...state, isLoading: false};
        case 'SET_LOGIN_STATE':
            return {...state, isLogin: true};
        case 'SET_LOGOUT_STATE':
            return {...state, isLogin: false};
        default:
            return state;
    }
};

export default loaderReducer;
