import React from 'react';
import {withRouter} from 'react-router-dom';
import './login-page.css';
import {Helmet} from 'react-helmet';
import {connect} from 'react-redux';
import {START_LOADING, STOP_LOADING, SET_LOGIN_STATE} from '../../../redux/actions/loader';
import {Link} from 'react-router-dom';
import {Formik, Field, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import {AccountService} from '../../../services/account.service';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";

const initialValues = {
    email: '',
    password: '',
};

const LoginState = {
    Logging: 'Logging',
    Success: 'Success',
    Failure: 'Failure',
};


class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showPassword: false,
            loginStatus: LoginState.Logging
        }
        this.onSubmit = this.onSubmit.bind(this)
        this.handleShowPasswordToggle = this.handleShowPasswordToggle.bind(this)
    }

    handleShowPasswordToggle(event) {
        this.setState((prevState) => ({
            showPassword: !prevState.showPassword,
        }));
    }

    componentDidMount() {
        this.props.START_LOADING();
        // redirect to home if already logged in
        const user = AccountService.userValue;
        this.props.STOP_LOADING();
        if (user) {
            this.props.history.push({
                pathname: '/',
                state: {},
            })
        }
    }

    validationSchema = Yup.object().shape({
        email: Yup.string().email('Email is invalid').required('Email is required'),
        password: Yup.string().required('Password is required'),
    });

    async onSubmit({email, password}, {setSubmitting, setErrors}) {
        // alertService.clear();
        await AccountService.login(email, password)
            .then((res) => {
                if (res.status === 400) {
                    if (res.data?.email) {
                        setErrors({email: res.data?.email});
                        this.setState({
                            "loginStatus": LoginState.Failure
                        })
                        return;
                    }
                    if (res.data?.password) {
                        setErrors({password: res.data?.password});
                        this.setState({
                            "loginStatus": LoginState.Failure
                        })
                        return;
                    }
                    setErrors({
                        email: 'Email or password is incorrect. Please try login again.',
                    });
                    return;
                }

                // Backend - internal server error occured.
                if (res.status === 500) {
                    setErrors({
                        email:
                            'We are sorry. An server error occurred. Please try again or contact the administrator.',
                    });
                    this.setState({
                        "loginStatus": LoginState.Failure
                    })
                    return;
                }
                localStorage.removeItem('timeVisitToday');
                this.props.SET_LOGIN_STATE();
                setTimeout(function(e)
                {
                    window.location.reload();
                },500);
                setTimeout(() => {
                    this.props.history.goBack();
                }, 100);
            })
            .catch((error) => {
                setSubmitting(false);
            });
    }

    render() {
        return (
            <div className='signin-page'>
                <Helmet>
                    <title> Sign in | ScriptureCast</title>
                </Helmet>
                <div className='signin-page-content'>
                    <div className='font-size-title'>ScriptureCast®</div>
                    <div className='signin-form box-style1'>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={this.validationSchema}
                            onSubmit={this.onSubmit}
                        >
                            {({errors, touched, isSubmitting}) => (
                                <Form>
                                    <div className='form-title font-size-large text-center'>Sign In</div>
                                    <div className='form-body'>
                                        <div>
                                            <div className='form-input'>
                                                <div>Email</div>
                                                <Field
                                                    name="email"
                                                    type="email"
                                                    className={
                                                        (errors.email && touched.email ? ' is-invalid' : '')
                                                    }
                                                />
                                            </div>
                                            <ErrorMessage
                                                name="email"
                                                component="div"
                                                className="error-yellow"
                                            />
                                        </div>
                                        <div>
                                            <div className='form-input'>
                                                <div>Password</div>
                                                <div>
                                                    <div className='password-input'>
                                                        <Field
                                                            name="password"
                                                            type={this.state.showPassword ? 'text' : 'password'}
                                                            className={
                                                                (errors.password && touched.password
                                                                    ? ' is-invalid'
                                                                    : '')
                                                            }
                                                        />
                                                        <button className="show-password-button"
                                                                onClick={this.handleShowPasswordToggle}>
                                                            <FontAwesomeIcon
                                                                icon={this.state.showPassword ? faEyeSlash : faEye}/>
                                                        </button>
                                                    </div>
                                                    <div className='width-full font-italic font-size-small'><Link
                                                        className='link-style1' to="forgot-password">Forgot
                                                        Password?</Link></div>

                                                </div>
                                            </div>
                                            <ErrorMessage
                                                name="password"
                                                component="div"
                                                className="error-yellow"
                                            />
                                        </div>
                                        <div className='self-end'>
                                            <button
                                                className='button button-style1'
                                                type="submit"
                                                disabled={isSubmitting}
                                            >
                                                {isSubmitting && (
                                                    <span className="spinner-border spinner-border-sm mr-1"></span>
                                                )}
                                                Sign in
                                            </button>
                                        </div>
                                        <div className='font-size-small self-end'> Not a Member yet?
                                            <span><Link className='link-style1'
                                                        to="register"> Register HERE.</Link></span>
                                            {(this.state.loginStatus === LoginState.Failure) && <><br/>Have problems
                                                logging in? Please <Link to="/about/contact">CONTACT US.</Link></>}
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(
    connect(null, {START_LOADING, STOP_LOADING, SET_LOGIN_STATE})(Login)
);
