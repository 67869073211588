import {connect} from 'react-redux';
import React from 'react';
import {withRouter} from 'react-router';
import {AccountService} from '../../../../../services/account.service';
import './logout-custom-dropdown-item.css';
import {SET_LOGOUT_STATE} from '../../../../../redux/actions/loader';
import NavDropdown from 'react-bootstrap/NavDropdown';

class LogoutCustomDropdownItem extends React.Component {
    constructor() {
        super();
        this.handleLogout = this.handleLogout.bind(this);
    }

    async handleLogout() {
        await AccountService.logout()
            .then(() => {
                window.location.href = "/exit";
                this.props.SET_LOGOUT_STATE();
            })
            .catch((error) => {
            });
    }

    render() {
        const {iconHelp, textHelp} = this.props;
        return (
            < NavDropdown.Item className="dropdown-help-item" href="#log-out" onClick={this.handleLogout}>
                <span className="icon-help">{iconHelp}</span>
                {textHelp}

            </NavDropdown.Item>
        );
    }
}

export default withRouter(
    connect(null, {SET_LOGOUT_STATE})(LogoutCustomDropdownItem)
);
