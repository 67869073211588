import axios from 'axios';
import {API_URL} from '../config/config';

class PodCastsService {
    async getPodcastTracks() {
        const res = await axios.post(`${API_URL}/podcast/get`);
        return res.data;
    }
}


const podCastsServiceInstance = new PodCastsService();
export default podCastsServiceInstance;
