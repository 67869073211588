import axios from 'axios';
import {API_URL} from '../config/config';

class StripeService {
    async create_checkout_session(params) {
        const res = await axios.post(`${API_URL}/stripe/sessions/create`, params)
        return res.data;
    }

    async create_customer_portal_session(email) {
        const res = await axios.post(`${API_URL}/stripe/customers/portal/createSession`, {"email": email}, {withCredentials: true})
        return res.data;
    }
}


const stripeServiceInstance = new StripeService();
export default stripeServiceInstance;