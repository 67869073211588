import axios from 'axios';
import {API_URL} from '../config/config';


class CategoryService {
    async getAllCategoriesBySubject(id) {
        const params = {
            'subject_id': id,
        };
        const res = await axios.post(`${API_URL}/category/listCategoryBySubject`, params);
        return res;
    }

    async getAllCategories() {
        const res = await axios.post(`${API_URL}/category/listCategories`);
        return res;
    }

    async getCategoryById(id) {
        const params = {
            'id': id,
        };
        const res = await axios.post(`${API_URL}/category/getById`, params);
        return res;
    }
}

const categoryServiceInstance = new CategoryService();
export default categoryServiceInstance;
