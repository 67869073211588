import http from "../http-common";
import axios from 'axios';
import {API_URL} from '../config/config';

class TopicService {
    getAllTopic() {
        return http.post('/topic/list');
    }

    async getTopicListByCategory(id) {
        const params = {
            'category_id': id,
        };
        const res = await axios.post(`${API_URL}/topic/listByCategory`, params);
        return res;
    }

    async getTopicDetailByName(verseInfo) {
        const params = {
            'verse_info': verseInfo,
        };
        const res = await axios.post(`${API_URL}/verse/getByName`, params);
        return res;
    }

    async getTopicById(topicId) {
        const params = {
            'topic_id': topicId,
        };
        const res = await axios.post(`${API_URL}/topic/getById`, params);
        return res;
    }
}


const topicServiceInstance = new TopicService();
export default topicServiceInstance;
