import React from 'react';
import {withRouter, Link} from 'react-router-dom';
import './spiritual-gifts-assessment-form.css';
import {START_LOADING, STOP_LOADING} from '../../../../redux/actions/loader';
import {connect} from 'react-redux';
import spiritualGiftsAssessmentData from '../spiritualGiftsAssessmentData';
import parse from 'html-react-parser';
import SpiritualGiftsAssessmentService from '../../../../services/spiritual-gifts-assessment.service';
import {Spinner} from '../../../common/reusable/spinner/spinner';
import {BsPrinterFill} from "react-icons/bs";
import ImageModal from '../../../common/reusable/image-modal/ImageModal';
import ReactToPrint from 'react-to-print';
import {Button, Modal} from 'react-bootstrap';
import {LanguageButton} from '../../../common/fixed/language-button/language-button';

class SpiritualGiftsAssessmentForm extends React.Component {
    constructor(props) {
        super(props);
        this.props.START_LOADING();
        this.inputRef = React.createRef();
        this.state = {
            data: spiritualGiftsAssessmentData.en,
            answers: [],
            isLoading: true,
            chartUrl: '',
            uuid: '',
            isSubmitting: false,
            emailError: false,
            nameError: false,
            urlCopied: false,
            undoneQuestions: [],
            isSaving: false,
            language: "en",
            showModal: false,

        }
    }

    async componentDidMount() {
        const searchParams = new URLSearchParams(window.location.search);
        const uuid = searchParams.get('uuid');
        let res = null;
        try {
            res = await SpiritualGiftsAssessmentService.getForm(uuid);
        } catch {
        }
        const formInfo = res?.formInfo;
        let is_page_valid = true;
        if (formInfo) {
            const savedFormResponse = formInfo?.formResponse;

            let form_responder_name = savedFormResponse?.name;
            if (!form_responder_name) {
                form_responder_name = formInfo.customerName;
            }

            let form_responder_email = savedFormResponse?.email
            if (!form_responder_email) {
                form_responder_email = formInfo.customerEmail;
            }
            let savedAnswer = savedFormResponse?.answers;
            if (!savedAnswer) {
                savedAnswer = new Array(200).fill(-1);
            }
            this.resetUndoneList(savedAnswer);

            let finalResultGiftObject = '';
            let data = this.state.data;
            let form_responder_lanuage = formInfo.defaultLanguage;
            if (form_responder_lanuage === 'es') {
                data = spiritualGiftsAssessmentData.es;
            } else {
                form_responder_lanuage = 'en';
                data = spiritualGiftsAssessmentData.en;
            }

            data.description.content.forEach((item) => {
                if (item.en_title === res.formInfo.gift) {
                    finalResultGiftObject = item
                }
            })

            this.setState({
                data: data,
                uuid: uuid,
                formInfo: formInfo,
                status: formInfo?.formStatus,
                name: form_responder_name,
                email: form_responder_email,
                chartUrl: formInfo?.chartUrl,
                isLoading: false,
                answers: savedAnswer,
                action: '',
                giftObject: finalResultGiftObject,
                validId: true,
                language: form_responder_lanuage,
            })
        } else {
            is_page_valid = false;
        }
        if (!is_page_valid) {
            this.setState({
                validId: false,
                isLoading: false,
            })
        }
        this.props.STOP_LOADING();
    }

    resetUndoneList(answers) {
        let undoneQuestions = [];
        for (let i = 0; i < 200; i++) {
            if (answers[i] === -1) {
                undoneQuestions.push(i);
            }
        }
        this.setState({undoneQuestions: undoneQuestions})
    }

    async handleSubmit() {
        const urlWithoutHash = window.location.href.replace(/#.*$/, '');
        window.history.replaceState(null, null, urlWithoutHash);
        const answers = this.state.answers;
        const name = this.state.name
        const email = this.state.email

        if (this.validateEmail() && this.validateName()) {
            this.setState({
                emailError: false,
                nameError: false,
            })
            if (!answers.includes(-1)) {
                this.setState({
                    isLoading: true,
                })
                const param = {
                    uuid: this.state.formInfo.id,
                    customerName: this.state.formInfo.customerName,
                    customerEmail: this.state.formInfo.customerEmail,
                    formResponse:
                        {
                            name: name,
                            email: email,
                            answers: this.state.answers,
                        },
                    note: '',
                    defaultLanguage: this.state.language,
                }
                await SpiritualGiftsAssessmentService.submitForm(param);
                this.componentDidMount();
            } else {
                this.setState({
                    action: 'missing-anwswers',
                })
                this.resetUndoneList(answers);
                for (let i = 0; i < 200; i++) {
                    const question = document.getElementById('q' + i);
                    if (answers[i] === -1) {
                        question.style.color = 'rgb(255, 170, 168)'
                    } else {
                        question.style.color = 'white'
                    }
                }
                this.setState({
                    isLoading: false,
                })
            }
        } else {
            this.setState({
                action: 'missing-info',
            })
            if (!this.validateEmail()) {
                this.setState({
                    emailError: true,
                })
            } else {
                this.setState({
                    emailError: false,
                })
            }
            if (!this.validateName()) {
                this.setState({
                    nameError: true,
                })
            } else {
                this.setState({
                    nameError: false,
                })
            }
        }

    }

    async handleSave() {
        this.setState({
            isSaving: true,
            action: '',
        })
        const urlWithoutHash = window.location.href.replace(/#.*$/, '');
        window.history.replaceState(null, null, urlWithoutHash);
        const param = {
            uuid: this.state.formInfo.id,
            customerName: this.state.formInfo.customerName,
            customerEmail: this.state.formInfo.customerEmail,
            formResponse: {
                name: this.state.name,
                email: this.state.email,
                answers: this.state.answers,
            },
            note: '',
            defaultLanguage: this.state.language,
        }

        await SpiritualGiftsAssessmentService.updateForm(param);

        this.setState({
            status: "Editing",
            showModal: true,
            isSaving: false,
        })
        this.resetUndoneList(this.state.answers);
    }

    handleClose() {
        this.setState({
            showModal: false,
        })
    }

    handleRadioInput(questionNumber, answerNumber) {
        let newAnswers = this.state.answers;

        newAnswers[questionNumber] = answerNumber;
        this.resetUndoneList(newAnswers);
        this.setState({
            answers: newAnswers,
        })
        const id = 'q' + questionNumber;
        const radio = document.getElementById(id);
        radio.style.color = 'white';
    }

    turnoffRadio(questionNumber, answerNumber) {
        let newAnswers = this.state.answers;
        let emptyRadio = -1;
        if (newAnswers[questionNumber] === answerNumber) {
            newAnswers[questionNumber] = emptyRadio;
            this.resetUndoneList(newAnswers);
            this.setState({
                answers: newAnswers,
            })
        }
    }

    validateEmail() {
        const validRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        const email = this.state.email;
        const emailValidation = validRegex.test(email)
        return emailValidation;
    };

    validateName() {
        const name = this.state.name;
        const nameValidation = !!name;
        return nameValidation;
    }

    copyURL() {
        const currentURL = window.location.href;
        navigator.clipboard.writeText(currentURL)
            .then(() => {
                const tooltip = document.getElementById("gt-copy-tooltip");
                tooltip.style.opacity = "1";
                setTimeout(() => tooltip.style.opacity = "0", 1500);

            })
            .catch((error) => {
                console.error('Failed to copy URL to clipboard:', error);
            });

    }

    async handleChangeLanguage(lang) {
        let language = lang;
        if (language === 'es') {
            this.setState({
                language: language,
                data: spiritualGiftsAssessmentData.es
            })
        } else {
            language = 'en';
            this.setState({
                language: language,
                data: spiritualGiftsAssessmentData.en
            })
        }
        const params =
            {
                "uuid": this.state.uuid,
                "defaultLanguage": language
            }
        await SpiritualGiftsAssessmentService.switchLanguage(params);
    }

    render() {
        const {
            data,
            validId,
            status,
            answers,
            isLoading,
            chartUrl,
            undoneQuestions,
            action,
            giftObject,
            nameError,
            emailError,
            isSaving,
            showModal,
            language
        } = this.state;
        const translate = data.questionaire.sentences;
        const questionaire = data.questionaire;
        const form = data.form;
        return (
            <div>
                {isLoading ? <Spinner/>
                    :
                    <>

                        <div>
                            <div>
                                <div className="gt-checkout-language">
                                    <LanguageButton
                                        iconSrc="/img/english.png"
                                        country="English"
                                        iconAlt="EN"
                                        onClick={() => this.handleChangeLanguage("en")}
                                        isFocused={(language==="en")? "focus" :""}
                                    />
                                    <LanguageButton
                                        iconSrc="/img/spanish.png"
                                        country="Español"
                                        iconAlt="ES"
                                        onClick={() => this.handleChangeLanguage("es")}
                                        isFocused={(language==="es")? "focus" :"" }
                                    />
                                    
                                </div>
                            </div>
                        </div>
                        {(!validId) ?
                            <div className='gt-notification'>
                                {translate.notFound}
                            </div>
                            :
                            <>
                                {(status === 'Submitted') &&
                                    <div className='spiritual-gifts-assessment-form-page'>
                                        <div className='spiritual-gifts-assessment-form-content'>
                                            <div className='spiritual-gifts-assessment-result-sub-div'>
                                                <ReactToPrint
                                                    trigger={() => <div className='gt-print-result'>
                                                        <BsPrinterFill/>{questionaire.button.print}</div>}
                                                    content={() => this.inputRef.current}
                                                />
                                            </div>

                                            <div ref={this.inputRef}
                                                 className='spiritual-gifts-assessment-form-result-to-print'>
                                                <h1>{translate.assessmentResult}</h1>
                                                <div>
                                                    <p>{translate.thankyou}<strong>{giftObject.title}</strong>.</p>
                                                    <p><strong>{translate.yourSpiritualReferences}</strong>
                                                        <span><i> {giftObject.content}</i> </span>
                                                        <span>{giftObject.reference.map((ref, index2) => (
                                                            <a href={ref.link} key={index2} rel="noreferrer"
                                                               target="_blank">
                                                                <span> {ref.title}{(index2 === giftObject.reference.length - 1) ? "." : ';'}</span>
                                                            </a>))}
                                                        </span>
                                                    </p>
                                                    <p>{translate.chartBelow}</p>
                                                </div>

                                                <div className='result-image'>
                                                    <ImageModal img={chartUrl}/>
                                                </div>

                                                <div className='spiritual-gifts-assessment-description'>
                                                    <h2>{data.description.title}</h2>
                                                    <div className='description-list'>
                                                        {data.description.content.map((item, index) => (
                                                            <div key={index}>
                                                                <div><strong>{item.title}:</strong> {item.content}
                                                                    <span>{item.reference.map((ref, index2) => (
                                                                        <a href={ref.link} key={index2} rel="noreferrer"
                                                                           target="_blank">
                                                                            <span> {ref.title}{(index2 === item.reference.length - 1) ? "." : ';'}</span>
                                                                        </a>))}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}

                                {(status === 'Paid' || status === 'Editing') &&
                                    <div className='spiritual-gifts-assessment-form-page'>
                                        <div className='spiritual-gifts-assessment-form-content'>
                                            <div className="function-buttons">
                                                <div className='button button-style1'  onClick={() => this.handleSave()}> 
                                                    {questionaire.button.save}
                                                </div>
                                                <a href='#gt-form-bottom'>
                                                    <div className='button button-style1'> 
                                                        {questionaire.button.goToBottom}
                                                    </div>
                                                </a>
                                            </div>
                                            <div className='spiritual-gifts-assessment-opening'>
                                                <h1>{questionaire.title}</h1>
                                                <h3>{questionaire.subtitle}</h3>
                                                <div id='client-info'
                                                     className='spiritual-gifts-assessment-client-info'>
                                                    <div>
                                                        <label>{form.name}*</label> <br/>
                                                        <input
                                                            className='gt-info-input'
                                                            type='text'
                                                            value={this.state.name}
                                                            onChange={(e) => this.setState({name: e.currentTarget.value})}></input>
                                                        {nameError &&
                                                            <div className='gt-error'>{form.error.nameIsRequired}</div>}
                                                    </div>
                                                    <div>
                                                        <label>{form.email}*</label><br/>
                                                        <input
                                                            className='gt-info-input'
                                                            type='text'
                                                            value={this.state.email}
                                                            onChange={(e) => this.setState({email: e.currentTarget.value})}
                                                        ></input>
                                                        {emailError && <div
                                                            className='gt-error'>{form.error.valideMailIsRequired}</div>}
                                                    </div>
                                                </div>

                                            </div>

                                            <div className='spiritual-gifts-assessment-instruction'>
                                                <h2>{data.instruction.title}</h2>
                                                <div
                                                    className='instruction-content'>{parse(data.instruction.content)}</div>
                                            </div>

                                            <div className='spiritual-gifts-assessment-form'>
                                                <h2>{questionaire.questiontitle}</h2>
                                                <div className='question-list'>
                                                    {data.questions.map((item, qIndex) => (
                                                        <div key={qIndex} id={'q' + qIndex}>
                                                            <div className='gt-question'>{item.id}. {item.content}<span
                                                                className='required'>{item.required ? '*' : ''}</span>
                                                            </div>
                                                            <div className='answer-list'>
                                                                {data.answers.map((item, aIndex) => (
                                                                    <div className='answer-item' key={aIndex}>
                                                                        <input
                                                                            type='radio'
                                                                            name={qIndex}
                                                                            className='gt-input'
                                                                            id={'a' + aIndex + 'q' + qIndex}
                                                                            value={aIndex}
                                                                            checked={answers[qIndex] === aIndex}
                                                                            onChange={() => this.handleRadioInput(qIndex, aIndex)}
                                                                            onClick={() => this.turnoffRadio(qIndex, aIndex)}
                                                                        />
                                                                        <div>{item.content}</div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>

                                                <div id="gt-form-bottom">
                                                    <div className='spiritual-gifts-assessment-form-buttons-div'>
                                                        <div className='spiritual-gifts-assessment-form-buttons'
                                                             onClick={() => this.handleSubmit()}>
                                                            {questionaire.button.submit}
                                                        </div>
                                                    </div>
                                                    <div className='gt-event-notification'>
                                                        {isSaving && <div>{translate.Saving}</div>}
                                                        {action === 'missing-anwswers' && undoneQuestions.length > 0 &&
                                                            <div
                                                                className='gt-event-notification-action gt-save-noti-unsuccess'> {translate.completequestion1}{undoneQuestions.length}{translate.completequestion2} </div>}
                                                        {action === 'missing-info' && <a href='#client-info'
                                                                                         className='gt-event-notification-action gt-save-noti-unsuccess'> {translate.remindnNameAndEmail}</a>}
                                                    </div>
                                                </div>

                                                {undoneQuestions.length > 0 &&
                                                    <>
                                                        <div
                                                            id='undone-section'>{translate.questionsToBeCompleted}</div>
                                                        <div className='gt-undone-div'>
                                                            {undoneQuestions.map((item, index) => <a
                                                                className='gt-undone-item' href={'#q' + item}
                                                                key={index}>
                                                                <div>{item + 1}</div>
                                                            </a>)}
                                                        </div>
                                                    </>}

                                                <Modal show={showModal} onHide={() => this.handleClose()}>
                                                    <Modal.Header closeButton>
                                                        <Modal.Title>{data.questionaire.notification}</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <div className='gt-save-notification-modal'>
                                                            <div>
                                                                <span>{data.questionaire.sentences.saveModalContent1}</span><br/>
                                                                <span>{data.questionaire.sentences.saveModalContent2}</span>
                                                            </div>
                                                            <div className='gt-save-copy-link'>
                                                                <div className='gt-save-copy-link-url'
                                                                     onClick={() => this.copyURL()}>{window.location.href}</div>
                                                                <Button className='gt-save-copy-link-button'
                                                                        onClick={() => this.copyURL()}>{data.questionaire.button.copy}</Button>
                                                            </div>
                                                            <div id='gt-copy-tooltip'>{translate.copied}</div>
                                                        </div>
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <Button variant="secondary" onClick={() => this.handleClose()}>
                                                            {data.questionaire.button.close}
                                                        </Button>
                                                    </Modal.Footer>
                                                </Modal>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {(status === 'Unpaid') &&
                                    <div className="gt-notification">
                                        <div>{translate.paymentIssue}</div>
                                        <Link to="/about/contact">{translate.contactForSupports}</Link>
                                        <div>{translate.apologize}</div>
                                    </div>
                                }
                            </>

                        }
                    </>}
            </div>

        );
    }
}

export default withRouter(connect(null, {START_LOADING, STOP_LOADING})(SpiritualGiftsAssessmentForm));