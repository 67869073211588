import React from 'react';
import {withRouter} from 'react-router-dom';
import './privacy-policy.css';
import {connect} from 'react-redux';
import {START_LOADING, STOP_LOADING} from '../../../redux/actions/loader';

class PrivacyPolicy extends React.Component {
    constructor(props) {
        super(props);
        this.props.START_LOADING();
    }

    componentDidMount() {
        this.props.STOP_LOADING();
    }


    render() {
        return (
            <div className="privacy-policy-page">
                <div className='privacy-policy-content'>
                    <h1>PRIVACY POLICY</h1>
                    <div className='privacy-policy-text'>
                        <div><strong>Last updated March 02, 2023</strong></div>
                        <div id="policy-intro">
                            <div>
                                This privacy notice for ScriptureCast Inc.
                                (<strong>"Company"</strong>, <strong>"we"</strong>, <strong>"us"</strong>,
                                or <strong>"our"</strong> ),
                                describes how and why we might collect, store, use, and/or share
                                (<strong>"process"</strong>) your
                                information when you use our services (<strong>"Services"</strong>), such as when you:
                            </div>
                            <ul>
                                <li>Visit our website at ScriptureCast.com, or any website of ours that links to this
                                    privacy notice
                                </li>
                                <li>Engage with us in other related ways, including any sales, marketing, or
                                    events
                                </li>
                            </ul>
                            <div>
                                <strong>Questions or concerns? </strong>Reading this privacy notice will help you
                                understand your
                                privacy rights and choices. If you do not agree with our policies and practices, please
                                do not use our Services. If you still have any questions or concerns, please contact
                                us at info@ScriptureCast.com.
                            </div>
                        </div>

                        <div id="policy-summary">
                            <h2>SUMMARY OF KEY POINTS</h2>
                            <div><strong><i>
                                This summary provides key points from our privacy notice, but you can find
                                out more details about any of these topics by clicking the link following each
                                key point or by using our table of contents below to find the section you are
                                looking for. You can also click
                                <a href="#table-of-content"> here </a>
                                to go directly to our table of contents.
                            </i></strong>

                                <br/> <br/>
                                <strong>What personal information do we process? </strong>
                                When you visit, use, or navigate our
                                Services, we may process personal information depending on how you interact with
                                ScriptureCast Inc. and the Services, the choices you make, and the products and
                                features you use. Click
                                <a href="#p1"> here </a>
                                to learn more.
                                <br/> <br/>
                                <strong>Do we process any sensitive personal information? </strong>
                                We do not process sensitive
                                personal information.
                                <br/> <br/>
                                <strong>Do we receive any information from third parties? </strong>
                                We do not receive any
                                information from third parties.
                                <br/> <br/>
                                <strong>How do we process your information? </strong>
                                We process your information to provide,
                                improve, and administer our Services, communicate with you, for security and fraud
                                prevention, and to comply with law. We may also process your information for other
                                purposes with your consent. We process your information only when we have a valid
                                legal reason to do so. Click
                                <a href="#p2"> here </a>
                                to learn more.
                                <br/> <br/>
                                <strong>In what situations and with which parties do we share personal
                                    information? </strong>
                                We may share information in specific situations and with specific third parties.
                                Click
                                <a href="#p4"> here </a>
                                to learn more.
                                <br/> <br/>
                                <strong>How do we keep your information safe? </strong>
                                We have organizational and technical guaranteed to be 100% secure, so we cannot promise
                                or guarantee that hackers, cybercriminals, or other
                                unauthorized third parties will not be able to defeat oursecurity and improperly
                                collect, access, steal, or modify your information.Click
                                <a href="#p7"> here </a>
                                to learn more.
                                <br/> <br/>
                                <strong>What are your rights? </strong>
                                Depending on where you are located geographically, the applicable privacy law may mean
                                you have certain rights regarding your personal information. Click
                                <a href="#p9"> here </a>
                                to learn more.
                                <br/> <br/>
                                <strong>How do you exercise your rights? </strong>
                                The easiest way to exercise your rights is by filling out our data subject request form
                                available
                                <a href="#aaa"> here </a>
                                , or by contacting us. We will consider and act upon any request in accordance with
                                applicable data protection laws.
                                <br/> <br/>
                                Want to learn more about what
                                ScriptureCast Inc.
                                does with any information we collect? Click
                                <a href="#table-of-content"> here </a>
                                to review the notice in full.
                            </div>
                            <br/>


                        </div>

                        <div id="table-of-content">
                            <h2>TABLE OF CONTENTS</h2>
                            <a href="#p1">1. WHAT INFORMATION DO WE COLLECT?</a> <br/>
                            <a href="#p2">2. HOW DO WE PROCESS YOUR INFORMATION?</a> <br/>
                            <a href="#p3">3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR
                                PERSONALINFORMATION?</a><br/>
                            <a href="#p4">4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a><br/>
                            <a href="#p5">5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</a><br/>
                            <a href="#p6">6. HOW LONG DO WE KEEP YOUR INFORMATION?</a><br/>
                            <a href="#p7">7. HOW DO WE KEEP YOUR INFORMATION SAFE?</a><br/>
                            <a href="#p8">8. DO WE COLLECT INFORMATION FROM MINORS?</a><br/>
                            <a href="#p9">9. WHAT ARE YOUR PRIVACY RIGHTS?</a><br/>
                            <a href="#p10">10. CONTROLS FOR DO-NOT-TRACK FEATURES</a> <br/>
                            <a href="#p11">11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</a> <br/>
                            <a href="#p12">12. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</a> <br/>
                            <a href="#p13">13. DO WE MAKE UPDATES TO THIS NOTICE?</a> <br/>
                            <a href="#p14">14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a> <br/>
                            <a href="#p15">15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECTFROM YOU?</a>
                            <br/><br/>
                        </div>

                        <div id="p1">
                            <h2>1. WHAT INFORMATION DO WE COLLECT?</h2>
                            <h3>Personal information you disclose to us</h3>

                            <div>
                                <i>
                                    <strong>In Short: </strong>
                                    We collect personal information that you provide to us.
                                </i>
                                <br/> <br/>
                                We collect personal information that you voluntarily provide to us when you
                                registeron the Services,
                                express an interest in obtaining information about us or our products and Services, when
                                you participate in activities on the Services, or otherwise when you contact us.
                                <br/> <br/>
                                <strong id="personal-info">Personal Information Provided by You.</strong>
                                The personal information that we collect depends on the context of your interactions
                                with us and the Services, the choices you make, and the products and features you use.
                                The personal information we collectmay include the following:
                                <ul>
                                    <li>names</li>
                                    <li>phone numbers</li>
                                    <li>email addresses</li>
                                </ul>
                                <strong>Sensitive Information.</strong>

                                We do not process sensitive information.
                                <br/> <br/>
                                <strong>Payment Data.</strong>

                                We may collect data necessary to process your payment if you make purchases, such as
                                your payment instrument number, and the security code associated with your payment
                                instrument. All payment data is stored by
                                Stripe.com
                                .You may find their privacy notice link(s) here:
                                <a href="https://stripe.com/privacy">https://stripe.com/privacy</a>
                                .
                                <br/>
                                All personal information that you provide to us must be true, complete, and accurate,
                                and you must notify us of any changes to such personal information.
                            </div>
                            <br/>

                            <h3>Information automatically collected</h3>
                            <div>
                                <i>
                                    <strong>In Short: </strong>
                                    Some information — such as your Internet Protocol (IP) address and/or browser and
                                    device characteristics — is collected automatically when you visit our Services.
                                </i>


                                <br/> <br/>
                                We automatically collect certain information when you visit, use, or navigate the
                                Services. This information does not reveal your specific identity (like your name or
                                contact information) but may include device and usage information, such as your
                                IPaddress, browser and device characteristics, operating system, languagepreferences,
                                referring URLs, device name, country, location, information about howand when you use
                                our Services, and other technical information. This information isprimarily needed to
                                maintain the security and operation of our Services, and for ourinternal analytics and
                                reporting purposes.
                                <br/> <br/>
                                Like many businesses, we also collect information through cookies and similar
                                technologies.
                                <br/> <br/>
                                The information we collect includes:
                                <ul>
                                    <li>
                                        Log and Usage Data.
                                        Log and usage data is service-related, diagnostic, usage, and performance
                                        information our servers automatically collect when you access or use our
                                        Services and which we record in log files. Depending onhow you interact with us,
                                        this log data may include your IP address, deviceinformation, browser type, and
                                        settings and information about your activity inthe Services
                                        (such as the date/time stamps associated with your usage, pages and files
                                        viewed, searches, and other actions you take such as which features you use),
                                        device event information (such as system activity, error reports(sometimes
                                        called
                                        "crash dumps"
                                        ), and hardware settings).
                                    </li>
                                    <li>
                                        Location Data.
                                        We collect location data such as information about your device's location, which
                                        can be either precise or imprecise. How much information we collect depends on
                                        the type and settings of the device you useto access the Services. For example,
                                        we may use GPS and other technologiesto collect geolocation data that tells us
                                        your current location (based on your IPaddress). You can opt out of allowing us
                                        to collect this information either byrefusing access to the information or by
                                        disabling your Location setting on yourdevice. However, if you choose to opt
                                        out, you may not be able to use certainaspects of the Services.
                                    </li>
                                </ul>

                            </div>
                        </div>

                        <div id="p2">
                            <h2>2. HOW DO WE PROCESS YOUR INFORMATION?</h2>
                            <div>
                                <i>
                                    <strong>In Short: </strong>
                                    We process your information to provide, improve, and administer our Services,
                                    communicate with you, for security and fraud prevention, and to comply with law. We
                                    may also process your information for other purposes with your consent.
                                </i>
                                <br/> <br/>
                                <strong>We process your personal information for a variety of reasons, depending on how
                                    you interact with our Services, including:</strong>
                            </div>
                            <ul>
                                <li>
                                    <strong>To facilitate account creation and authentication and otherwise manage user
                                        accounts.</strong>

                                    We may process your information so you can create and login to your account, as well
                                    as keep your account in working order.
                                </li>
                                <li>
                                    <strong>To deliver and facilitate delivery of services to the user.</strong>

                                    We may process your information to provide you with the requested service.
                                </li>
                                <li>
                                    <strong>To respond to user inquiries/offer support to users.</strong>

                                    We may process your information to respond to your inquiries and solve any potential
                                    issues youmight have with the requested service.
                                </li>
                                <li>
                                    <strong>To save or protect an individual's vital interest.</strong>

                                    We may process your information when necessary to save or protect an individual's
                                    vital interest,such as to prevent harm.
                                </li>
                            </ul>
                        </div>

                        <div id="p3">
                            <h2>3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</h2>
                            <div>
                                <i>
                                    <strong>In Short: </strong>
                                    We only process your personal information when we believe it is necessary and we
                                    have a valid legal reason (i.e.,legal basis) to do so under applicable law, like
                                    with your consent, to comply with laws, to provide you with services to enter into
                                    or
                                    fulfill our contractual obligations, to protect your rights, or to fulfill our
                                    legitimate business interests.
                                </i>
                                <br/> <br/>
                                <strong><i><u>If you are located in the EU or UK, this section applies to
                                    you.</u></i></strong>

                                <br/> <br/>
                                The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the
                                valid legal bases we rely on in order to process your personal information. As such, we
                                may rely on the following legal bases to process your personal information:
                                <br/> <br/>
                                <ul>
                                    <li>
                                        <strong>Consent. </strong>
                                        We may process your information if you have given us permission (i.e.
                                        ,
                                        consent) to use your personal information for a specific purpose. You can
                                        withdraw your consent at any time. Click
                                        <a href="#withdraw-consent"> here </a>
                                        to learn more.
                                    </li>
                                    <li>
                                        <strong>Performance of a Contract. </strong>
                                        We may process your personal information whenwe believe it is necessary to
                                        fulfill
                                        our contractual obligations to you, including providing our Services or at your
                                        request prior to entering into a contract with you.
                                    </li>
                                    <li>
                                        <strong>
                                            Legal Obligations.
                                        </strong>
                                        We may process your information where we believe it is necessary for compliance
                                        with our legal obligations, such as to cooperate with a law enforcement body or
                                        regulatory agency, exercise or defend our legalrights, or disclose your
                                        information as evidence in litigation in which we areinvolved.
                                    </li>
                                    <li>
                                        <strong>Vital Interests. </strong>
                                        We may process your information where we believe it is necessary to protect your
                                        vital interests or the vital interests of a third party, such as situations
                                        involving potential threats to the safety of any person.
                                    </li>
                                </ul>

                                <br/> <br/>
                                <strong><i><u>If you are located in Canada, this section applies to
                                    you.</u></i></strong>

                                <br/> <br/>
                                We may process your information if you have given us specific permission (i.e.
                                ,
                                express consent) to use your personal information for a specific purpose, or
                                insituations where your permission can be inferred (i.e.
                                ,
                                implied consent). You can withdraw your consent at any time. Click
                                <a href="#withdraw-consent"> here </a>
                                to learn more.
                                <br/> <br/>
                                In some exceptional cases, we may be legally permitted under applicable law to process
                                your information without your consent, including, for example:
                                <br/> <br/>
                                <ul>
                                    <li>
                                        If collection is clearly in the interests of an individual and consent cannot be
                                        obtained in a timely way
                                    </li>
                                    <li>
                                        For investigations and fraud detection and prevention
                                    </li>
                                    <li>
                                        For business transactions provided certain conditions are met
                                    </li>
                                    <li>
                                        If it is contained in a witness statement and the collection is necessary to
                                        assess, process, or settle an insurance claim
                                    </li>
                                    <li>
                                        For identifying injured, ill, or deceased persons and communicating with next of
                                        kin
                                    </li>
                                    <li>
                                        If we have reasonable grounds to believe an individual has been, is, or may be
                                        victim of financial abuse
                                    </li>
                                    <li>
                                        If it is reasonable to expect collection and use with consent would compromise
                                        the availability or the accuracy of the information and the collection is
                                        reasonable for purposes related to investigating a breach of an agreement or
                                        acontravention of the laws of Canada or a province
                                    </li>
                                    <li>
                                        If disclosure is required to comply with a subpoena, warrant, court order, or
                                        rules of the court relating to the production of records
                                    </li>
                                    <li>
                                        If it was produced by an individual in the course of their employment, business,
                                        or profession and the collection is consistent with the purposes for which the
                                        information was produced
                                    </li>
                                    <li>
                                        If the collection is solely for journalistic, artistic, or literary purposes
                                    </li>
                                    <li>
                                        If the information is publicly available and is specified by the regulations
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div id="p4">
                            <h2>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h2>
                            <div>
                                <i>
                                    <strong>In Short: </strong> We may share information in specific situations
                                    described in this section and/or with the following
                                    third parties.

                                </i>
                                <br/> <br/>
                                We may need to share your personal information in the following situations:
                                <ul>
                                    <li>
                                        We may share or transfer your information in connection with, or during
                                        negotiations of, any merger, sale of company assets, financing,
                                    </li>
                                </ul>
                                <strong>Business Transfers.</strong>
                            </div>
                        </div>

                        <div id="p5">
                            <h2>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h2>
                            <div>
                                <i>
                                    <strong>In Short: </strong>
                                    We may use cookies and other tracking technologies to collect and store your
                                    information.
                                </i>
                                <br/> <br/>
                                We may use cookies and similar tracking technologies (like web beacons and pixels) to
                                access or store information. Specific information about how we use such technologies and
                                how you can refuse certain cookies is set out in our Cookie Notice.
                            </div>
                        </div>

                        <div id="p6">
                            <h2>6. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
                            <div>
                                <i>
                                    <strong>In Short: </strong> We keep your information for as long as necessary to
                                    fulfill
                                    the purposes outlined in this privacy notice unless otherwise required by law.
                                </i>
                                <br/> <br/>
                                We will only keep your personal information for as long as it is necessary for the
                                purposes set out in this privacy notice, unless a longer retention period is required or
                                permitted by law (such as tax, accounting, or other legal requirements).
                                No purpose in this notice will require us keeping your personal information for longer
                                than
                                the period of time in which users have an account with us
                                .
                                <br/> <br/>
                                When we have no ongoing legitimate business need to process your personal information,
                                we will either delete or
                                anonymize
                                such information, or, if this is not possible (for example, because your personal
                                information has been stored in backup archives), then we will securely store your
                                personal information and isolate it fromany further processing until deletion is
                                possible.
                            </div>
                        </div>
                        <div id="p7">
                            <h2>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
                            <div>
                                <i>
                                    <strong>In Short: </strong>
                                    We aim to protect your personal information through a system of
                                    organizational
                                    and technical security measures.
                                </i>
                                <br/> <br/>
                                We have implemented appropriate and reasonable technical and
                                organizational
                                security measures designed to protect the security of any personal information we
                                process. However, despite our safeguards and efforts to secure your information, no
                                electronic transmission over the Internet or information storage technology can be
                                guaranteed to be 100% secure, so we cannot promise or guarantee that hackers,
                                cybercriminals, or other
                                unauthorized
                                third parties will not be able to defeat our security and improperly collect, access,
                                steal, or modify your information. Although we will do our best to protect your personal
                                information, transmission of personal information to and from our Services is at your
                                own risk. You should only access the Services within a secure environment.
                            </div>
                        </div>
                        <div id="p8">
                            <h2>8. DO WE COLLECT INFORMATION FROM MINORS?</h2>
                            <div>
                                <i>
                                    <strong>In Short: </strong>
                                    We do not knowingly collect data from or market to
                                    children under 18 yearsof age
                                    .
                                </i>
                                <br/> <br/>
                                We do not knowingly solicit data from or market to children under 18 years of age. By
                                using the Services, you represent that you are at least 18 or that you are the parentor
                                guardian of such a minor and consent to such minor dependent's use of the Services. If
                                we learn that personal information from users less than 18 years of agehas been
                                collected, we will deactivate the account and take reasonable measures topromptly delete
                                such data from our records. If you become aware of any data wemay have collected from
                                children under age 18, please contact us at
                                info@ScriptureCast.com
                                .
                            </div>
                        </div>

                        <div id="p9">
                            <h2>9. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
                            <div>
                                <i>
                                    <strong>In Short: </strong>
                                    In some regions, such as
                                    the European Economic Area (EEA), United Kingdom (UK), and Canada
                                    , you have rights that allow you greater access to and control over your personal
                                    information.
                                    You may review, change, or terminate your account at any time.
                                    In some regions (like
                                    the EEA, UK, and Canada
                                    ), you have certain rights under applicable data protection laws. These may include
                                    the right (i) to request access andobtain a copy of your personal information, (ii)
                                    to request rectification or erasure; (iii)to restrict the processing of your
                                    personal information; and (iv) if applicable, to dataportability. In certain
                                    circumstances, you may also have the right to object to the processing of your
                                    personal information. You can make such a request by contacting us by using the
                                    contact details provided in the section
                                    "
                                    <a href="#p14">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>
                                    "
                                    below.
                                </i>
                                <br/> <br/>
                                We will consider and act upon any request in accordance with applicable dataprotection
                                laws.
                                If you are located in the EEA or UK and you believe we are unlawfully processing your
                                personal information, you also have the right to complain to your local dataprotection
                                supervisory authority. You can find their contact details here:
                                <a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.html"> https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.html </a>

                                .
                                <br/> <br/>
                                If you are located in Switzerland, the contact details for the data protection
                                authorities are available here:
                                <a href="https://www.edoeb.admin.ch/edoeb/en/home.html"> https://www.edoeb.admin.ch/edoeb/en/home.html </a>
                                .
                                <br/> <br/>
                                <strong id="withdraw-consent"><u>Withdrawing your consent:</u></strong>
                                If we are relying on your consent to process your personal information,
                                which may be express and/or implied consent depending on the applicable law,
                                you have the right to withdraw your consent at any time. You can withdraw your consent
                                at any time by contacting us by using the contact details provided in the section
                                "
                                <a href="#p14">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>
                                "
                                below
                                or updating your preferences
                                .
                                <br/> <br/>
                                However, please note that this will not affect the lawfulness of the processing before
                                its withdrawal nor,
                                when applicable law allows,
                                will it affect the processing of your personal information conducted in reliance on
                                lawful processing grounds other than consent.
                                <br/> <br/>
                                <strong><u>Opting out of marketing and promotional communications:</u></strong>
                                You can unsubscribe from our marketing and promotional communications at any time by
                                clicking on the unsubscribe link in the emails that we send,
                                or by contacting us using the details provided in the section
                                "
                                <a href="#p14">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>
                                "
                                below. You will then be removed from the marketing lists. However, we may still
                                communicate with you — for example, to send you service-related messages that are
                                necessary for the administration and use of your account, to respond to service
                                requests, or for other non-marketing purposes.
                            </div>

                            <h3>Account Information</h3>
                            <div>
                                If you would at any time like to review or change the information in your account or
                                terminate your account, you can:
                                <ul>
                                    <li>
                                        Sign in to your account settings and update your user account.
                                    </li>
                                </ul>
                                Upon your request to terminate your account, we will deactivate or delete your account
                                and information from our active databases. However, we may retain some information in
                                our files to prevent fraud, troubleshoot problems, assist with any investigations,
                                enforce our legal terms and/or comply with applicable legal requirements.
                                <br/> <br/>
                                <strong><u>Cookies and similar technologies: </u></strong>

                                Most Web browsers are set to accept cookiesby default. If you prefer, you can usually
                                choose to set your browser to remove cookies and to reject cookies. If you choose to
                                remove cookies or reject cookies, this could affect certain features or services of our
                                Services. To opt out of interest-based advertising by advertisers on our Services visit
                                <a href="http://www.aboutads.info/choices/"> http://www.aboutads.info/choices/ </a>
                                .
                                <br/> <br/>
                                If you have questions or comments about your privacy rights, you may email us at
                                info@ScriptureCast.com
                                .
                            </div>
                        </div>

                        <div id="p10">
                            <h2>10. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
                            <div>
                                Most web browsers and some mobile operating systems and mobile applications include a
                                Do-Not-Track (
                                "DNT"
                                ) feature or setting you can activate to signal yourprivacy preference not to have data
                                about your online browsing activities monitored and collected. At this stage no uniform
                                technology standard for
                                recognizing
                                andimplementing DNT signals has been
                                finalized
                                . As such, we do not currently respond to DNT browser signals or any other mechanism
                                that automatically communicates your choice not to be tracked online. If a standard for
                                online tracking is adopted thatwe must follow in the future, we will inform you about
                                that practice in a revised version of this privacy notice.
                            </div>
                        </div>

                        <div id="p11">
                            <h2>11. DO CALIFORNIA RESIDENTS HAVE SPECIFICPRIVACY RIGHTS?</h2>
                            <div>
                                <i>
                                    <strong>In Short: </strong>
                                    Yes, if you are a resident of California, you are granted specific rights regarding
                                    access to your personal information.
                                </i>
                                <br/> <br/>
                                California Civil Code Section 1798.83, also known as the
                                "Shine The Light"
                                law, permits our users who are California residents to request and obtain from us, once
                                ayear and free of charge, information about categories of personal information (if any)
                                we disclosed to third parties for direct marketing purposes and the names and addresses
                                of all third parties with which we shared personal information in the immediately
                                preceding calendar year. If you are a California resident and would like to make such a
                                request, please submit your request in writing to us using the contact information
                                provided below.
                                <br/> <br/>
                                If you are under 18 years of age, reside in California, and have a registered account
                                with Services, you have the right to request removal of unwanted data that you publicly
                                post on the Services. To request removal of such data, please contact ususing the
                                contact information provided below and include the email address associated with your
                                account and a statement that you reside in California. We will make sure the data is not
                                publicly displayed on the Services, but please be aware that the data may not be
                                completely or comprehensively removed from all oursystems (e.g.
                                ,
                                backups, etc.).
                            </div>

                            <h3>CCPA Privacy Notice</h3>
                            <div>
                                The California Code of Regulations defines a
                                "resident"
                                as:
                                <ul className="num-ul">
                                    <li>
                                        (1) every individual who is in the State of California for other than a
                                        temporary or transitory purpose and
                                    </li>
                                    <li>
                                        (2) every individual who is domiciled in the State of California who is outside
                                        the State of California for a temporary or transitory purpose
                                    </li>
                                </ul>
                                All other individuals are defined as
                                "non-residents."
                                <br/> <br/>
                                If this definition of
                                "resident"
                                applies to you, we must adhere to certain rights and obligations regarding your personal
                                information.
                                <br/> <br/>
                                <strong>What categories of personal information do we collect?</strong>

                                <br/> <br/>
                                We have collected the following categories of personal information in the past
                                twelve(12) months:
                            </div>
                            <div className="policy-grid">
                                <div className="policy-grid-item  policy-grid-title">
                                    Category
                                </div>
                                <div className="policy-grid-item policy-grid-title">
                                    Examples
                                </div>
                                <div className="policy-grid-item policy-grid-title">
                                    Collected
                                </div>
                                <div className="policy-grid-item">
                                    A. Identifiers
                                </div>
                                <div className="policy-grid-item">
                                    Contact details, such as real name, alias, postal address, telephone or mobile
                                    contact number, unique personal identifier, online identifier, Internet Protocol
                                    address, email address, and account name
                                </div>
                                <div className="policy-grid-item">
                                    NO
                                </div>
                                <div className="policy-grid-item">
                                    B. Personal information categories listed in the California Customer Records statute
                                </div>
                                <div className="policy-grid-item">
                                    Name, contact information, education, employment, employment history, andfinancial
                                    information
                                </div>
                                <div className="policy-grid-item">
                                    NO
                                </div>
                                <div className="policy-grid-item">
                                    C. Protected classification characteristics under California or federal law
                                </div>
                                <div className="policy-grid-item">
                                    Gender and date of birth
                                </div>
                                <div className="policy-grid-item">
                                    NO
                                </div>
                                <div className="policy-grid-item">
                                    D. Commercial information
                                </div>
                                <div className="policy-grid-item">
                                    Transaction information, purchase history, financial details, and payment
                                    information
                                </div>
                                <div className="policy-grid-item">
                                    NO
                                </div>
                                <div className="policy-grid-item">
                                    E. Biometric information
                                </div>
                                <div className="policy-grid-item">
                                    Fingerprints and voiceprints
                                </div>
                                <div className="policy-grid-item">
                                    NO
                                </div>
                                <div className="policy-grid-item">
                                    F. Internet or other similar network activity
                                </div>
                                <div className="policy-grid-item">
                                    Browsing history, search history, online behavior, interest data, and interactions
                                    with our and other websites, applications, systems, and advertisements
                                </div>
                                <div className="policy-grid-item">
                                    NO
                                </div>
                                <div className="policy-grid-item">
                                    G. Geolocation data
                                </div>
                                <div className="policy-grid-item">
                                    Device location
                                </div>
                                <div className="policy-grid-item">
                                    NO
                                </div>
                                <div className="policy-grid-item">
                                    H. Audio, electronic, visual, thermal, olfactory, or similar information
                                </div>
                                <div className="policy-grid-item">
                                    Images and audio, video or call recordings created in connection with our business
                                    activities
                                </div>
                                <div className="policy-grid-item">
                                    NO
                                </div>
                                <div className="policy-grid-item">
                                    I. Professional or employment-related information
                                </div>
                                <div className="policy-grid-item">
                                    Business contact details in order to provide you our Services at a business level or
                                    job title, work history, and professional qualifications if you apply for a job with
                                    us
                                </div>
                                <div className="policy-grid-item">
                                    NO
                                </div>
                                <div className="policy-grid-item">
                                    J. Education Information
                                </div>
                                <div className="policy-grid-item">
                                    Student records and directory information
                                </div>
                                <div className="policy-grid-item">
                                    NO
                                </div>
                                <div className="policy-grid-item">
                                    K. Inferences drawn fromother personal information
                                </div>
                                <div className="policy-grid-item">
                                    Inferences drawn from any of the collected personal information listed above to
                                    createa profile or summary about, for example, an individual's preferences and
                                    characteristics
                                </div>
                                <div className="policy-grid-item">
                                    NO
                                </div>
                                <div className="policy-grid-item">
                                    L. Sensitive Personal Information
                                </div>
                                <div className="policy-grid-item">

                                </div>
                                <div className="policy-grid-item">
                                    NO
                                </div>
                            </div>
                            <br/>
                            <div>
                                We may also collect other personal information outside of these categories through
                                instances where you interact with us in person, online, or by phone or mail in the
                                context of:
                                <ul>
                                    <li>
                                        Receiving help through our customer support channels;
                                    </li>
                                    <li>
                                        Participation in customer surveys or contests; and
                                    </li>
                                    <li>
                                        Facilitation in the delivery of our Services and to respond to your inquiries.
                                    </li>
                                </ul>
                                How do we use and share your personal information?
                                <br/> <br/>
                                More information about our data collection and sharing practices can be found in this
                                privacy notice
                                .
                                <br/> <br/>
                                You may contact us
                                by visiting
                                <a href="https://scripturecast.com/about/contact"> https://scripturecast.com/about/contact </a>
                                ,
                                or by referringto the contact details at the bottom of this document.
                                <br/> <br/>
                                If you are using an authorized agent to exercise your right to opt out we may deny are
                                quest if the authorized agent does not submit proof that they have been validly
                                authorized
                                to act on your behalf.
                                <br/> <br/>
                                <strong>Will your information be shared with anyone else?</strong>

                                <br/> <br/>
                                We may disclose your personal information with our service providers pursuant to a
                                written contract between us and each service provider. Each service provider is a
                                for-profit entity that processes the information on our behalf, following the same
                                strictprivacy protection obligations mandated by the CCPA.
                                <br/> <br/>
                                We may use your personal information for our own business purposes, such as for under
                                taking internal research for technological development and demonstration. This is not
                                considered to be
                                "selling"
                                of your personal information.
                                <br/> <br/>
                                ScriptureCast Inc.
                                has not disclosed, sold, or shared any personal information tothird parties for a
                                business or commercial purpose in the preceding twelve (12)months.
                                ScriptureCast Inc.
                                will not sell or share personal information in the future belonging to website visitors,
                                users, and other consumers.
                                <br/> <br/>
                                <strong>Your rights with respect to your personal data</strong>

                                <br/> <br/>
                                <strong><u>Right to request deletion of the data — Request to delete</u></strong>

                                <br/> <br/>
                                You can ask for the deletion of your personal information. If you ask us to delete your
                                personal information, we will respect your request and delete your personal information,
                                subject to certain exceptions provided by law, such as (but not limited to) the exercise
                                by another consumer of his or her right to free speech, our compliance requirements
                                resulting from a legal obligation, or any processing that may berequired to protect
                                against illegal activities.
                                <br/> <br/>
                                <strong><u>Right to be informed — Request to know</u> </strong>

                                <br/> <br/>
                                Depending on the circumstances, you have a right to know:


                                <ul>
                                    <li>
                                        whether we collect and use your personal information;
                                    </li>
                                    <li>
                                        the categories of personal information that we collect;
                                    </li>
                                    <li>
                                        the purposes for which the collected personal information is used;
                                    </li>
                                    <li>
                                        whether we sell or share personal information to third parties;
                                    </li>
                                    <li>
                                        the categories of personal information that we sold, shared, or disclosed for
                                        abusiness purpose;
                                    </li>
                                    <li>
                                        the categories of third parties to whom the personal information was sold,
                                        shared, or disclosed for a business purpose;
                                    </li>
                                    <li>
                                        the business or commercial purpose for collecting, selling, or sharing personal
                                        information; and
                                    </li>
                                    <li>
                                        the specific pieces of personal information we collected about you.
                                    </li>
                                </ul>

                                In accordance with applicable law, we are not obligated to provide or delete consumer
                                information that is de-identified in response to a consumer request or to re-identify
                                individual data to verify a consumer request.
                                <br/> <br/>
                                <strong><u>Right to Non-Discrimination for the Exercise of a Consumer's Privacy
                                    Rights</u></strong>
                                <br/> <br/>
                                We will not discriminate against you if you exercise your privacy rights.
                                <br/> <br/>
                                <u>Right to Limit Use and Disclosure of Sensitive Personal Information</u>
                                <br/> <br/>
                                We do not process consumer's sensitive personal information.
                                <br/> <br/>
                                <u>Verification process</u>

                                <br/> <br/>
                                Upon receiving your request, we will need to verify your identity to determine you are
                                the same person about whom we have the information in our system. The severification
                                efforts require us to ask you to provide information so that we can match itwith
                                information you have previously provided us. For instance, depending on thetype of
                                request you submit, we may ask you to provide certain information so that we can match
                                the information you provide with the information we already have on file, or we may
                                contact you through a communication method (e.g.
                                ,
                                phone or email) that you have previously provided to us. We may also use other
                                verification methods as the circumstances dictate.
                                <br/> <br/>
                                We will only use personal information provided in your request to verify your identity
                                or authority to make the request. To the extent possible, we will avoid requesting
                                additional information from you for the purposes of verification. However, if we cannot
                                verify your identity from the information already maintained by us, we may request that
                                you provide additional information for the purposes of verifying your identity andfor
                                security or fraud-prevention purposes. We will delete such additionally provided
                                information as soon as we finish verifying you.
                                <br/> <br/>
                                <u>Other privacy rights</u>

                                <ul>
                                    <li>
                                        You may object to the processing of your personal information.
                                    </li>
                                    <li>
                                        You may request correction of your personal data if it is incorrect or no longer
                                        relevant, or ask to restrict the processing of the information.
                                    </li>
                                    <li>
                                        You can designate an authorized
                                        agent to make a request under the CCPA onyour behalf. We may deny a request from
                                        an
                                        authorized
                                        agent that does notsubmit proof that they have been validly
                                        authorized
                                        to act on your behalf inaccordance with the CCPA.
                                    </li>
                                    <li>
                                        You may request to opt out from future selling or sharing of your personal
                                        information to third parties. Upon receiving an opt-out request, we will act
                                        upon the request as soon as feasibly possible, but no later than fifteen (15)
                                        days from the date of the request submission.
                                    </li>
                                </ul>
                                To exercise these rights, you can contact us
                                by visiting
                                https://scripturecast.com/about/contact
                                ,
                                or by referring to the contact details at the bottom of this document. If you have a
                                complaint about how we handle your data, we would like to hear from you.
                            </div>
                        </div>

                        <div id="p12">
                            <h2>12. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACYRIGHTS?</h2>
                            <div>
                                <i>
                                    <strong>In Short: </strong>
                                    Yes, if you are a resident of Virginia, you may be granted specific rights regarding
                                    access to and use of your personal information.
                                </i>
                            </div>

                            <h3>Virginia CDPA Privacy Notice</h3>
                            <div>Under the Virginia Consumer Data Protection Act (CDPA):
                                <br/> <br/>
                                "Consumer"
                                means a natural person who is a resident of the Commonwealth actingonly in an individual
                                or household context. It does not include a natural person acting in a commercial or
                                employment context.
                                <br/> <br/>
                                "Personal data"
                                means any information that is linked or reasonably linkable to anidentified or
                                identifiable natural person.
                                <br/> <br/>
                                "Personal data"
                                does not include de-identifieddata or publicly available information.
                                <br/> <br/>
                                "Sale of personal data"
                                means the exchange of personal data for monetary consideration.
                                <br/> <br/>
                                If this definition
                                "consumer"
                                applies to you, we must adhere to certain rights andobligations regarding your personal
                                data.
                                <br/> <br/>
                                The information we collect, use, and disclose about you will vary depending on howyou
                                interact with
                                ScriptureCast Inc.
                                and our Services. To find out more, please visitthe following links:
                                <ul>
                                    <li>
                                        <a href="#p1">Personal data we collect</a>
                                    </li>
                                    <li>
                                        <a href="#p2">How we use your personal data</a>
                                    </li>
                                    <li>
                                        <a href="#p4">When and with whom we share your personal data</a>
                                    </li>
                                </ul>
                                <u>Your rights with respect to your personal data</u>


                                <ul>
                                    <li>
                                        Right to be informed whether or not we are processing your personal data

                                    </li>
                                    <li>
                                        Right to access your personal data

                                    </li>
                                    <li>
                                        Right to correct inaccuracies in your personal data

                                    </li>
                                    <li>
                                        Right to request deletion of your personal data

                                    </li>
                                    <li>
                                        Right to obtain a copy of the personal data you previously shared with us

                                    </li>
                                    <li>
                                        Right to opt out of the processing of your personal data if it is used for
                                        targetedadvertising, the sale of personal data, or profiling in furtherance of
                                        decisions that produce legal or similarly significant effects (
                                        "profiling"
                                        )
                                    </li>

                                </ul>

                                ScriptureCast Inc.
                                has not sold any personal data to third parties for business orcommercial purposes.
                                ScriptureCast Inc.
                                will not sell personal data in the futurebelonging to website visitors, users, and other
                                consumers.
                                <br/> <br/>

                                <u>Exercise your rights provided under the Virginia CDPA</u>

                                <br/> <br/>
                                More information about our data collection and sharing practices can be found in this
                                privacy notice
                                .
                                <br/> <br/>
                                You may contact us by email at
                                info@ScriptureCast.com
                                ,
                                by visiting our
                                <a href="https://app.termly.io/notify/2f543bde-b6b5-443d-931a-fa10bfe008a7">
                                    data subjectrequest form
                                </a>
                                , or by referring to the contact details at the bottom of this document.
                                <br/> <br/>
                                If you are using an
                                authorized
                                agent to exercise your rights, we may deny a request ifthe
                                authorized
                                agent does not submit proof that they have been validly
                                authorized
                                toact on your behalf.
                                <br/> <br/>

                                <u>Verification process</u>

                                <br/> <br/>
                                We may request that you provide additional information reasonably necessary to verify
                                you and your consumer's request. If you submit the request through an
                                authorized
                                agent, we may need to collect additional information to verify your identity before
                                processing your request.
                                <br/> <br/>
                                Upon receiving your request, we will respond without undue delay, but in all cases,
                                within forty-five (45) days of receipt. The response period may be extended once
                                byforty-five (45) additional days when reasonably necessary. We will inform you of any
                                such extension within the initial 45-day response period, together with the reason for
                                the extension.
                                <br/> <br/>
                                <u>Right to appeal</u>

                                <br/> <br/>
                                If we decline to take action regarding your request, we will inform you of our
                                decisionand reasoning behind it.
                                If you wish to appeal our decision, please email us at
                                info@ScriptureCast.com
                                . Within sixty (60) days of receipt of an appeal, we will inform you in writing of any
                                action taken or not taken in response to the appeal, including a written explanation of
                                the reasons for the decisions. If your appeal if denied, you may contact the Attorney
                                General to
                                <a href="https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint"> submit
                                    a complaint </a>

                                .

                            </div>
                        </div>

                        <div id="p13">
                            <h2>13. DO WE MAKE UPDATES TO THIS NOTICE?</h2>
                            <div>
                                <i>
                                    <strong>In Short: </strong>
                                    Yes, we will update this notice as necessary to stay compliant with relevant laws.
                                </i>
                                We may update this privacy notice from time to time. The updated version will be
                                indicated by an updated
                                "Revised"
                                date and the updated version will be effective as soon as it is accessible. If we make
                                material changes to this privacy notice, we may notify you either by prominently posting
                                a notice of such changes or by directly sending you a notification. We encourage you to
                                review this privacy notice frequently to be informed of how we are protecting your
                                information.
                            </div>
                        </div>

                        <div id="p14">
                            <h2>14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>
                            <div>
                                If you have questions or comments about this notice, you may
                                email us at
                                info@ScriptureCast.com
                                or by post to:
                                <br/> <br/>
                                ScriptureCast Inc.
                                <br/>
                                __________
                                <br/>
                                __________
                            </div>
                        </div>

                        <div id="p15">
                            <h2>15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THEDATA WE COLLECT FROM YOU?</h2>
                            <div>
                                Based on the applicable laws of your country, you may have the right to request access
                                to the personal information we collect from you, change that information, or delete it.
                                To request to review, update, or delete your personal information, please
                                submit a request form by clicking
                                <a href="https://app.termly.io/notify/2f543bde-b6b5-443d-931a-fa10bfe008a7"> here </a>
                                .

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default withRouter(
    connect(null, {START_LOADING, STOP_LOADING})(PrivacyPolicy)
);
