import React from 'react';
import {withRouter} from 'react-router-dom';
import {LinkHoverUnderline} from '../../common/reusable/link-hover-underline/link-hover-underline';
import './homepage.css';
import {Helmet} from 'react-helmet';
import {connect} from 'react-redux';
import {START_LOADING, STOP_LOADING} from "../../../redux/actions/loader"
import {TextbuttonHoverUnderline} from '../../common/reusable/textbutton-hover-underline/textbutton-hover-underline';
import { emitter } from '../../common/function/event-emitter/event-emitter';

class Homepage extends React.Component {
    constructor(props) {
        super(props);
        this.props.START_LOADING();
        this.state = {
            isSearch: false,
            user: this.props.user
        };
        this.onClickSearch = this.onClickSearch.bind(this);
    }

    componentDidMount() {
        this.props.STOP_LOADING();
    }

    onClickSearch() {
        this.setState({isSearch: true});
    }

    handleOpenSearch() {
        emitter.emit('openPopup', true)
    }

    render() {
        const {isLogin} = this.props
        return (
            <div className='search-page'>
                <div className='search-page-content'>
                    <Helmet>
                        <title>Search Page | ScriptureCast®</title>
                    </Helmet>
                    <div className='font-size-small-title'>ScriptureCast®</div>
                        <div className='search-page-body bullet-list'>
                        {!isLogin && 
                            <LinkHoverUnderline fontSize='font-size-large' url="/account/login" linkText="Sign In"/>
                        }
                            <TextbuttonHoverUnderline onClick={this.handleOpenSearch} fontSize='font-size-large' text="Start Search"/>
                            <LinkHoverUnderline fontSize='font-size-large' url="/advanced-studies"
                                                linkText="Advanced Studies"/>
                            <LinkHoverUnderline fontSize='font-size-large' url="/about/how-to-use"
                                                linkText="How to use ScriptureCast"/>
                        </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isLogin: state.isLogin,
    };
};

export default withRouter(connect(mapStateToProps, {START_LOADING, STOP_LOADING})(Homepage));
