import React from 'react';
import {LinkHoverUnderline} from '../../common/reusable/link-hover-underline/link-hover-underline';
import './homepage.css';
import {Helmet} from 'react-helmet';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {START_LOADING, STOP_LOADING} from "../../../redux/actions/loader";

class AdvancedStudies extends React.Component {
    render() {
        return (
            <div className='search-page'>
                <div className='search-page-content'>
                    <Helmet>
                        <title>Advanced Studies | ScriptureCast®</title>
                    </Helmet>
                    <div className='font-size-small-title'>ScriptureCast®</div>
                        <div className='search-page-body bullet-list'>
                            <LinkHoverUnderline fontSize='font-size-large' url="/subject-list"
                                                linkText="Subject List"/>
                            <LinkHoverUnderline fontSize='font-size-large' url="/category-list"
                                                linkText="Category List"/>
                        </div>
                </div>
            </div>
        );
    }
}


export default withRouter(connect(null, {START_LOADING, STOP_LOADING})(AdvancedStudies));