import React from 'react';
import {withRouter} from 'react-router-dom';
import './error-page.css';

class ErrorPage extends React.Component {

    render() {
        return (
            <div className="mt-5 pt-5 errorPage">
                <p>SORRY</p>
                <p>Page Not Found.</p>
            </div>
        );
    }
}

export default withRouter(ErrorPage);
