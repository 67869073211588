import React from 'react';
import {withRouter} from 'react-router-dom';
import './verify-email-page.css';
import {Helmet} from 'react-helmet';
import {connect} from 'react-redux';
import {START_LOADING, STOP_LOADING, SET_LOGIN_STATE} from '../../../redux/actions/loader';
import {Link} from 'react-router-dom';
import {AccountService} from '../../../services/account.service';
import queryString from 'query-string';
import CustomModal from '../../common/reusable/custom-modal/custom-modal';

const EmailStatus = {
    Verifying: 'Verifying',
    Failed: 'Failed',
    Success: 'Success'
}

class VerifyEmail extends React.Component {
    constructor(props) {
        super(props);
        this.props.START_LOADING();
        this.state = {
            emailStatus: EmailStatus.Verifying,
            isShowModal: false,
        };

        this.handleShowModal = this.handleShowModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleOKButton = this.handleOKButton.bind(this);
    }

    componentDidMount() {
        this.props.STOP_LOADING();

        // Get Verification Token from query param in URL
        const {token} = queryString.parse(this.props?.location?.search);
        AccountService.verifyEmail(token)
            .then((res) => {
                if (res.status === 200) {
                    this.setState({
                        emailStatus: EmailStatus.Success
                    })
                    this.handleShowModal()
                } else {
                    this.setState({emailStatus: EmailStatus.Failed});
                }
            })
            .catch(() => {
                this.setState({emailStatus: EmailStatus.Failed});
            });
    }

    getBody() {
        // eslint-disable-next-line default-case
        switch (this.state?.emailStatus) {
            case EmailStatus.Verifying:
                return <div>Verifying...</div>;
            case EmailStatus.Failed:
                return (
                    <div>
                        Verification failed, you can also verify your account using the{' '}
                        <Link className='link-style1' to="forgot-password">forgot password</Link> page.
                    </div>
                );
            case EmailStatus.Success:
                return <div>Email verification success</div>;
        }
    }

    handleCloseModal() {
        this.setState({
            isShowModal: false,
        });
        this.props.SET_LOGIN_STATE();
    }

    handleShowModal() {
        this.setState({
            isShowModal: true,
        });
    }

    handleOKButton() {
        this.setState({
            isShowModal: false,
        });
        this.props.SET_LOGIN_STATE();
        this.props.history.push({
            pathname: `/`,
            state: {},
        });
    }

    render() {
        const {isShowModal} = this.state;

        return (
            <>
                <Helmet>
                    <title> Verify Email | ScriptureCast®</title>
                </Helmet>
                <>
                    <CustomModal
                        isShowModal={isShowModal}
                        modalHeading={'Verification Successful'}
                        modalBody={
                            'Thank you for registering for ScriptureCast®. Let\'s start the journey.'
                        }
                        buttonName={'Login'}
                        handleCloseModal={this.handleCloseModal}
                        handleOKButton={this.handleOKButton}
                    ></CustomModal>
                </>
                <div className="box-style1">
                    <div>
                        <h3 className="card-header">Verify Email</h3>
                        <div className="card-body">{this.getBody()}</div>
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(
    connect(null, {START_LOADING, STOP_LOADING, SET_LOGIN_STATE})(VerifyEmail)
);
