import React from 'react';
import {withRouter, Link} from 'react-router-dom';
import './donate-success.css';
import {connect} from 'react-redux';
import {START_LOADING, STOP_LOADING} from '../../../redux/actions/loader';
import {BsCheck2Circle, BsFillArrowRightCircleFill} from "react-icons/bs";


class DonateSuccess extends React.Component {
    constructor(props) {
        super(props);
        this.props.START_LOADING()
        this.state = {
            amount: sessionStorage.getItem('donationAmount')
        };
    }

    componentDidMount() {
        this.props.STOP_LOADING();
        this.setState({
            amount: sessionStorage.getItem('donationAmount')
        })
    }

    render() {
        return (
            <div className="thank-you-page">
                <div className="thank-you-box">
                    <p className="confirm-icon"><BsCheck2Circle/></p>
                    <h1>Thank you for your gift of ${this.state.amount} </h1>
                    <h2>Your contribution will help make ScriptureCast® a sustainable resource
                        for you and others like you who desire to have Scripture interpret itself.</h2>
                    <p className="kindness-quote"><strong>ScriptureCast®</strong> <span className="small">Casting a new light on Scripture</span>
                    </p>
                    <Link to='/'><p className="link">Back to Main Page <BsFillArrowRightCircleFill/></p></Link>
                </div>
            </div>
        );
    }
}

export default withRouter(
    connect(null, {START_LOADING, STOP_LOADING})(DonateSuccess)
);
