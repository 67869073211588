import React from 'react';
import {withRouter} from 'react-router-dom';
import './assistant-on-journey.css';
import {Helmet} from 'react-helmet';
import {connect} from 'react-redux';
import {START_LOADING, STOP_LOADING} from '../../../redux/actions/loader';
import {LinkHoverUnderline} from '../../common/reusable/link-hover-underline/link-hover-underline';

class AssistantOnJourney extends React.Component {
    constructor(props) {
        super(props);
        this.props.START_LOADING();
    }

    componentDidMount() {
        this.props.STOP_LOADING();
    }

    render() {
        return (
            <div className="assistant-page">
                <Helmet>
                    <title> Assistance on Your Spiritual Journey | ScriptureCast®</title>
                </Helmet>

                <div className="font-size-small-title text-center">Assistance on Your Spiritual&nbsp;Journey</div>

                <div className="assistant-on-journey-items-container bullet-list">
                    <LinkHoverUnderline
                        fontSize='font-size-large'
                        url="/about-us/spiritual-consultation"
                        linkText="Spiritual Consultation"
                    />
                    <LinkHoverUnderline
                        fontSize='font-size-large'
                        url="/spiritual-gifts-assessment/introduction"
                        linkText="Understanding Your Spiritual Gifts"
                    />
                    <LinkHoverUnderline
                        fontSize='font-size-large'
                        url="/podcasts" linkText="The Shepherd and The Shrink Podcast"/>

                </div>
            </div>
        );
    }
}

export default withRouter(
    connect(null, {START_LOADING, STOP_LOADING})(AssistantOnJourney)
);
