import React from 'react';
import {withRouter} from 'react-router-dom';
import './how-to-use.css';
import {Helmet} from 'react-helmet';
import {connect} from 'react-redux';
import {START_LOADING, STOP_LOADING} from "../../../redux/actions/loader"

class HowToUseBookmarks extends React.Component {
    constructor(props) {
        super(props);
        this.props.START_LOADING();
    }

    componentDidMount() {
        this.props.STOP_LOADING();
    }

    render() {
        return (
            <div className='howtouse-page'>
                <div className='howtouse-page-content'>
                    <Helmet>
                        <title>How to organize bookmarks in custom "Folders" | ScriptureCast®</title>
                    </Helmet>
                    <div className='text-center mb-2'>Welcome to ScriptureCast®. Here are some instructions to help you get familiar with the bookmarks feature.</div>
                    <iframe src="https://scribehow.com/embed/How_to_organize_bookmarks_in_the_new_Folders_option__pitO06_nRXqIPxBtpDdIQw?skipIntro=true&removeLogo=true"
                            className='howtouse-iframe'
                            title="How to organize bookmarks in custom Folders" allowFullScreen></iframe>
                    <div className='text-center bottom'><strong>ScriptureCast®</strong> - help for today and hope for tomorrow.</div>
                </div>
            </div>
        );
    }
}

export default withRouter(connect(null, {START_LOADING, STOP_LOADING})(HowToUseBookmarks));
