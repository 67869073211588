import React from 'react';
import './select-voice-button.css';
import {emitter} from '../../function/event-emitter/event-emitter';
import Dropdown from 'react-bootstrap/Dropdown';

export default class VoicePopover extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            voiceName: 'en-US-Standard-B',
        };
        this.handleSelectVoice = this.handleSelectVoice.bind(this)
    }

    handleSelectVoice(name) {
        sessionStorage.setItem('currentVoiceName', name);
        emitter.emit('voiceChange', name);
    }

    render() {
        return (
            <>
                <Dropdown.Item href="#" onClick={(event) => {
                    this.handleSelectVoice('en-US-Standard-B', event)
                }}>US-Male</Dropdown.Item>
                <Dropdown.Item href="#" onClick={(event) => {
                    this.handleSelectVoice('en-US-Standard-C', event)
                }}>US-Female</Dropdown.Item>
                <Dropdown.Item href="#" onClick={(event) => {
                    this.handleSelectVoice('en-GB-Standard-B', event)
                }}>GB-Male</Dropdown.Item>
                <Dropdown.Item href="#" onClick={(event) => {
                    this.handleSelectVoice('en-GB-Standard-A', event)
                }}>GB-Female</Dropdown.Item>
                <Dropdown.Item href="#" onClick={(event) => {
                    this.handleSelectVoice('en-AU-Standard-B', event)
                }}>AU-Male</Dropdown.Item>
                <Dropdown.Item href="#" onClick={(event) => {
                    this.handleSelectVoice('en-AU-Standard-A', event)
                }}>AU-Female</Dropdown.Item>
            </>
        );
    }
}

