import React from 'react';
import './user-menu.css';
import {Modal} from 'react-bootstrap';
import {BsArrowRight} from "react-icons/bs";
import Button from 'react-bootstrap/Button';
import {AccountService} from "../../../../services/account.service";
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';


export class SearchHistory extends React.Component {
    constructor() {
        super();
        this.target = React.createRef(null);
        this.state = {
            history: [],
            checkboxValues: [],
            isSelected: false,
            hasData: false,
            currentPage: 1,
            isShow: false,
            selectedIdList: [],
            pageSelected: null,
            isLoading: false,
            showTooltip: false
        }
    }

    componentDidMount() {
        this.setState({
            isLoading: true,
        })
        this.refreshDataList();
        if (this.state.hasData) {
            this.setState({
                checkboxValues: new Array(this.state.history.length).fill(false),
            })
        }

        this.setState({
            isLoading: false,
        })
    }


    refreshDataList = async () => {
        this.setState(
            {isLoading: true}
        )
        let data_list = await AccountService.getSearchHistory()
        if (data_list) {
            if (data_list.data.length !== 0) {
                this.setState({
                    history: data_list.data,
                    hasData: true,
                })
                this.clearAllValue();
            } else {
                this.setState({
                    hasData: false,
                    checkboxValues: new Array(this.state.history.length).fill(false),
                })
            }
        }
        this.setState(
            {isLoading: false}
        )
    }


    componentDidUpdate(prevProps, prevState) {
        if (this.state.isShow !== prevState.isShow) {
            if (!this.state.showHistory) {
                this.setState({
                    isSelected: false,
                    currentPage: 1,
                })
                this.clearAllValue();
                this.refreshDataList();
            } else {
                this.componentDidMount()
            }
        }
    }

    componentWillUnmount() {

    }

    clearAllValue() {
        this.setState({
            checkboxValues: new Array(this.state.history.length).fill(false),
            selectedIdList: [],
        })
    }

    handleCheckboxChange = (event, index, id) => {
        //add Id to the list
        const newSelectedId = [...this.state.selectedIdList];
        if (event.target.checked) {
            newSelectedId.push(id);
            this.setState({selectedIdList: newSelectedId});
        } else {
            let index = newSelectedId.indexOf(id);
            if (index !== -1) {
                newSelectedId.splice(index, 1);
                this.setState({selectedIdList: newSelectedId});
            }
        }

        //change the display
        const newCheckboxValues = [...this.state.checkboxValues];
        newCheckboxValues[index] = event.target.checked;
        this.setState({checkboxValues: newCheckboxValues});

    };

    selectAllValue() {
        const pageSelected = document.getElementsByClassName("history-checkbox");
        const arr = [];
        for (let i = 0; i < pageSelected.length; i++) {
            arr.push(pageSelected[i].id);
        }
        const newArr = arr.map((item) => item.split(" "));
        const idList = newArr.reduce((acc, val) => {
            acc.push(parseInt(val[0]));
            return acc;
        }, []);
        const indexList = newArr.reduce((acc, val) => {
            acc.push(parseInt(val[1]));
            return acc;
        }, []);

        const newCheckboxValues = [...this.state.checkboxValues];
        for (let i = indexList[0]; i <= indexList[indexList.length - 1]; i++) {
            newCheckboxValues[i] = true;
        }
        this.setState({
            checkboxValues: newCheckboxValues,
        })

        const newSelectedIdList = [...this.state.selectedIdList]
        idList.forEach(item => {
            if (!newSelectedIdList.includes(item)) {
                newSelectedIdList.push(item);
            }
        })
        this.setState({
            selectedIdList: newSelectedIdList
        })
    }

    handleDelete = async () => {
        if (this.state.selectedIdList.length !== 0) {
            await AccountService.deleteSearchHistoryByIds(this.state.selectedIdList);
            this.refreshDataList();
        } else {
            this.setState({
                showTooltip: true
            })
            setTimeout(() => {
                this.setState({showTooltip: false});
            }, 2000);
        }


    };

    handleClearAll = async () => {
        this.setState({
            isLoading: true,
        })
        this.setState({
            hasData: false,
            isSelected: false,
        })
        await AccountService.deleteAllSearchHistory();

        this.refreshDataList();
        this.setState({
            isLoading: false
        })
    }

    handleSelectAll() {
        this.selectAllValue();
    }

    handleUnselectAll() {
        this.clearAllValue();
    }

    handleSelect() {
        this.setState({
            isSelected: !this.state.isSelected,
        })
        this.clearAllValue();
    }


    render() {
        const {showHistory, handleCloseModal} = this.props;
        const {history, checkboxValues, currentPage, hasData, isLoading, showTooltip} = this.state;
        const isSelected = this.state.isSelected;
        const itemsPerPage = 10;
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentItems = history.slice(indexOfFirstItem, indexOfLastItem);
        const totalPages = Math.ceil(history.length / itemsPerPage);


        return (
            <div>
                {!isLoading &&
                    <Modal id="history-modal" show={showHistory} onShow={() => this.setState({isShow: true})}
                           onHide={handleCloseModal} onExit={() => this.setState({isShow: false})}>
                        <div className="history-main-header">
                            <Modal.Header closeButton>
                                <Modal.Title>Search History</Modal.Title>
                            </Modal.Header>
                            {hasData &&
                                <div className="history-body-header">
                                    <div className="history-select-checkbox-div">
                                        <input
                                            className="history-select-checkbox"
                                            type="checkbox"
                                            checked={this.state.isSelected}
                                            onChange={() => this.handleSelect()}
                                        />{' '}
                                        <p>Modify</p>
                                    </div>

                                    {this.state.isSelected && (
                                        <div className="handle-list-div">
                                            <Button variant="primary" className="select-all-btnhis history-button"
                                                    onClick={() => this.handleSelectAll()}>
                                                Select Page
                                            </Button>
                                            <Button variant="secondary history-button" className="unselect-all-btn"
                                                    onClick={() => this.handleUnselectAll()}>
                                                Unselect All
                                            </Button>
                                            <Button ref={this.target} className="delete-history-btn history-button"
                                                    variant="dark" onClick={() => this.handleDelete()}>
                                                Delete
                                            </Button>
                                            <Overlay target={this.target.current} show={showTooltip} placement="bottom">
                                                {(props) => (
                                                    <Tooltip id="del-tooltip" {...props}>
                                                        <p>Select at least one item to delete.</p>
                                                    </Tooltip>
                                                )}
                                            </Overlay>
                                            <Button className="deleteall-history-btn history-button" variant="danger"
                                                    onClick={() => this.handleClearAll()}>
                                                Clear All
                                            </Button>
                                        </div>
                                    )}
                                </div>}
                        </div>

                        <Modal.Body>
                            <div className="history-div">
                                {!hasData ? <p>No history to show.</p>
                                    :
                                    <>
                                        {currentItems.map((path, index) => (
                                            <div className="his-item-div" key={index}>
                                                {isSelected && (
                                                    <input
                                                        id={path.id + " " + parseInt(indexOfFirstItem + index)}
                                                        className="history-checkbox"
                                                        type="checkbox"
                                                        checked={checkboxValues[indexOfFirstItem + index] || false}
                                                        onChange={(event) => this.handleCheckboxChange(event, indexOfFirstItem + index, path.id)}
                                                    />
                                                )}

                                                <div id="his-link">
                                                    <div className="history-list-item">
                                                        <a href={path.url}>
                                                            <p>- {path.verse_info} -</p>
                                                            <p>{path.subject} <BsArrowRight/> {path.category}
                                                                <BsArrowRight/> {path.topic}{' '}</p>
                                                        </a>

                                                    </div>
                                                </div>

                                                {index < currentItems.length - 1 ? <p className="line"></p> : ''}
                                            </div>
                                        ))}
                                    </>
                                }

                            </div>


                        </Modal.Body>

                        <div className="history-footer">

                            <Modal.Footer>
                                <div className="footer-flex">
                                    <div>
                                        {hasData &&
                                            <nav>
                                                <ul className="pagination">
                                                    {[...Array(totalPages)].map((_, index) => (
                                                        <li key={index}
                                                            className={`page-item ${index + 1 === currentPage ? 'active' : ''}`}>
                                                            <p
                                                                className="page-link"
                                                                onClick={() => {
                                                                    this.setState({currentPage: index + 1});
                                                                }}>
                                                                {index + 1}
                                                            </p>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </nav>
                                        }
                                    </div>
                                    <Button variant="secondary" onClick={handleCloseModal}>
                                        Close
                                    </Button>
                                </div>
                            </Modal.Footer>

                        </div>

                    </Modal>
                }
            </div>
        );
    }

}

