import React from 'react';
import './pagination-buttons.css';

const Pagination = ({total_pages, current_page, setCurrentPage}) => {
    const current_page_number = parseInt(current_page)
    const buttons = [];
    const totalPages = total_pages;
    const nextPage = current_page_number + 1;
    const lastPage = totalPages;

    if (totalPages <= 5) {
        for (let i = 1; i <= totalPages; i++) {
            buttons.push(
                <div
                    key={i}
                    className={`pagination-button ${i === current_page_number ? 'current' : ''}`}
                    onClick={() => setCurrentPage(i)}
                >
                    {i}
                </div>
            );
        }
    } else {
        if (current_page > 1) {
            buttons.push(
                <div key={1} className="pagination-button" onClick={() => setCurrentPage(current_page_number - 1)}>
                    Previous
                </div>)
            buttons.push(
                <div key={2} className="pagination-button" onClick={() => setCurrentPage(1)}>
                    1
                </div>
            )
        }

        if (current_page > 2) {
            buttons.push(
                <div key={3} className="pagination-button" onClick={() => setCurrentPage(2)}>
                    2
                </div>)
        }

        if (current_page > 3) {
            buttons.push(<div key={6}>...</div>)
        }

        buttons.push(<div key={4} className="pagination-button current">{current_page_number}</div>)

        if (current_page < lastPage) {
            buttons.push(
                <div key={5} className="pagination-button" onClick={() => setCurrentPage(nextPage)}>
                    {nextPage}
                </div>
            )
        }

        if (current_page < lastPage - 2) {
            buttons.push(<div key={7}>...</div>)
        }

        if (current_page < lastPage - 1) {
            buttons.push(
                <div key={9} className="pagination-button" onClick={() => setCurrentPage(lastPage)}>
                    {lastPage}
                </div>
            )
        }

        if (current_page < lastPage) {
            buttons.push(
                <div key={8} className="pagination-button" onClick={() => setCurrentPage(current_page_number + 1)}>
                    Next
                </div>
            )
        }
    }

    return <div className="pagination-container">{buttons}</div>;
};

export default Pagination;
