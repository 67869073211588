import Container from '@mui/material/Container'
import React from 'react';
import {withRouter} from 'react-router-dom';
import './about-us-introduction.css';
import {Helmet} from 'react-helmet';
import {connect} from 'react-redux';
import {START_LOADING, STOP_LOADING} from '../../../../redux/actions/loader';

class IntroductionAboutUs extends React.Component {
    constructor(props) {
        super(props);
        this.props.START_LOADING();
    }

    componentDidMount() {
        this.props.STOP_LOADING();
    }

    render() {
        return (
            <div className='about-page'>
                <div className='about-intro-content'>
                    <Helmet>
                        <title> About Us | ScriptureCast®</title>
                    </Helmet>
                    <div className="font-size-small-title text-center">About Us</div>
                    <Container>
                        <div className='about-intro-text'>
                            <div className="scripturecast-description">
                                <h3 className="widget-title">ScriptureCast® Description</h3>
                                <h5 className="subheader-2">
                                    Amplify your Spiritual Growth One Topic at a Time
                                </h5>
                                <p>
                                    Welcome to ScriptureCast®, a dynamic educational resource for
                                    people who want to understand what the Bible teaches on 470+
                                    topics covering 38,000 verses.
                                </p>
                                <p>
                                    ScriptureCast’s unique format allows the Scripture to speak and
                                    interpret itself in a way that will enable the Spirit of God to
                                    speak through His Word to all people.
                                </p>
                                <p>
                                    ScriptureCast® was developed from an observation that people
                                    generally do not understand the Bible, and not everyone learns
                                    similarly. Some can be confused and discouraged when attempting to
                                    read the Bible from cover to cover. This approach allows
                                    subscribers to learn about the Bible differently and see the
                                    wisdom and practicality of it. ScriptureCast® has a unique
                                    annotated format, which guides the reader through each topic and
                                    every verse. Each topic in the Table of Categories has been
                                    linked, so the subscriber can go straight to the topic. Each
                                    topic’s verses are also linked to the New International Version of
                                    the Bible for easy online reading.
                                </p>
                            </div>
                            <br></br>
                            <div className="scripturecast-objectives">
                                <h3 className="widget-title">ScriptureCast® Goals</h3>
                                <p>
                                    1. To allow the Bible to speak truth for itself.<br></br>
                                    2. To Provide a focused and a self-paced Biblical learning
                                    environment for people of all ages. Empowering individuals to have
                                    “an answer for the faith that lies with them”.<br></br>
                                    3. To provide a place for Biblical learning, growth, and healing.
                                    <br></br>
                                    4. To grow in Grace and Knowledge.<br></br>
                                    5. To assist individuals, couples, parents and families around the
                                    world amplify and apply their Spiritual Growth.<br></br>
                                    6. A web-based self-coaching platform consisting 21 Subjects, with
                                    over 470 Categories, thousands of topics and 38 thousand
                                    subsequent annotated Scripture references.<br></br>
                                    7. Linked to online New international Version. It is accessed
                                    through both sight and voice activation with a search feature for
                                    Subjects, Categories, and Topics and Verses.<br></br>
                                    8. Crossed platformed for access from computer, tablet or phone.
                                </p>
                            </div>
                            <br></br>
                            <div className="scripturecast-mission">
                                <h3 className="widget-title">ScriptureCast’s Mission</h3>
                                <p>
                                    <i>“Go therefore and make
                                        disciples of all nations, baptizing them in the name of the Father
                                        and Son and the Holy Spirit, teaching them to observe all that I
                                        commanded you and lo, I am with you always, even to the end of the
                                        age.”</i> (Matthew 28:19,20)
                                </p>
                            </div>
                        </div>

                    </Container>
                </div>

            </div>
        );
    }
}

export default withRouter(
    connect(null, {START_LOADING, STOP_LOADING})(IntroductionAboutUs)
);
