import {BehaviorSubject} from 'rxjs';
import {API_URL} from '../config/config';
import httpCommon from '../http-common';

const userSubject = new BehaviorSubject(null);

export const AccountService = {
    register,
    verifyEmail,
    forgotPassword,
    validateResetToken,
    resetPassword,
    login,
    refreshToken,
    logout,
    changePassword,
    user: userSubject.asObservable(),
    get userValue() {
        return userSubject.value;
    },
    podcastSubscribe,
    podcastUnsubscribe,
    addSearchHistory,
    getSearchHistory,
    deleteAllSearchHistory,
    deleteSearchHistoryByIds,
    addBookmark,
    getBookmark,
    deleteAllBookmarks,
    deleteBookmarkByIds,
    move_bookmark,
    listBookmarkFolder,
    createBookmarkFolder,
    deleteBookmarkFolder,
    setDefaultBookmarkFolder,
    renameBookmarkFolder,
    renameBookmarkById,
    deleteBookmarksByUrl
};


async function register(params) {
    const res = await httpCommon
        .post(`${API_URL}/accounts/register`, params)
        .then((res) => {
            return res;
        })
        .catch(function (error) {
            return error.response;
        });

    return res;
}

async function verifyEmail(token) {
    const params = {
        token: token,
    };
    const res = await httpCommon.post(`${API_URL}/accounts/verify-email`, params).then((user) => {
        // publish user to subscribers and start timer to refresh token
        userSubject.next(user);
        startRefreshTokenTimer();
        return user;
    })
        .catch(function (error) {
            return error.response;
        });
    return res;
}

async function forgotPassword(email) {
    const params = {
        email: email,
    };
    const res = await httpCommon
        .post(`${API_URL}/accounts/forgot-password`, params)
        .then((res) => {
            return res;
        })
        .catch(function (error) {
            return error.response;
        });
    return res;
}

// Update Password
async function validateResetToken(token) {
    const params = {
        token: token,
    };
    await httpCommon.post(`${API_URL}/accounts/validate-reset-token`, params)
        .then((res) => {
            return res;
        })
        .catch(function (error) {
            return error.response;
        });
}

async function resetPassword(token, password, confirmPassword) {
    const params = {
        token: token,
        password: password,
        confirmPassword: confirmPassword,
    };
    const res = await httpCommon.post(`${API_URL}/accounts/reset-password`, params).then((user) => {
        // publish user to subscribers and start timer to refresh token
        userSubject.next(user);
        startRefreshTokenTimer();
        return user;
    })
        .catch(function (error) {
            return error.response;
        });
    return res;
}

async function changePassword(currentPassword, password, confirmPassword) {
    const params = {
        currentPassword: currentPassword,
        password: password,
        confirmPassword: confirmPassword
    };
    const res = await httpCommon
        .post(`${API_URL}/accounts/change-password`, params)
        .then((res) => {
            return res;
        })
        .catch(function (error) {
            return error.response;
        });

    return res;
}

async function login(email, password) {
    const params = {
        email: email,
        password: password,
    };
    const res = await httpCommon
        .post(`${API_URL}/accounts/authenticate`, params)
        .then((user) => {
            // publish user to subscribers and start timer to refresh token
            userSubject.next(user);
            startRefreshTokenTimer();
            return user;
        })
        .catch(function (error) {
            return error.response;
        });

    return res;
}


function refreshToken() {
    const res = httpCommon
        .post(`${API_URL}/accounts/refresh-token`, {})
        .then((user) => {
            if (user.data.is_valid) {
                // publish user to subscribers and start timer to refresh token
                userSubject.next(user);
                startRefreshTokenTimer();
                return user;
            }
        })
        .catch(function (error) {
        });
    return res;
}

async function logout() {
    // revoke token, stop refresh timer, publish null to user subscribers and redirect to login page

    const res = await httpCommon
        .post(`${API_URL}/accounts/revoke-token`, {})
        .then((user) => {
            // publish user to subscribers and start timer to refresh token
            stopRefreshTokenTimer();
            userSubject.next(null);
            return user;
        })
        .catch(function (error) {
        });

    return res;
}


async function podcastSubscribe() {
    if (!userSubject.value) {
        return {
            "is_success": false
        }
    } else {
        const params = {
            email: userSubject.value.data.email,
        };
        const res = await httpCommon
            .post(`${API_URL}/accounts/subscribePodcast`, params)
            .then((res) => {
                return res;
            })
            .catch(function (error) {
                return error.response;
            });
        return res;
    }
}


async function podcastUnsubscribe() {
    if (!userSubject.value) {
        return {
            "is_success": false
        }
    } else {
        const params = {
            email: userSubject.value.data.email,
        };
        const res = await httpCommon
            .post(`${API_URL}/accounts/unSubscribePodcast`, params)
            .then((res) => {
                return res;
            })
            .catch(function (error) {
                return error.response;
            });
        return res;
    }
}


async function addSearchHistory(params) {
    if (!userSubject.value) {
        return {
            "is_success": false
        }
    } else {
        params["accountId"] = userSubject.value.data.id.toString();
        const res = await httpCommon
            .post(`${API_URL}/accounts/add-search-history`, params)
            .then((res) => {
                return res;
            })
            .catch(function (error) {
                return error.response;
            });
        return res;
    }
}


async function getSearchHistory() {
    if (!userSubject.value) {
        return []
    } else {
        const params = {
            "accountId": userSubject.value.data.id.toString()
        }
        const res = await httpCommon
            .post(`${API_URL}/accounts/get-search-history`, params)
            .then((res) => {
                return res;
            })
            .catch(function (error) {
                return error.response;
            });
        return res;
    }
}


async function deleteAllSearchHistory() {
    if (!userSubject.value) {
        return []
    } else {
        const params = {
            "accountId": userSubject.value.data.id.toString()
        }
        const res = await httpCommon
            .post(`${API_URL}/accounts/delete-all-search-history`, params)
            .then((res) => {
                return res;
            })
            .catch(function (error) {
                return error.response;
            });
        return res;
    }
}

async function deleteBookmarksByUrl(url) {
    if (!userSubject.value) {
        return []
    } else {
        const accountId = userSubject.value.data.id.toString()
        const params = {accountId,url}
        console.log(params)
        const res = await httpCommon
            .post(`${API_URL}/accounts/delete-bookmark-by-url`, params)
            .then((res) => {
                return res;
            })
            .catch(function (error) {
                return error.response;
            });
        return res;
    }
}


async function deleteSearchHistoryByIds(id_list) {
    if (!userSubject.value) {
        return []
    } else {
        const params = {
            "historyIds": id_list
        }
        const res = await httpCommon
            .post(`${API_URL}/accounts/delete-search-history-by-ids`, params)
            .then((res) => {
                return res;
            })
            .catch(function (error) {
                return error.response;
            });
        return res;
    }
}

async function addBookmark({folderId,name,url}) {
    if (!userSubject.value) {
        return {
            "is_success": false
        }
    } else {
        const params = {folderId,name,url}
        const res = await httpCommon
            .post(`${API_URL}/accounts/add-bookmark`, params)
            .then((res) => {
                return res;
            })
            .catch(function (error) {
                return error.response;
            });
        return res;
    }
}


async function getBookmark() {
    if (!userSubject.value) {
        return []
    } else {
        const params = {
            "accountId": userSubject.value.data.id.toString()
        }
        const res = await httpCommon
            .post(`${API_URL}/accounts/get-bookmark`, params)
            .then((res) => {
                return res;
            })
            .catch(function (error) {
                return error.response;
            });
        return res;
    }
}


async function deleteAllBookmarks() {
    if (!userSubject.value) {
        return []
    } else {
        const params = {
            "accountId": userSubject.value.data.id.toString()
        }
        const res = await httpCommon
            .post(`${API_URL}/accounts/delete-all-bookmarks`, params)
            .then((res) => {
                return res;
            })
            .catch(function (error) {
                return error.response;
            });
        return res;
    }
}


async function deleteBookmarkByIds(id_list) {
    if (!userSubject.value) {
        return []
    } else {
        const params = {
            "bookmarkIds": id_list
        }
        const res = await httpCommon
            .post(`${API_URL}/accounts/delete-bookmark-by-ids`, params)
            .then((res) => {
                return res;
            })
            .catch(function (error) {
                return error.response;
            });
        return res;
    }
}

async function renameBookmarkById(bookmarkId,newBookmarkName) {
    if (!userSubject.value) {
        return []
    } else {
        const params = {bookmarkId,newBookmarkName}
        const res = await httpCommon
            .post(`${API_URL}/accounts/rename-bookmark-by-id`, params)
            .then((res) => {
                return res;
            })
            .catch(function (error) {
                return error.response;
            });
        return res;
    }
}

let refreshTokenTimeout;

function startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(atob(userSubject?.value?.data?.jwtToken?.split('.')[1]));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - (60 * 1000);
    refreshTokenTimeout = setTimeout(refreshToken, timeout);
}

function stopRefreshTokenTimer() {
    clearTimeout(refreshTokenTimeout);
}


async function listBookmarkFolder() {
    if (!userSubject.value) {
        return []
    } else {
        const accountId =  userSubject.value.data.id.toString()
        const params = {accountId}
        const res = await httpCommon
            .post(`${API_URL}/accounts/listBookmarkFolder`, params)
            .then((res) => {
                return res;
            })
            .catch(function (error) {
                return error.response;
            });
        return res;
    }
}

async function createBookmarkFolder(folderName) {
    if (!userSubject.value) {
        return []
    } else {
        const accountId =  userSubject.value.data.id.toString()
        const params = {accountId,folderName}
        let res = await httpCommon
            .post(`${API_URL}/accounts/createBookmarkFolder`, params)
            .then((res) => {
                return res;
            })
            .catch(function (error) {
                return error.response;
            });
        return res;
    }
}

async function renameBookmarkFolder(folderId,newFolderName) {
    if (!userSubject.value) {
        return []
    } else {
        const accountId =  userSubject.value.data.id.toString()
        const params = {accountId,folderId,newFolderName}
        const res = await httpCommon
            .post(`${API_URL}/accounts/renameBookmarkFolder`, params)
            .then((res) => {
                return res;
            })
            .catch(function (error) {
                return error.response;
            });
        return res;
    }
}

async function setDefaultBookmarkFolder(folderId) {
    if (!userSubject.value) {
        return []
    } else {
        const accountId =  userSubject.value.data.id.toString()
        const params = {accountId,folderId}
        const res = await httpCommon
            .post(`${API_URL}/accounts/setDefaultBookmarkFolder`, params)
            .then((res) => {
                return res;
            })
            .catch(function (error) {
                return error.response;
            });
        return res;
    }
}


async function deleteBookmarkFolder(folderId) {
    if (!userSubject.value) {
        return []
    } else {
        const accountId =  userSubject.value.data.id.toString()
        const params = {accountId,folderId}
        const res = await httpCommon
            .post(`${API_URL}/accounts/deleteBookmarkFolder`, params)
            .then((res) => {
                return res;
            })
            .catch(function (error) {
                return error.response;
            });
        return res;
    }
}


async function move_bookmark(id,newFolderId) {
    if (!userSubject.value) {
        return []
    } else {
        const params = {id,newFolderId}
        const res = await httpCommon
            .post(`${API_URL}/accounts/move_bookmark`, params)
            .then((res) => {
                return res;
            })
            .catch(function (error) {
                return error.response;
            });
        return res;
    }
}
