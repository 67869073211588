import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default class CustomModal3buttons extends React.Component {
    render() {
        const {
            firstButton,
            secondButton,
            isShowModal,
            modalHeading,
            modalBody,
            handleFirstButton,
            handleCloseModal,
            handleSecondButton
        } = this.props;
        return (
            <Modal show={isShowModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{modalHeading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalBody}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleFirstButton}>
                        {firstButton}
                    </Button>
                    <Button variant="primary" onClick={handleSecondButton}>
                        {secondButton}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
