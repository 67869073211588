import React from 'react';
import {withRouter} from 'react-router-dom';
import './spiritual-consultation.css';
import {Helmet} from 'react-helmet';
import {connect} from 'react-redux';
import {START_LOADING, STOP_LOADING} from '../../../redux/actions/loader';
import {LinkHoverUnderline} from '../../common/reusable/link-hover-underline/link-hover-underline';

class SpiritualConsultation extends React.Component {
    constructor(props) {
        super(props);
        this.props.START_LOADING();
    }

    componentDidMount() {
        this.props.STOP_LOADING();
    }

    render() {
        return (
            <div className='spiritual-consultation-page'>
                <Helmet>
                    <title> Spiritual Consultation | ScriptureCast®</title>
                </Helmet>
                <div className="about-header">
                    <div className='font-size-small-title'>Spiritual Consultation</div>
                </div>
                <div className="scripturecast-description">
                    <p>
                        <strong>Spiritual Consultation.</strong> This is designed to
                        assist the reader to navigate the site and point them to their
                        area of need or interest. This is also designed to see if the
                        reader is in need of Spiritual Counseling of Spiritual Coaching.
                    </p>
                    <p>
                        <strong>Spiritual Counseling</strong> is a brief process in
                        targeting ScriptureCast to a specific problem or issue. Spiritual
                        Coaching can be brief or a longer period of individual or couple
                        interaction.
                    </p>
                    <p>
                        <strong>Spiritual Coaching.</strong> The focus of Coaching process
                        is more spiritual development in one's own Christian journey. This
                        maybe facilitated individually, as a couple or in groups,
                        depending on several factors determined by the coach. These last two services may have a fee as
                        they will need more experiences Counselors or Coaches.
                    </p>
                </div>
                <div className="mx-auto">
                    <LinkHoverUnderline
                        fontSize='font-size-large'
                        url="/about/contact"
                        linkText="Contact	Us	For	More	Information"
                    />
                </div>
            </div>
        );
    }
}

export default withRouter(
    connect(null, {START_LOADING, STOP_LOADING})(SpiritualConsultation)
);
