import React from 'react';
import {withRouter} from 'react-router-dom';
import './term-of-use.css';
import {connect} from 'react-redux';
import {START_LOADING, STOP_LOADING} from '../../../redux/actions/loader';

class TermOfUse extends React.Component {
    constructor(props) {
        super(props);
        this.props.START_LOADING();
    }

    componentDidMount() {
        this.props.STOP_LOADING();
    }

    render() {
        return (
            <div className="term-of-use-page">
                <div className='font-size-small-title text-center'>Terms of Service</div>
                <div className='term-text'>
                    <div className='font-size-large'>Proper Use Statement, Copyright and Terms of Use Statement for
                        ScriptureCast® Online Services
                    </div>
                    <div className='font-size-large'>Proper Use Statement</div>
                    <div>ScriptureCast® is intended for your personal use only. Any other use, including. but not
                        limited to copying or re-posting the contents of this website on the Internet, must not be
                        altered or modified in any form but must remain in their original context.
                    </div>
                    <div>
                        The content of ScriptureCast® may not be sold or otherwise offered for sale, including usage
                        that encapsulates ScriptureCast's content to market online ad space. The use of commercial
                        online advertisements is strictly prohibited. The content of ScriptureCast® is to remain a
                        noncommercial and advertisement-free resource.
                    </div>
                    <div>
                        Quoted Scriptural content is used with permission from
                        <a className='link-style1' href="https://www.biblica.com/"> Biblica.com</a>
                    </div>
                </div>


            </div>
        );
    }
}

export default withRouter(
    connect(null, {START_LOADING, STOP_LOADING})(TermOfUse)
);
