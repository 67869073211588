import React from 'react';
import {withRouter} from 'react-router-dom';
import './logout-page.css';
import {Helmet} from 'react-helmet';
import {connect} from 'react-redux';
import {START_LOADING, STOP_LOADING} from '../../../redux/actions/loader';
import {AccountService} from '../../../services/account.service';

class Logout extends React.Component {
    constructor(props) {
        super(props);
        const user = AccountService.userValue;
        if (user) {
            window.location.href = "/"
        }
    }

    render() {
        return (
            <div className='logout-page'>
                <Helmet>
                    <title> Exit | ScriptureCast®</title>
                </Helmet>
                <div className='logout-content'>
                    <div className='font-size-large'>
                        No matter how your day goes, look for God's blessings large or small in your life today. Thank
                        him.
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(
    connect(null, {START_LOADING, STOP_LOADING})(Logout)
);
