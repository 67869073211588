import React from "react";
import "./AppSearch.css";
import searchService from '../../../services/search.service.js';
import {recognizeVerseName, extract_verse_info} from '../../../utils';
import {START_LOADING, STOP_LOADING} from "../../../redux/actions/loader"
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import { emitter } from "../../common/function/event-emitter/event-emitter.jsx";
import {AccountService} from "../../../services/account.service";


class AppSearch extends React.Component {
    constructor(props) {
        super(props);    
        const selected_doc = ""
        this.state = {
            inputValue: '',
            user: null,
            selected_doc,
            selected_chapter : "",
            selected_verse : "",
            user_id: ""
        };
    }

    async componentDidMount() { 
        const all_chapters = this.props.allChapters
        const all_docs =  Object.keys(all_chapters)
        this.setState({all_chapters,all_docs})
        const isLogin = AccountService.userValue ? true : false;
        let user_id = ""
        if (isLogin) {
            const user = AccountService.userValue.data;
            user_id = user.id.toString()
        }
        this.setState({
            user_id
        })

    }

    handleEnter = async () => {
        const {inputValue} = this.state;
        if (inputValue && inputValue.trim() !== '') {
            const input = inputValue.trim()
            const isVerse = recognizeVerseName(input)
            if (isVerse) {
                const verse_found = await this.getResultByVerseName(input)
                if (verse_found) {
                    const extract_result = extract_verse_info(input);
                    await searchService.addBibleSearchHistory(this.state.user_id, input);
                    if (extract_result) {
                        emitter.emit('updateSearchHistory', this.state.user_id);
                        window.location.href = `/chapter-overview/${encodeURI(extract_result["doc_chapter_info"])}#${encodeURI(extract_result["html_doc_id"])}`;
                    } else {
                        emitter.emit('updateSearchHistory', this.state.user_id);
                        window.location.href = `/chapter-overview/${input}`;
                    }
                } else {
                    this.setState({error: "Verse not found!"})
                }
            } else {
                window.location.href = `/search-result?query=${encodeURIComponent(input)}`;
            }        
        } else {
            this.setState({error: "Please enter a keyword!"})
        }
    };

    getResultByVerseName = async (input) => {    
        const res = await searchService.getVerseByName(input) 
        var item = res.data.verse_info[0]
        return item["verse_string_valid"] && item["verse_found"];
    }

    handleInputChange = (e) => {
        this.setState({inputValue: e.target.value});
        if (e.target.value) {
             this.setState({error: ""})
        }
    };

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.handleEnter();
        }
    };

    async moveToVerse (event) {
        const selected_doc = this.state.selected_doc
        const selected_chapter = this.state.selected_chapter
        const selected_verse = this.state.selected_verse
        if (selected_doc && selected_chapter && selected_verse) {
            this.setState({error: ""})
            const extract_result = extract_verse_info(`${selected_doc} ${selected_chapter}:${selected_verse}`);
            if (extract_result) {
                // console.log(`Redirect to: /chapter-overview/${encodeURI(extract_result["doc_chapter_info"])}#${encodeURI(extract_result["html_doc_id"])}`)
                await searchService.addBibleSearchHistory(this.state.user_id, `${selected_doc} ${selected_chapter}:${selected_verse}`);
                emitter.emit('updateSearchHistory', this.state.user_id);
                window.location.href = `/chapter-overview/${encodeURI(extract_result["doc_chapter_info"])}#${encodeURI(extract_result["html_doc_id"])}`
            } else {
                let search_text = `${selected_doc} ${selected_chapter}`
                await searchService.addBibleSearchHistory(this.state.user_id, search_text);
                emitter.emit('updateSearchHistory', this.state.user_id);
                window.location.href = `/chapter-overview/${encodeURI(search_text)}`
            }
            emitter.emit('openPopup', false);
        } else {
            this.setState({error: "No Bibble book selected."})
        }
        
    };

    handleChangeDoc(e) {
        const selected_doc = e.target.value
        const selected_chapter = 1
        const selected_verse = 1
        this.setState({error: ""})
        if (selected_doc) {
            this.setState({selected_doc,selected_chapter,selected_verse}) 
        }
    }

    handleChangeVerse(e) {
        const selected_verse = e.target.value
        if (selected_verse) {
            this.setState({selected_verse}) 
        }
    }

    handleChangeChapter(e) {
        const selected_chapter = e.target.value
        const selected_verse = 1
        if (selected_chapter) {
            this.setState({selected_chapter,selected_verse}) 
        }
    }

    render() {
        const {error,all_docs,selected_doc,all_chapters,selected_chapter,selected_verse} = this.state
        return (
            <div id='app-search' className="app-search box-style1">
                <div className='search-box font-size-medium my-4'>
                    <div className='search-box-content'>
                        <div className='font-size-large mr-4 mb-2'>Search</div>
                        <div>
                            <div className='search-input'>
                                <div>
                                    <input
                                        type="text"
                                        name='search-keyword'
                                        id='search-keyword'
                                        value={this.state.inputValue}
                                        onChange={this.handleInputChange}
                                        onKeyDown={this.handleKeyDown}
                                    ></input>
                                    <div className='search-button' onClick={this.handleEnter}>Enter</div>         
                                </div>
                                
                            </div>
                            <div className='text-white'>Enter Subject, Category or Topic</div>
                            {all_chapters && 
                            <div className='mt-2'>
                                <select className="form-select mr-2 mb-2" placeholder="Book" value={selected_doc} onChange={(e)=>this.handleChangeDoc(e)}>
                                    <option key="-1"value="" disabled >Book</option>
                                    {all_docs.map ((item,index) => (
                                        <option key={index} value={item} >{item}</option>
                                    ))}
                                </select>

                                <select className="form-select mr-2" placeholder="Chapter" value={selected_chapter} onChange={(e)=>this.handleChangeChapter(e)}>
                                    <option key="-1"value="" disabled >Chapter</option>
                                    {selected_doc &&  Object.keys(all_chapters[selected_doc]['chapter']).map ((item,index) => (
                                        <option key={index} value={item} >{item}</option>
                                    ))}
                                </select>

                                <select className="form-select mr-2" placeholder="Verse" value={selected_verse} onChange={(e)=>this.handleChangeVerse(e)}>
                                     <option key="-1"value="" disabled >Verse</option>
                                    {selected_doc && Array.from({ length:all_chapters[selected_doc]['chapter'][selected_chapter]['no_verses'] }, (v, k) => k + 1).map((item,index) => (
                                        <option key={index} value={item} >{item}</option>
                                    ))}
                                </select>

                                <button style={{borderRadius: '5px'}} className='button-style1 px-2' onClick={()=>this.moveToVerse()}><i className="fa-solid fa-arrow-right"></i></button>

                                <div className='text-white'>To view or listen to a specific Bible verse enter it here</div>
                                {error && <div className='error-yellow'>{error}</div>}
                            </div>}
                        </div>
                    </div>
                    <div className='text-center text-white'>Press Control S from any Screen to edit search</div>
                    
                </div>

            </div>
        );
    }
}

export default withRouter(connect(null, {START_LOADING, STOP_LOADING})(AppSearch));
