import React from 'react';
import './custom-modal.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default class CustomModal extends React.Component {
    render() {
        const {
            leftButtonName,
            isShowModal,
            modalHeading,
            modalBody,
            buttonName,
            handleCloseModal,
            handleOKButton
        } = this.props;
        return (
            <Modal show={isShowModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{modalHeading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalBody}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        {leftButtonName ? leftButtonName : 'Close'}
                    </Button>
                    <Button variant="primary" onClick={handleOKButton}>
                        {buttonName}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
