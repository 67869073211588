const spiritualAssessmentData = {
    en: {
        form: {
            name: "Name",
            email: "Email",
            error: {
                nameIsRequired: "Name is required.",
                valideMailIsRequired: "Valid email is required.",
            }
        },
        questionaire: {
            title: "Spiritual Gifts Assessment",
            subtitle: "A service of ScriptureCast.com",
            notification: "Notification",
            button: {
                save: 'Save',
                submit: 'Submit',
                print: " Print this page",
                goToBottom: "Go to Bottom",
                copy: "Copy",
                close: "Close",
            },
            questiontitle: "Spiritual Gifts Assessment",
            sentences: {
                notFound: "404 Page not found.",
                assessmentResult: "Assessment Result",
                thankyou: "Thank you for taking time to complete the Spiritual Gifts Assessment. Your final result is ",
                yourSpiritualReferences: "Your Spiritual References: ",
                chartBelow: "The chart below show our analysis on which you may be gifted from the assessment.",
                saveModalContent1: "Your progress has been saved.",
                saveModalContent2: "Please save the Link below to continue next time:",
                completequestion1: "Please complete ",
                completequestion2: " remaining question(s) to submit.",
                remindnNameAndEmail: "Please click HERE to enter your name and email.",
                paymentIssue: "We're experiencing some issues with the payment method.",
                contactForSupports: "Contact us for supports.",
                apologize: "We apologize for any inconvenience caused.",
                questionsToBeCompleted: "Questions to be completed: ",
                saving: "Saving...",
                copied: "Copied",
                saved: "Saved",
            }
        },

        instruction: {
            title: "Instruction",
            content:
                `
            <div>
                The following questions will help you determine whether you have these gifts, or have an inclination toward them. After each question, mark a number according to how much this characteristic is evident in your life. The numbers have the following significance:
            </div>
            <div>
            <div>0 = not true in my life</div>
            <div>1 = sometimes true in my life</div>
            <div>2 = regularly true in my life</div>
            <div>3 = almost always true in my life</div>
            </div>
            <div> After completing this Spiritual Gift Assessment, you will receive a bar chart which  represent the areas in which you may be gifted. In the section following the graphic summary along with a listing of all the gifts and their Scriptural references.
            </div>
            <div><strong>NOTE:</strong> You may find it helpful to have a partner or other person(s) who knows you well to review your responses. Sometimes we see ourselves differently than others see us. Sometimes we respond to the questions as we'd like to be rather than as we are. Having someone else review our responses can provide us with a helpful second opinion.             
            </div>
            <div><strong>NOTE:</strong> This questionnaire covers various aspects, and we understand it may require multiple sessions to complete. You have the flexibility to save your progress and return to it later at your convenience by clicking the Save button.</br>To get back to the questionnaire, simply bookmark the current page or copy the URL in your browser's address bar. You can also open the link directly from the email we sent you, ensuring easy access to continue where you left off.        
            </div>                  
                `
        },
        description: {
            title: "Spiritual Gifts Description",
            content: [
                {
                    title: "Apostleship",
                    en_title: "Apostleship",
                    content: "The ability and responsibility to be sent by Jesus Christ to communicate His Gospel and establish His Church, especially where it has not been before.",
                    reference: [
                        {
                            title: "1Corinthians 12:28",
                            link: "https://www.biblica.com/bible/niv/1-corinthians/12/"
                        },
                        {
                            title: "Ephesians 4:11",
                            link: "https://www.biblica.com/bible/niv/ephesians/4/"
                        }
                    ]
                },
                {
                    title: "Prophecy",
                    en_title: "Prophecy",
                    content: "The ability and responsibility to effectively speak God’s message to people.",
                    reference: [
                        {
                            title: "Romans 12:6",
                            link: "https://www.biblica.com/bible/niv/romans/12/"
                        },
                        {
                            title: "Ephesians 4:11",
                            link: "https://www.biblica.com/bible/niv/ephesians/4/"
                        },
                        {
                            title: "1Corinthians 12:10, 28",
                            link: "https://www.biblica.com/bible/niv/1-corinthians/12/"
                        }
                    ]
                },
                {
                    title: "Evangelism",
                    en_title: "Evangelism",
                    content: "The ability and responsibility to effectively communicate the Good News of salvation through Jesus in such a way that people respond and are converted.",
                    reference: [
                        {
                            title: "Ephesians 4:11",
                            link: "https://www.biblica.com/bible/niv/ephesians/4/"
                        }
                    ]
                },
                {
                    title: "Shepherding",
                    en_title: "Shepherding",
                    content: "The ability and responsibility to spiritually guide, feed, and protect God’s people.",
                    reference: [
                        {
                            title: "Ephesians 4:11",
                            link: "https://www.biblica.com/bible/niv/ephesians/4/"
                        }
                    ]
                },
                {
                    title: "Teaching",
                    en_title: "Teaching",
                    content: "The chief function is to explain God’s truth and how to apply it to life in a meaningful way for Christian growth.",
                    reference: [
                        {
                            title: "Romans 12:7",
                            link: "https://www.biblica.com/bible/niv/romans/12/"
                        },
                        {
                            title: "1Corinthians 12:28",
                            link: "https://www.biblica.com/bible/niv/1-corinthians/12/"
                        }
                    ]
                },
                {
                    title: "Exhortation",
                    en_title: "Exhortation",
                    content: "The ability and responsibility to come alongside and provide encouragement, strength, consolation, and help.",
                    reference: [
                        {
                            title: "Romans 12:8",
                            link: "https://www.biblica.com/bible/niv/romans/12/"
                        }
                    ]
                },
                {
                    title: "Knowledge",
                    en_title: "Knowledge",
                    content: "The supernatural ability and responsibility to investigate and systematize the facts and truths related to God’s Revealed truth.",
                    reference: [
                        {
                            title: "1Corinthians 12:8",
                            link: "https://www.biblica.com/bible/niv/1-corinthians/12/"
                        },
                    ]
                },
                {
                    title: "Wisdom",
                    en_title: "Wisdom",
                    content: "The ability and responsibility to interpret, demonstrate insight and understand facts for Godly application.",
                    reference: [
                        {
                            title: "1Corinthians 12:8",
                            link: "https://www.biblica.com/bible/niv/1-corinthians/12/"
                        }
                    ]
                },
                {
                    title: "Helps",
                    en_title: "Helps",
                    content: "The ability and responsibility to give assistance and support where a need appears.",
                    reference: [
                        {
                            title: "Proverbs 19:17",
                            link: "https://www.biblica.com/bible/niv/proverbs/19/"
                        },
                        {
                            title: "Matthew 25:44-45",
                            link: "https://www.biblica.com/bible/niv/matthew/25/"
                        },
                        {
                            title: "Hebrews 13:16",
                            link: "https://www.biblica.com/bible/niv/hebrews/13/"
                        }
                    ]
                },
                {
                    title: "Hospitality",
                    en_title: "Hospitality",
                    content: "The ability and responsibility to provide welcome, friendship, fellowship, food and/or lodging, especially to newcomers and strangers.",
                    reference: [
                        {
                            title: "Romans 12:13",
                            link: "https://www.biblica.com/bible/niv/romans/12/"
                        },
                        {
                            title: "Hebrews 13:2",
                            link: "https://www.biblica.com/bible/niv/hebrews/13/"
                        }
                    ]
                },
                {
                    title: "Giving",
                    en_title: "Giving",
                    content: "The ability and responsibility to give materially to others and to the Lord’s work with generosity and joy.",
                    reference: [
                        {
                            title: "Romans 12:8",
                            link: "https://www.biblica.com/bible/niv/romans/12/"
                        }
                    ]
                },
                {
                    title: "Government",
                    en_title: "Government",
                    content: "The ability and responsibility to understand God’s goals for a group or task and effectively organize the group to accomplish those goals.",
                    reference: [
                        {
                            title: "Romans 12:8",
                            link: "https://www.biblica.com/bible/niv/romans/12/"
                        },
                        {
                            title: "1Corinthians 12:28",
                            link: "https://www.biblica.com/bible/niv/1-corinthians/12/"
                        }
                    ]
                },
                {
                    title: "Leadership",
                    en_itle: "Leadership",
                    content: "The ability and responsibility to sense and know God’s goals from the church or ministry and to guide the body to the fulfillment of those goals.",
                    reference: [
                        {
                            title: "Exodus 18:21",
                            link: "https://www.biblica.com/bible/niv/exodus/18/"
                        },
                        {
                            title: "Proverbs 11:14",
                            link: "https://www.biblica.com/bible/niv/proverbs/11/"
                        },
                        {
                            title: "Matthew 20:25-28",
                            link: "https://www.biblica.com/bible/niv/matthew/20/"
                        },
                        {
                            title: "Philippians 2:3",
                            link: "https://www.biblica.com/bible/niv/philippians/2/"
                        }
                    ]
                },
                {
                    title: "Mercy",
                    en_title: "Mercy",
                    content: "The ability and responsibility to feel compassion for the hurting and to cheerfully take action to alleviate that hurt.",
                    reference: [
                        {
                            title: "Romans 12:8",
                            link: "https://www.biblica.com/bible/niv/romans/12/"
                        }
                    ]
                },
                {
                    title: "Faith",
                    en_title: "Faith",
                    content: "The ability and responsibility to confidently discern the will and purposes of God for His work and believe that God will accomplish it, even when it looks impossible.",
                    reference: [
                        {
                            title: "1Corinthians 12:9",
                            link: "https://www.biblica.com/bible/niv/1-corinthians/12/"
                        }
                    ]
                },
                {
                    title: "Discernment",
                    en_title: "Discernment",
                    content: "The ability and responsibility to distinguish between the spirit of truth and the spirit of error.",
                    reference: [
                        {
                            title: "1Corinthians 12:10",
                            link: "https://www.biblica.com/bible/niv/1-corinthians/12/"
                        }
                    ]
                },
                {
                    title: "Miracles",
                    en_title: "Miracles",
                    content: "The ability and responsibility to authorize God’s Word through supernatural acts.",
                    reference: [
                        {
                            title: "1Corinthians 12:10, 28",
                            link: "https://www.biblica.com/bible/niv/1-corinthians/12/"
                        }
                    ]
                },
                {
                    title: "Healing",
                    en_title: "Healing",
                    content: "The ability and responsibility to make sick people well by intervening in a supernatural way as an instrument of God.",
                    reference: [
                        {
                            title: "1Corinthians 12:9, 28",
                            link: "https://www.biblica.com/bible/niv/1-corinthians/12/"
                        },
                    ]
                },
                {
                    title: "Tongues",
                    en_title: "Tongues",
                    content: "The ability and responsibility to hear words in a language that has not been learned.",
                    reference: [
                        {
                            title: "1Corinthians 12:10, 28, 30",
                            link: "https://www.biblica.com/bible/niv/1-corinthians/12/"
                        },
                    ]
                },
                {
                    title: "Interpretation of Tongues",
                    en_title: "Interpretation of Tongues",
                    content: "The ability and responsibility to hear words spoken in tongues and then communicate those words in a language listeners can understand.",
                    reference: [
                        {
                            title: "1Corinthians 12:10, 30",
                            link: "https://www.biblica.com/bible/niv/1-corinthians/12/"
                        },
                    ]
                },

            ]
        },
        answers: [
            {
                content: "Not true in my life",
                score: 0,
            },
            {
                content: "Sometimes true in my life",
                score: 1,
            },
            {
                content: "Regularly true in my life",
                score: 2,
            },
            {
                content: "Almost always true in my life",
                score: 3,
            }
        ],
        questions: [
            {
                id: 1,
                content: "I am good at visualizing something before it becomes reality and tend to know how to make it happen.",
                required: true,
            },
            {
                id: 2,
                content: "When I hear a poor sermon, I think of other ways to say God's words to these people.",
                required: true
            },
            {
                id: 3,
                content: "Even though I am sometimes afraid, I still feel compelled to share my faith with those I come in contact with, and I am amazed at how often they respond.",
                required: true
            },
            {
                id: 4,
                content: "I feel frustrated if the church or a group of Christians isn't being cared for spiritually, the way I know they could and should be. I sometimes wish I could do the job.",
                required: true
            },
            {
                id: 5,
                content: "When I have the opportunity to teach or communicate Biblical truth to others, they seem to be affected in knowledge, attitudes and actions.",
                required: true
            },
            {
                id: 6,
                content: "I have a strong urge to verbally challenge those who are spiritually complacent and apathetic.",
                required: true
            },
            {
                id: 7,
                content: "I have the ability to discover Biblical truths and principles by myself.",
                required: true
            },
            {
                id: 8,
                content: "My assessment of people, situations, and needs seems to be mostly correct.",
                required: true
            },
            {
                id: 9,
                content: "When presented with a physical or spiritual need. I tend to respond best if the need does not require a lot of organizational detail or personal preparation.",
                required: true
            },
            {
                id: 10,
                content: "I see my home as a haven for the lonely, the alienated, and the stranger.",
                required: true
            },
            {
                id: 11,
                content: "I manage my money well and restrict my standard of living in order to give liberally to God's work.",
                required: true
            },
            {
                id: 12,
                content: "People often look to me to set up procedures and plans to make changes or improvements to present ministries.",
                required: true
            },
            {
                id: 13,
                content: "Generally speaking, I tend to be more sensitive to the overall organizational direction and goals, than to minority or individual opinions.",
                required: true
            },
            {
                id: 14,
                content: "In situations requiring organizational decision-making, I tend to lack firmness because I am concerned for people's feelings.",
                required: true
            },
            {
                id: 15,
                content: "I tend to encourage other Christians to trust God and think big in terms of certain goals and actions.",
                required: true
            },
            {
                id: 16,
                content: "Other Christians seem to look to me for insight into the truthfulness of a particular teaching or person.",
                required: true
            },
            {
                id: 17,
                content: "I know that nothing is impossible with God and have seen evidence of that through specific miracles in my ministry for God.",
                required: true
            },
            {
                id: 18,
                content: "Though not everyone is healed, I see God miraculously healing individuals through my ministry to them.",
                required: true
            },
            {
                id: 19,
                content: "I have had unusual experiences using this unknown language, especially desiring to speak it to people I don't know so that they may know about Christ.",
                required: true
            },
            {
                id: 20,
                content: "Other Christians and the church look to me to interpret a God-given language someone is speaking.",
                required: true
            },
            {
                id: 21,
                content: "I have the desire to help start work for God where it does not now exist.",
                required: true
            },
            {
                id: 22,
                content: "I sense an inner urgency to persuade people to make spiritual decisions and commitments.",
                required: true
            },
            {
                id: 23,
                content: "I have a consistent concern for people who don't know Christ and would like to share the gospel with them.",
                required: true
            },
            {
                id: 24,
                content: "I have a desire to care for the spiritual welfare of new Christians.",
                required: true
            },
            {
                id: 25,
                content: "I have a deep conviction that Scriptural truths should be understood and applied to daily life.",
                required: true
            },
            {
                id: 26,
                content: "1 am accepting of people who seem to be deeply troubled or in a crisis.",
                required: true
            },
            {
                id: 27,
                content: "I am motivated to study and learn Biblical truth and information.",
                required: true
            },
            {
                id: 28,
                content: "I have a great reverence for God and His purposes for all of life — including mine.",
                required: true
            },
            {
                id: 29,
                content: "When listening to a speaker, I am strongly impressed and want to respond to exhortations to serve other Christians.",
                required: true
            },
            {
                id: 30,
                content: "I have great joy in hosting people at my home who need ministry.",
                required: true
            },
            {
                id: 31,
                content: "When presented with a physical or spiritual need, I tend to respond on my own initiative to try to meet it. especially through monetary or material generosity.",
                required: true
            },
            {
                id: 32,
                content: "With regard to planning for the future of my church or organization, I tend to be concerned about and willing to do detailed work on the plans.",
                required: true
            },
            {
                id: 33,
                content: "In a group or organization, I prefer to lead.",
                required: true
            },
            {
                id: 34,
                content: "I feel deeply and hurt for others who are sick, imprisoned, poverty-stricken or broken in some other way.",
                required: true
            },
            {
                id: 35,
                content: "Usually, I have a tendency to visualize God's goals for His work and work toward them despite roadblocks.",
                required: true
            },
            {
                id: 36,
                content: "I tend to probe /analyze to determine other Christians' true spiritual conditions or needs.",
                required: true
            },
            {
                id: 37,
                content: "I have been amazed and so have others by the miracles God has done through my life for the good of others.",
                required: true
            },
            {
                id: 38,
                content: "When given ministry opportunities. I tend to accept ministries where there are hurting or sick people that God can heal through me.",
                required: true
            },
            {
                id: 39,
                content: "I have the ability to speak a language I don't understand.",
                required: true
            },
            {
                id: 40,
                content: "God has given me the ability to understand and speak forth His truth in languages I have never learned before.",
                required: true
            },
            {
                id: 41,
                content: "I desire to be used by God to start new churches or works in other places where there is no church or work.",
                required: true
            },
            {
                id: 42,
                content: "I am convinced that people need to respond to Christ, and I am willing to confront them with this reality.",
                required: true
            },
            {
                id: 43,
                content: "I enjoy being with non-Christians in order to develop relationships with them that may lead to opportunities to share Christ.",
                required: true
            },
            {
                id: 44,
                content: "I am concerned that new believers get started correctly in their faith.",
                required: true
            },
            {
                id: 45,
                content: "I feel confident in my ability to communicate Biblical truths to others.",
                required: true
            },
            {
                id: 46,
                content: "People often come to me for advice in personal matters and choices.",
                required: true
            },
            {
                id: 47,
                content: "I have seen God do what seemed impossible through supernatural power.",
                required: true
            },
            {
                id: 48,
                content: "Though I don't have the gift of healing, I have seen people miraculously healed in answer to prayer.",
                required: true
            },
            {
                id: 49,
                content: "When I hear a language I don't recognize, I have an urge to speak in that language so that others can understand the truth about Christ.",
                required: true
            },
            {
                id: 50,
                content: "I am often sought out to help others understand the meaning of an unfamiliar language they have heard.",
                required: true
            },

            {
                "id": 51,
                "content": "With regard to financial matters, I am moved to give all I can to people/organizations I consider worthy.",
                "required": true
            },
            {
                "id": 52,
                "content": "I am able and willing to learn administrative skills such as planning, organizing, and delegating.",
                "required": true
            },
            {
                "id": 53,
                "content": "I am able to quickly assess the needs of a group and figure out how its goals should be accomplished.",
                "required": true
            },
            {
                "id": 54,
                "content": "When faced with counseling another person, I tend to deeply identify with their situation.",
                "required": true
            },
            {
                "id": 55,
                "content": "I am convinced God answers prayer and that He works specifically through my prayer life.",
                "required": true
            },
            {
                "id": 56,
                "content": "Generally, I tend to be wise in discerning the personality, character and spirituality of others.",
                "required": true
            },
            {
                "id": 57,
                "content": "God has broken natural laws in answer to my prayers so that miracles have taken place.",
                "required": true
            },
            {
                "id": 58,
                "content": "God has used me to bring physical healing to others above the natural healing processes.",
                "required": true
            },
            {
                "id": 59,
                "content": "Through interpretation of what I say, I can tell of God's goodness and salvation in a language I never learned.",
                "required": true
            },
            {
                "id": 60,
                "content": "When someone speaks in tongues, I have an overwhelming urge to say what it means and be confident it is right.",
                "required": true
            },
            {
                "id": 61,
                "content": "I like to help organize and start new ministries.",
                "required": true
            },
            {
                "id": 62,
                "content": "When I share my testimony, I tend to point out some area of doctrine that has come alive to me through an experience or verse from the Bible.",
                "required": true
            },
            {
                "id": 63,
                "content": "I am at ease in sharing with others how Christ is my Savior and Lord.",
                "required": true
            },
            {
                "id": 64,
                "content": "I find joy and fulfilment when I am responsible for the growth of a group ci Christians.",
                "required": true
            },
            {
                "id": 65,
                "content": "I am convinced that for Christians and the church to grow there must be quality teaching and significant learning.",
                "required": true
            },
            {
                "id": 66,
                "content": "When counseling another person. I tend to identify deeply with their situation and want to help them through it.",
                "required": true
            },
            {
                "id": 67,
                "content": "I would like to have a Biblical answer for everything possible — so much so, that I tend to study matters deeply.",
                "required": true
            },
            {
                "id": 68,
                "content": "I tend to be very effective at solving problems by using Biblical principles.",
                "required": true
            },
            {
                "id": 69,
                "content": "When there is a task to do, I prefer to do it myself, rather than delegating it to someone else.",
                "required": true
            },
            {
                "id": 70,
                "content": "Without worrying about the neatness or readiness of my home, I feel comfortable having people over to the house.",
                "required": true
            },
            {
                "id": 71,
                "content": "When called upon to serve, I am motivated to help in situations in which there are specific material needs like food, money, equipment, buildings, etc",
                "required": true
            },
            {
                "id": 72,
                "content": "I enjoy and am able to organize ideas, people, and projects for more effective ministry.",
                "required": true
            },
            {
                "id": 73,
                "content": "I enjoy and find it easy to motivate others to follow through on a ministry project.",
                "required": true
            },
            {
                "id": 74,
                "content": "If I could choose the Bible passages to study, I would choose those which are very practical.",
                "required": true
            },
            {
                "id": 75,
                "content": "I feel great satisfaction and joy in persisting through prayer for specific needs.",
            },
            {
                "id": 76,
                "content": "Usually, I am able to detect weaknesses and pitfalls when evaluating opportunities and situations.",
                "required": true
            },
            {
                "id": 77,
                "content": "As God does miracles through my life and words, people come to faith in Christ and believe in the Gospel.",
                "required": true
            },
            {
                "id": 78,
                "content": "God uses me to bring healing to those who are emotionally sick.",
                "required": true
            },
            {
                "id": 79,
                "content": "Others have been helped and drawn to Christ through a language I can speak that I don't understand.",
                "required": true
            },
            {
                "id": 80,
                "content": "I have seen people be encouraged and blessed when I interpret the tongues of language another Christian is speaking.",
                "required": true
            },
            {
                "id": 81,
                "content": "I have a regular compulsion to share the Good News with others and to encourage Christians to do God's work.",
                "required": true
            },
            {
                "id": 82,
                "content": "When evaluating another person's spiritual condition, I tend to point out or see the errors in their understanding of the Christian life.",
                "required": true
            },
            {
                "id": 83,
                "content": "I am thrilled by the challenge and opportunity to share the Gospel with people I don't even know.",
                "required": true
            },
            {
                "id": 84,
                "content": "I become concerned and protective when a Christian or a group of Christians is threatened by enemies of Christ.",
                "required": true
            },
            {
                "id": 85,
                "content": "I enjoy talking to either small or large groups, explaining the exciting truths of the Bible.",
                "required": true
            },
            {
                "id": 86,
                "content": "When listening to others teach, I tend to get frustrated by studies that have little or no application.",
                "required": true
            },
            {
                "id": 87,
                "content": "I have a great desire to share with others all these things I am learning and discovering.",
                "required": true
            },
            {
                "id": 88,
                "content": "People seem to turn to me for ideas that are workable, for solutions to conflicting options, or for alternatives in discussions.",
                "required": true
            },
            {
                "id": 89,
                "content": "I find satisfaction in taking on a helping role in some worthy project.",
                "required": true
            },
            {
                "id": 90,
                "content": "It is fulfilling for me to see my home, my furnishings, my food and household supplies used for those outside my immediate family.",
                "required": true
            },
            {
                "id": 91,
                "content": "I feel moved to help when confronted with the urgent financial needs of others.",
                "required": true
            },
            {
                "id": 92,
                "content": "If I am in a group meeting and there is no evidence or assigned leader, I would want to appoint or ask someone in the group to lead.",
                "required": true
            },
            {
                "id": 93,
                "content": "It's challenging and fulfilling for me to guide a group of people to achieve their desired goals and objectives.",
                "required": true
            },
            {
                "id": 94,
                "content": "Generally speaking, I tend to help meet obvious needs without measuring the worthiness of the needy person or evaluating what the real needs may be.",
                "required": true
            },
            {
                "id": 95,
                "content": "It seems that I depend on God's resources and guidance much more than most others.",
                "required": true
            },
            {
                "id": 96,
                "content": "I am often able to see others' actions and know their real motives and inner attitudes.",
                "required": true
            },
            {
                "id": 97,
                "content": "When a situation seems humanly impossible. God gives me faith and confidence to speak or act so that a miracle transpires and people are helped.",
                "required": true
            },
            {
                "id": 98,
                "content": "I am strongly drawn toward those who are ill with different types of diseases and sicknesses, and know that God can heal them through my ministry.",
                "required": true
            },
            {
                "id": 99,
                "content": "Though I can't understand this language, I know that I am saying God's words and doing His will.",
                "required": true
            },
            {
                "id": 100,
                "content": "Though I don't know many languages, I seem to know what someone is saying when they speak in a language others don't know.",
                "required": true
            },
            {
                "id": 101,
                "content": "I have a great concern for those who have never heard the gospel and find myself thinking about going personally.",
                "required": true
            },
            {
                "id": 102,
                "content": "When I approach my personal devotions, I mostly prefer to relate to the verses emotionally, so as to get a personal blessing and application.",
                "required": true
            },
            {
                "id": 103,
                "content": "I find that people are open and listen to me when I share about my relationship with Christ.",
                "required": true
            },
            {
                "id": 104,
                "content": "It is important for me to know, keep in touch with, and be known by those Christians I am responsible for.",
                "required": true
            },
            {
                "id": 105,
                "content": "When I need to prepare a talk or lesson for teaching, I am motivated to carefully organize the Biblical truths, so that the listener clearly understands them.",
                "required": true
            },
            {
                "id": 106,
                "content": "When I give a testimony, I tend to encourage or console others, rather than just share an experience or verse from the Bible.",
                "required": true
            },
            {
                "id": 107,
                "content": "I like to study issues and passages of Scripture that are difficult to understand.",
                "required": true
            },
            {
                "id": 108,
                "content": "I do enjoy or would enjoy serving on a ruling or decision-making board since I like facing issues and seeing how they can be resolved for honoring God and helping God's work.",
                "required": true
            },
            {
                "id": 109,
                "content": "I prefer to be helping in the background, rather than up-front.",
                "required": true
            },
            {
                "id": 110,
                "content": "People seem to feel comfortable and at ease in my home, including those who need shelter and healing.",
                "required": true
            },
            {
                "id": 111,
                "content": "I am often willing to sacrifice personal desires to give more generously to God's work.",
                "required": true
            },
            {
                "id": 112,
                "content": "In terms of decision-making, I often make decisions easily and with some degree of confidence.",
                "required": true
            },
            {
                "id": 113,
                "content": "I almost automatically take responsibility or leadership when no other leaders have been designated.",
                "required": true
            },
            {
                "id": 114,
                "content": "I have a strong desire to do acts of love and kindness for those who cannot or will not return them to me.",
                "required": true
            },
            {
                "id": 115,
                "content": "I get excited about and feel at ease around others who pray much for situations and people's needs.",
                "required": true
            },
            {
                "id": 116,
                "content": "There are times when I sense particular teaching is unbiblical or off-balance.",
                "required": true
            },
            {
                "id": 117,
                "content": "When asked to serve in a ministry or leadership capacity, I tend to get involved in situations that require great faith in God and absolute miracles.",
                "required": true
            },
            {
                "id": 118,
                "content": "I have seen people come to Christ and/or be strengthened in their faith through the healing God has accomplished in their lives through my ministry to them.",
                "required": true
            },
            {
                "id": 119,
                "content": "When I have spoken an unknown language in the presence of other Christians, it has been interpreted as words of encouragement from God.",
                "required": true
            },
            {
                "id": 120,
                "content": "I believe I am God's spokesperson when I am interpreting a message in an unknown language given by another person.",
                "required": true
            },
            {
                "id": 121,
                "content": "I have had people encourage me to consider becoming a missionary.",
                "required": true
            },
            {
                "id": 122,
                "content": "If I have a choice of Bible passages to study, I mostly choose ones that are very practical.",
                "required": true
            },
            {
                "id": 123,
                "content": "I like sharing what Christ has done for me with both Christians and non-Christians.",
                "required": true
            },
            {
                "id": 124,
                "content": "I have a strong desire to give myself to struggling or straying Christians so that they are encouraged and brought back into the group.",
                "required": true
            },
            {
                "id": 125,
                "content": "When I read the Bible devotionally or for study, I prefer to search out truths that add to my understanding of truth.",
                "required": true
            },
            {
                "id": 126,
                "content": "When talking with another Christian or in a group situation, I tend to challenge them to take certain actions to move ahead in their Christian walk.",
                "required": true
            },
            {
                "id": 127,
                "content": "When faced with having to counsel another person, I basically avoid discussions about feelings and instead prefer to share Biblical insights and truths.",
                "required": true
            },
            {
                "id": 128,
                "content": "My reaction to the needs of others tends to be deliberate rather than responding emotionally because I want to make sure the issues have been thought through.",
                "required": true
            },
            {
                "id": 129,
                "content": "I am content to do menial jobs, or jobs others might consider unimportant.",
                "required": true
            },
            {
                "id": 130,
                "content": "I would be happy to add someone to my household temporarily in order to provide a helping hand or meet a need in someone's life.",
                "required": true
            },
            {
                "id": 131,
                "content": "My convictions and my life show that all I own is really God's and is to be used for His glory.",
                "required": true
            },
            {
                "id": 132,
                "content": "If asked to serve in a church or ministry program, I would tend to choose a position that involves detailed planning and decision-making.",
                "required": true
            },
            {
                "id": 133,
                "content": "I have a strong desire to motivate others toward Godly objectives.",
                "required": true
            },
            {
                "id": 134,
                "content": "I am fulfilled and content to meet the needs of the suffering or the undeserving.",
                "required": true
            },
            {
                "id": 135,
                "content": "What appears impossible to others, seems possible to me because God can do it.",
                "required": true
            },
            {
                "id": 136,
                "content": "I believe I am able to differentiate between demonic influence and mental illness.",
                "required": true
            },
            {
                "id": 137,
                "content": "God has caused many people to come to Christ who otherwise seemingly would not, through miracles God has done through me.",
                "required": true
            },
            {
                "id": 138,
                "content": "When reading the passages in the Bible describing healing, I can strongly identify with them, because I have seen them similarly accomplished in my ministry.",
                "required": true
            },
            {
                "id": 139,
                "content": "Sometimes I just want to praise and thank God and find myself saying words I have never heard or learned before.",
                "required": true
            },
            {
                "id": 140,
                "content": "When someone is speaking in a God-given language, I hear them praising God and telling of His great works.",
                "required": true
            },
            {
                "id": 141,
                "content": "People have encouraged me to help start a new church or be a church planter.",
                "required": true
            },
            {
                "id": 142,
                "content": "When called upon to serve, I am most naturally motivated to help in situations in which there are specific spiritual needs.",
                "required": true
            },
            {
                "id": 143,
                "content": "Unbelievers understand and seem to respond when I explain who Jesus really is.",
                "required": true
            },
            {
                id: 144,
                content: "I find that the Christians I work with and minister to are fed by my ministry and application of Bible truths to their lives.",
                required: true
            },
            {
                id: 145,
                content: "When I give a testimony, I tend to share some truth that has come alive to me through experience or verses I've read.",
                required: true
            },
            {
                id: 146,
                content: "When reading the Bible for myself, I prefer to study passages with the purpose of changing specific areas of conduct or wrong attitudes.",
                required: true
            },
            {
                id: 147,
                content: "If I have to teach or speak before another person or a group, I am most comfortable presenting a thorough, detailed study of a topic or passage from the Bible.",
                required: true
            },
            {
                id: 148,
                content: "I can readily see how Biblical truth should be applied to my life and to others.",
                required: true
            },
            {
                id: 149,
                content: "Practical things like typing, cleaning, fixing, ushering, and other support tasks are meaningful and important for me to do.",
                required: true
            },
            {
                id: 150,
                content: "I am not threatened by strangers or new people. In fact, I find myself drawn to them to help them feel welcome.",
                required: true
            },
            {
                id: 151,
                content: "With regard to financial matters, I am concerned that God's money and ministry be used as efficiently as possible to accomplish more for people.",
                required: true
            },
            {
                id: 152,
                content: "I am able to lead a board, group, or committee in harmonizing various viewpoints to make a decision together.",
                required: true
            },
            {
                id: 153,
                content: "I usually know where we should be going as a group and can motivate others in that direction too.",
                required: true
            },
            {
                id: 154,
                content: "I cheerfully do tasks others find distasteful to minister to the suffering and desperately needy people.",
                required: true
            },
            {
                id: 155,
                content: "I am convinced that, despite circumstances, God is going to 'keep His promises, and my life shows it.",
                required: true
            },
            {
                id: 156,
                content: "I seem to be able to judge well between the evil and the good, the acceptable and the unacceptable.",
                required: true
            },
            {
                id: 157,
                content: "When God does a miracle through my ministry, it is so obvious that people are in awe of God and wonder at His power.",
                required: true
            },
            {
                id: 158,
                content: "I am deeply concerned for those who are ill in any way and am bothered that the church doesn't do more to bring healing to them.",
                required: true
            },
            {
                id: 159,
                content: "When I speak in this new language I feel fulfilled and blessed - as if God has spoken to me and through me.",
                required: true
            },
            {
                id: 160,
                content: "It gives me great joy to interpret the words of another Christian who is speaking in tongues.",
                required: true
            },
            {
                id: 161,
                content: "The idea of traveling to new areas to live and work is attractive and challenging to me.",
                required: true
            },
            {
                id: 162,
                content: "When the opportunity comes up to counsel someone, I tend to give them the best Biblical solution I can think of.",
                required: true
            },
            {
                id: 163,
                content: "I get frustrated when I see that the church or other Christians don't seem to care as much about the lost as I do.",
                required: true
            },
            {
                id: 164,
                content: "People who have wandered from Christ or from the church body seem to respond well to my love and concern and are often brought back.",
                required: true
            },
            {
                id: 165,
                content: "I seem to have the desire and the ability to dig into the Bible, search out the meaning of words and phrases, see how they fit into the great doctrines of the faith, and want to apply them to daily life situations.",
                required: true
            },
            {
                id: 166,
                content: "My reaction to the needs of others tends to be quick because I can usually sense what needs to be done and can tell them what they need to do.",
                required: true
            },
            {
                id: 167,
                content: "When serving in a church or Christian group, I am motivated to serve in areas where there are needs that challenge me mentally.",
                required: true
            },
            {
                id: 168,
                content: "It seems I am able to consistently make correct decisions for my life and family.",
                required: true
            },
            {
                id: 169,
                content: "I knew key leaders can only do their job if someone takes over the supportive responsibilities — this is my place.",
                required: true
            },
            {
                id: 170,
                content: "It would be a joy for me to host luncheons, dinners, or coffees for new residents in my area, or in my church.",
                required: true
            },
            {
                id: 171,
                content: "I joyfully and cheerfully give to God's work and God's people.",
                required: true
            },
            {
                id: 172,
                content: "I enjoy and am able to recruit Christians to use their talents and gifts in ministry.",
                required: true
            },
            {
                id: 173,
                content: "I notice through my Iifestyle, actions, and ideas I seem to motivate other Christians to follow me.",
                required: true
            },
            {
                id: 174,
                content: "I enjoy being an agent of blessing to those in hospitals and nursing homes.",
                required: true
            },
            {
                id: 175,
                content: "When I feel that God has led, I seem to have the unusual assurance that it will be accomplished, no matter what.",
                required: true
            },
            {
                id: 176,
                content: "In Christian, as well as secular, environments I am able to detect error and false teaching and relate it to truth.",
                required: true
            },
            {
                id: 177,
                content: "I find great joy in seeing God's miracles work through me so that people have greater faith in God.",
                required: true
            },
            {
                id: 178,
                content: "God uses my prayer and/or healing touch to bring healing to others.",
                required: true
            },
            {
                id: 179,
                content: "When I speak in this new language I have great confidence and assurance that God is speaking through me to others.",
                required: true
            },
            {
                id: 180,
                content: "God uses me as an interpreter of languages so others can be helped.",
                required: true
            },
            {
                id: 181,
                content: "When God leads me to start something from scratch I can stick with it no matter what may interfere.",
                required: true
            },
            {
                id: 182,
                content: "I have people tell me that they are encouraged, comforted, or edified by my talking with them or by my teaching/preaching.",
                required: true
            },
            {
                id: 183,
                content: "Some of the most joyful and fulfilling moments of my life have been leading others to Christ.",
                required: true
            },
            {
                id: 184,
                content: "I seem to often be aware of the needs of a group of Christians I am with and would like to give guidance to the group if allowed.",
                required: true
            },
            {
                id: 185,
                content: "I am able to explain well to others what the Bible and Christianity are all about.",
                required: true
            },
            {
                id: 186,
                content: "I find myself often encouraging those who are weak, wavering, doubting, or hurting in some way.",
                required: true
            },
            {
                id: 187,
                content: "Learning information and Biblical truths comes easily to me.",
                required: true
            },
            {
                id: 188,
                content: "The advice I give to groups or individuals seems to work well.",
                required: true
            },
            {
                id: 189,
                content: "When called on to serve, I am most comfortable and motivated to help in situations of specific material needs.",
                required: true
            },
            {
                id: 190,
                content: "I show genuine love and interest in each guest in my home or ministry group.",
                required: true
            },
            {
                id: 191,
                content: "When I think about how much I love God, I want to give all the money and things I can to Him and His work.",
                required: true
            },
            {
                id: 192,
                content: "I find great joy and fulfilment in organizing a project or ministry so that others know what to do and are effective in doing it.",
                required: true
            },
            {
                id: 193,
                content: "Even in groups with different maturity levels, I seem to be able to lead with a positive response from the group.",
                required: true
            },
            {
                id: 194,
                content: "Even though people may bring problems on themselves and even justly deserve it, God uses me to help them and spare them some of the consequences.",
                required: true
            },
            {
                id: 195,
                content: "When planning for the future of my church or Christian group, I am more concerned with seeing the end results than with concerning myself with the details of getting there.",
                required: true
            },
            {
                id: 196,
                content: "Usually, I can spot a phony person before others do.",
                required: true
            },
            {
                id: 197,
                content: "In situations where nothing would convince people that God is real, I have seen Him do miracles through me.",
                required: true
            },
            {
                id: 198,
                content: "Other Christians seek me out to pray for healing.",
                required: true
            },
            {
                id: 199,
                content: "I have had people come to faith in Christ by hearing this language and its interpretation.",
                required: true
            },
            {
                id: 200,
                content: "Sometimes when I interpret tongues, I find that others come to Christ through the message I give.",
                required: true
            }


        ],
        success: {
            congratulations: "Congratulations.",
            assessmentArrived: "Your Spiritual Gifts Assessment Has Arrived!",
            directing: "Redirecting to your questionnaire ..."
        },
        error: {
            TransactionFailed: "Transaction Failed",
            tryagain1: "Please click ",
            here: "HERE",
            tryagain2: " to try again",
            or: ' or ',
            contactus: "contact us.",
        }
    },
    es: {
        form: {
            name: "Nombre",
            email: "Correo electrónico",
            error: {
                nameIsRequired: "Se requiere el nombre.",
                valideMailIsRequired: "Se requiere un correo electrónico válido.",
            }
        },
        questionaire: {
            title: "Evaluación de los Dones Espirituales",
            subtitle: "Un servicio de ScriptureCast.com",
            questiontitle: "Evaluación de los Dones Espirituales",
            notification: "Notificación",
            button: {
                save: 'Ahorrar',
                submit: 'Entregar',
                print: " Imprime esta página",
                goToBottom: "Ir al fondo",
                copy: "Copiar",
                close: "Cerca",
            },
            sentences: {
                notFound: "404 Pagina no encontrada.",
                assessmentResult: "Resultado de la evaluación",
                thankyou: "Gracias por tomarse el tiempo para completar la Evaluación de los dones espirituales. Su resultado final es ",
                yourSpiritualReferences: "Sus Referencias Espirituales: ",
                chartBelow: "La tabla a continuación muestra nuestro análisis en el que puede ser dotado de la evaluación.",
                saveModalContent1: "Tu progreso ha sido guardado.",
                saveModalContent2: "Guarde el enlace a continuación para continuar la próxima vez:",
                completequestion1: "Complete ",
                completequestion2: " pregunta(s) restante(s) para enviar.",
                remindnNameAndEmail: "Haga clic AQUÍ para ingresar su nombre y correo electrónico.",
                paymentIssue: "Estamos experimentando algunos problemas con el método de pago.",
                contactForSupports: "Contacta con nosotros para apoyos.",
                apologize: "Nos disculpamos por cualquier inconveniente causado. ",
                questionsToBeCompleted: "Preguntas a completar: ",
                saving: "Ahorro...",
                copied: "Copiada/o",
                saved: "Salvado",
            }
        },
        instruction: {
            title: "Instrucciones",
            content:
                `
            <div>
                Las siguientes preguntas le ayudarán a determinar si posee dones o si tiene inclinación hacia ellos. Después de cada pregunta, marque un número según cuánto esta característica es evidente en su vida. Los números tienen el siguiente significado:
            </div>
            <div>
            <div>0 = no es verdad en mi vida</div>
            <div>1 = a veces es verdad en mi vida</div>
            <div>2 = regularmente es verdad en mi vida</div>
            <div>3 = casi siempre es verdad en mi vida</div>
            </div>
            <div> 
                Después de completar esta Evaluación de los Dones Espirituales, recibirá un gráfico de barras que representa las áreas en las que puede tener dones. En la sección siguiente al resumen gráfico, encontrará una lista de todos los dones y sus referencias bíblicas.
            </div>
            <div>
                <strong>NOTA:</strong> Puede resultar útil tener a un compañero u otras personas que le conozcan bien para revisar sus respuestas. A veces nos vemos a nosotros mismos de manera diferente a como nos ven los demás. A veces respondemos a las preguntas como nos gustaría ser en lugar de cómo somos en realidad. Tener a alguien más que revise nuestras respuestas puede brindarnos una valiosa segunda opinión.             
            </div>
            <div>
                <strong>NOTA:</strong> Este cuestionario abarca diversos aspectos, y entendemos que puede requerir múltiples sesiones para completarlo. Tiene la flexibilidad de guardar su progreso y volver a él más tarde cuando le convenga haciendo clic en el botón Guardar.</br>
Para volver al cuestionario, simplemente agregue esta página a sus marcadores o copie la URL de la barra de direcciones de su navegador. También puede abrir el enlace directamente desde el correo electrónico que le enviamos, asegurando un acceso fácil para continuar desde donde lo dejó.
            </div>
            `
        },
        description: {
            title: "Descripción de los dones espirituales",
            content: [
                {
                    title: "Apostolado",
                    en_title: "Apostleship",
                    content: "La capacidad y la responsabilidad de ser enviado por Jesucristo para comunicar su evangelio y establecer su Iglesia, especialmente donde no ha estado antes.",
                    reference: [
                        {
                            title: "1Corintios 12:28",
                            link: "https://www.biblica.com/america-latina/biblia/biblia-online/nvi/1-cr%c3%b3nicas/12/"
                        },
                        {
                            title: "Efesios 4:11",
                            link: "https://www.biblica.com/america-latina/biblia/biblia-online/nvi/efesios/4/"
                        }
                    ]
                },
                {
                    title: "Profecía",
                    en_title: "Prophecy",
                    content: "La capacidad y la responsabilidad de hablar eficazmente el mensaje de Dios a las personas.",
                    reference: [
                        {
                            title: "Romanos 12:6",
                            link: "https://www.biblica.com/america-latina/biblia/biblia-online/nvi/romanos/12/"
                        },
                        {
                            title: "Efesios 4:11",
                            link: "https://www.biblica.com/america-latina/biblia/biblia-online/nvi/efesios/4/"
                        },
                        {
                            title: "1Corintios 12:10,28",
                            link: "https://www.biblica.com/america-latina/biblia/biblia-online/nvi/1-cr%c3%b3nicas/12/"
                        }
                    ]
                },
                {
                    title: "Evangelizacion",
                    en_title: "Evangelism",
                    content: "La capacidad y responsabilidad de comunicar efectivamente las buenas nuevas de salvación a través de Jesús de tal manera que las personas respondan y se conviertan.",
                    reference: [
                        {
                            title: "Efesios 4:11",
                            link: "https://www.biblica.com/america-latina/biblia/biblia-online/nvi/efesios/4/"
                        }
                    ]
                },
                {
                    title: "Pastoreo",
                    en_title: "Shepherding",
                    content: "La capacidad y responsabilidad de guiar, alimentar y proteger espiritualmente al pueblo de Dios.",
                    reference: [
                        {
                            title: "Efesios 4:11",
                            link: "https://www.biblica.com/america-latina/biblia/biblia-online/nvi/efesios/4/"
                        }
                    ]
                },
                {
                    title: "Enseñanza",
                    en_title: "Teaching",
                    content: "La función principal es explicar la verdad de Dios y cómo aplicarla a la vida de una manera significativa para el crecimiento cristiano.",
                    reference: [
                        {
                            title: "Romanos 12:7",
                            link: "https://www.biblica.com/america-latina/biblia/biblia-online/nvi/romanos/12/"
                        },
                        {
                            title: "1Corintios 12:28",
                            link: "https://www.biblica.com/america-latina/biblia/biblia-online/nvi/1-cr%c3%b3nicas/12/"
                        }
                    ]
                },
                {
                    title: "Exhortación",
                    en_title: "Exhortation",
                    content: "La capacidad y la responsabilidad de acompañar y proporcionar aliento, fortaleza, consuelo y ayuda.",
                    reference: [
                        {
                            title: "Romanos 12:8",
                            link: "https://www.biblica.com/america-latina/biblia/biblia-online/nvi/romanos/12/"
                        }
                    ]
                },
                {
                    title: "Conocimiento",
                    en_title: "Knowledge",
                    content: "La habilidad sobrenatural y la responsabilidad de investigar y sistematizar los hechos y verdades relacionados con la verdad revelada de Dios.",
                    reference: [
                        {
                            title: "1Corintios 12:8",
                            link: "https://www.biblica.com/america-latina/biblia/biblia-online/nvi/1-cr%c3%b3nicas/12/"
                        },
                    ]
                },
                {
                    title: "Sabiduría",
                    en_title: "Wisdom",
                    content: "La capacidad y la responsabilidad de interpretar, demostrar perspicacia y comprender hechos para la aplicación piadosa.",
                    reference: [
                        {
                            title: "1Corintios 12:8",
                            link: "https://www.biblica.com/america-latina/biblia/biblia-online/nvi/1-cr%c3%b3nicas/12/"
                        }
                    ]
                },
                {
                    title: "Ayuda",
                    en_title: "Helps",
                    content: "La capacidad y la responsabilidad de brindar asistencia y apoyo cuando aparezca una necesidad.",
                    reference: [
                        {
                            title: "Proverbio 19:17",
                            link: "https://www.biblica.com/america-latina/biblia/biblia-online/nvi/proverbios/19/"
                        },
                        {
                            title: "Mateo 25:44-45",
                            link: "https://www.biblica.com/america-latina/biblia/biblia-online/nvi/mateo/25/"
                        },
                        {
                            title: "Hebreos 13:16",
                            link: "https://www.biblica.com/america-latina/biblia/biblia-online/nvi/hebreos/13/"
                        }
                    ]
                },
                {
                    title: "Hospitalidad",
                    en_title: "Hospitality",
                    content: "La capacidad y responsabilidad de proporcionar bienvenida, amistad, compañerismo, comida y / o alojamiento, especialmente a los recién llegados y extraños.",
                    reference: [
                        {
                            title: "Romanos 12:13",
                            link: "https://www.biblica.com/america-latina/biblia/biblia-online/nvi/romanos/12/"
                        },
                        {
                            title: "Hebreos 13:2",
                            link: "https://www.biblica.com/america-latina/biblia/biblia-online/nvi/hebreos/13/"
                        }
                    ]
                },
                {
                    title: "Dar",
                    en_title: "Giving",
                    content: "La capacidad y la responsabilidad de dar materialmente a los demás y a la obra del Señor con generosidad y gozo.",
                    reference: [
                        {
                            title: "Romanos 12:8",
                            link: "https://www.biblica.com/america-latina/biblia/biblia-online/nvi/romanos/12/"
                        }
                    ]
                },
                {
                    title: "Gobierno",
                    en_title: "Government",
                    content: "La capacidad y la responsabilidad de entender las metas de Dios para un grupo o tarea y organizar eficazmente al grupo para lograr esas metas.",
                    reference: [
                        {
                            title: "Romanos 12:8",
                            link: "https://www.biblica.com/america-latina/biblia/biblia-online/nvi/romanos/12/"
                        },
                        {
                            title: "1Corintios 12:28",
                            link: "https://www.biblica.com/america-latina/biblia/biblia-online/nvi/1-cr%c3%b3nicas/12/"
                        }
                    ]
                },
                {
                    title: "Liderazgo",
                    en_title: "Leadership",
                    content: "La capacidad y la responsabilidad de sentir y conocer las metas de Dios de la iglesia o ministerio y guiar al cuerpo hacia el cumplimiento de esas metas.",
                    reference: [
                        {
                            title: "Éxodo 18:21",
                            link: "https://www.biblica.com/america-latina/biblia/biblia-online/nvi/%C3%A9xodo/18/"
                        },
                        {
                            title: "Proverbio 11:14",
                            link: "https://www.biblica.com/america-latina/biblia/biblia-online/nvi/proverbios/11/"
                        },
                        {
                            title: "Mateo 20:25-28",
                            link: "https://www.biblica.com/america-latina/biblia/biblia-online/nvi/mateo/20/"
                        },
                        {
                            title: "Filipenses 2:3",
                            link: "https://www.biblica.com/america-latina/biblia/biblia-online/nvi/filipenses/2/"
                        }
                    ]
                },
                {
                    title: "Misericordia",
                    en_title: "Mercy",
                    content: "La capacidad y la responsabilidad de sentir compasión por el que sufre y de tomar medidas alegres para aliviar ese dolor.",
                    reference: [
                        {
                            title: "Romanos 12:8",
                            link: "https://www.biblica.com/america-latina/biblia/biblia-online/nvi/romanos/12/"
                        }
                    ]
                },
                {
                    title: "Fe",
                    en_title: "Faith",
                    content: "La capacidad y la responsabilidad de discernir con confianza la voluntad y los propósitos de Dios para su obra y creer que Dios la logrará, incluso cuando parezca imposible.",
                    reference: [
                        {
                            title: "1Corintios 12:9",
                            link: "https://www.biblica.com/america-latina/biblia/biblia-online/nvi/1-cr%c3%b3nicas/12/"
                        }
                    ]
                },
                {
                    title: "Discernimiento",
                    en_title: "Discernment",
                    content: ": La capacidad y la responsabilidad de distinguir entre el espíritu de verdad y el espíritu de error.",
                    reference: [
                        {
                            title: "1Corintios 12:10",
                            link: "https://www.biblica.com/america-latina/biblia/biblia-online/nvi/1-cr%c3%b3nicas/12/"
                        }
                    ]
                },
                {
                    title: "Milagros",
                    en_title: "Miracles",
                    content: "La capacidad y responsabilidad de autorizar la Palabra de Dios a través de actos sobrenaturales.",
                    reference: [
                        {
                            title: "1Corintios 12:10,28",
                            link: "https://www.biblica.com/america-latina/biblia/biblia-online/nvi/1-cr%c3%b3nicas/12/"
                        }
                    ]
                },
                {
                    title: "Sanación",
                    en_title: "Healing",
                    content: "La capacidad y la responsabilidad de curar a las personas enfermas interviniendo de manera sobrenatural como un instrumento de Dios.",
                    reference: [
                        {
                            title: "1Corintios 12:9,28",
                            link: "https://www.biblica.com/america-latina/biblia/biblia-online/nvi/1-cr%c3%b3nicas/12/"
                        },
                    ]
                },
                {
                    title: "Lenguas",
                    en_title: "Tongues",
                    content: "La capacidad y responsabilidad de escuchar palabras en un idioma que no ha sido aprendido.",
                    reference: [
                        {
                            title: "1Corintios 12:10,28,30",
                            link: "https://www.biblica.com/america-latina/biblia/biblia-online/nvi/1-cr%c3%b3nicas/12/"
                        },
                    ]
                },
                {
                    title: "Interpretación de lenguas",
                    en_title: "Interpretation of Tongues",
                    content: "La capacidad y la responsabilidad de escuchar palabras habladas en lenguas y luego comunicar esas palabras en un idioma que los oyentes puedan entender.",
                    reference: [
                        {
                            title: "1Corintios 12:10,30",
                            link: "https://www.biblica.com/america-latina/biblia/biblia-online/nvi/1-cr%c3%b3nicas/12/"
                        },
                    ]
                },

            ]
        },
        answers: [
            {
                content: "No es verdad en mi vida",
                score: 0,
            },
            {
                content: "A veces es verdad en mi vida",
                score: 1,
            },
            {
                content: "Regularmente es verdad en mi vida",
                score: 2,
            },
            {
                content: "Casi siempre es verdad en mi vida",
                score: 3,
            }
        ],
        questions: [
            {
                "id": 1,
                "content": "Soy bueno/a visualizando algo antes de que se convierta en realidad y tiendo a saber cómo hacer que suceda.",
                "required": true
            },
            {
                "id": 2,
                "content": "Cuando escucho un sermón pobre, pienso en otras formas de transmitir las palabras de Dios a esas personas.",
                "required": true
            },
            {
                "id": 3,
                "content": "Aunque a veces tengo miedo, siento la necesidad de compartir mi fe con aquellos con quienes entro en contacto, y me sorprende con qué frecuencia responden.",
                "required": true
            },
            {
                "id": 4,
                "content": "Me siento frustrado/a si la iglesia o un grupo de cristianos no está siendo cuidado espiritualmente de la manera que sé que podrían y deberían serlo. A veces desearía poder hacer el trabajo.",
                "required": true
            },
            {
                "id": 5,
                "content": "Cuando tengo la oportunidad de enseñar o comunicar verdades bíblicas a otros, parecen verse afectados en conocimiento, actitudes y acciones.",
                "required": true
            },
            {
                "id": 6,
                "content": "Siento un fuerte impulso de desafiar verbalmente a aquellos que son espiritualmente complacientes y apáticos.",
                "required": true
            },
            {
                "id": 7,
                "content": "Tengo la habilidad de descubrir verdades y principios bíblicos por mí mismo/a.",
                "required": true
            },
            {
                "id": 8,
                "content": "Mi evaluación de las personas, situaciones y necesidades parece ser mayormente correcta.",
                "required": true
            },
            {
                "id": 9,
                "content": "Cuando se presenta una necesidad física o espiritual, tiendo a responder mejor si la necesidad no requiere muchos detalles organizativos o preparación personal.",
                "required": true
            },
            {
                "id": 10,
                "content": "Veo mi hogar como un refugio para los solitarios, los alienados y los extraños.",
                "required": true
            },
            {
                "id": 11,
                "content": "Administro bien mi dinero y limito mi nivel de vida para dar generosamente a la obra de Dios.",
                "required": true
            },
            {
                "id": 12,
                "content": "A menudo las personas me buscan para establecer procedimientos y planes para realizar cambios o mejoras en los ministerios actuales.",
                "required": true
            },
            {
                "id": 13,
                "content": "En general, tiendo a ser más sensible a la dirección y metas organizativas generales que a las opiniones minoritarias o individuales.",
                "required": true
            },
            {
                "id": 14,
                "content": "En situaciones que requieren toma de decisiones organizativas, tiendo a carecer de firmeza porque me preocupa los sentimientos de las personas.",
                "required": true
            },
            {
                "id": 15,
                "content": "Tiendo a animar a otros cristianos a confiar en Dios y pensar en grande en términos de ciertos objetivos y acciones.",
                "required": true
            },
            {
                "id": 16,
                "content": "Otros cristianos parecen buscar en mí discernimiento sobre la veracidad de una enseñanza o persona en particular.",
                "required": true
            },
            {
                "id": 17,
                "content": "Sé que nada es imposible para Dios y he visto evidencia de eso a través de milagros específicos en mi ministerio para Dios.",
                "required": true
            },
            {
                "id": 18,
                "content": "Aunque no todos son sanados, veo a Dios sanando milagrosamente a individuos a través de mi ministerio hacia ellos.",
                "required": true
            },
            {
                "id": 19,
                "content": "He tenido experiencias inusuales usando este lenguaje desconocido, especialmente deseando hablarle a personas que no conozco para que puedan conocer a Cristo.",
                "required": true
            },
            {
                "id": 20,
                "content": "Otros cristianos y la iglesia cuentan conmigo para interpretar un lenguaje dado por Dios que alguien está hablando.",
                "required": true
            },
            {
                "id": 21,
                "content": "Tengo el deseo de ayudar a comenzar la obra para Dios donde actualmente no existe.",
                "required": true
            },
            {
                "id": 22,
                "content": "Siento una urgencia interna para persuadir a las personas a tomar decisiones y compromisos espirituales.",
                "required": true
            },
            {
                "id": 23,
                "content": "Tengo una preocupación constante por las personas que no conocen a Cristo y me gustaría compartir el evangelio con ellos.",
                "required": true
            },
            {
                "id": 24,
                "content": "Tengo el deseo de cuidar del bienestar espiritual de los nuevos cristianos.",
                "required": true
            },
            {
                "id": 25,
                "content": "Tengo una profunda convicción de que las verdades bíblicas deben ser entendidas y aplicadas a la vida diaria.",
                "required": true
            },
            {
                "id": 26,
                "content": "Acepto a las personas que parecen estar profundamente afligidas o en crisis.",
                "required": true
            },
            {
                "id": 27,
                "content": "Me siento motivado/a para estudiar y aprender verdades e información bíblica.",
                "required": true
            },
            {
                "id": 28,
                "content": "Tengo un gran respeto por Dios y Sus propósitos para toda la vida, incluyendo la mía.",
                "required": true
            },
            {
                "id": 29,
                "content": "Cuando escucho a un orador, me siento fuertemente impresionado/a y quiero responder a las exhortaciones para servir a otros cristianos.",
                "required": true
            },
            {
                "id": 30,
                "content": "Siento una gran alegría al recibir en mi hogar a personas que necesitan ministerio.",
                "required": true
            },
            {
                "id": 31,
                "content": "Cuando se presenta una necesidad física o espiritual, tiendo a responder por mi propia iniciativa para tratar de satisfacerla, especialmente a través de generosidad monetaria o material.",
                "required": true
            },
            {
                "id": 32,
                "content": "En lo que respecta a la planificación del futuro de mi iglesia u organización, tiendo a preocuparme y estar dispuesto/a a trabajar en detalle en los planes.",
                "required": true
            },
            {
                "id": 33,
                "content": "En un grupo u organización, prefiero liderar.",
                "required": true
            },
            {
                "id": 34,
                "content": "Siento un profundo dolor por aquellos que están enfermos, encarcelados, en situación de pobreza o quebrantados de alguna otra manera.",
                "required": true
            },
            {
                "id": 35,
                "content": "Por lo general, tengo la tendencia a visualizar las metas de Dios para Su obra y trabajar hacia ellas a pesar de los obstáculos.",
                "required": true
            },
            {
                "id": 36,
                "content": "Tiendo a investigar y analizar para determinar las verdaderas condiciones o necesidades espirituales de otros cristianos.",
                "required": true
            },
            {
                "id": 37,
                "content": "He quedado asombrado/a, y también otros, por los milagros que Dios ha hecho a través de mi vida para el bien de los demás.",
                "required": true
            },
            {
                "id": 38,
                "content": "Cuando se me presentan oportunidades de ministerio, tiendo a aceptar ministerios donde haya personas heridas o enfermas a las que Dios pueda sanar a través de mí.",
                "required": true
            },
            {
                "id": 39,
                "content": "Tengo la habilidad de hablar un idioma que no entiendo.",
                "required": true
            },
            {
                "id": 40,
                "content": "Dios me ha dado la capacidad de entender y proclamar Su verdad en idiomas que nunca he aprendido antes.",
                "required": true
            },
            {
                "id": 41,
                "content": "Creo que Dios me está llamando a ser misionero/a donde hay una gran necesidad.",
                "required": true
            },
            {
                "id": 42,
                "content": "Me siento impulsado/a a desafiar a las personas con la verdad de Dios, ayudándoles a ver cuánto la Palabra y el camino de Dios pueden ayudarles.",
                "required": true
            },
            {
                "id": 43,
                "content": "Disfruto compartir mi fe cuando se presenta la oportunidad.",
                "required": true
            },
            {
                "id": 44,
                "content": "Siento la necesidad de reunir a los cristianos y ayudarles a crecer y servirse mutuamente.",
                "required": true
            },
            {
                "id": 45,
                "content": "Me emociona el significado de las palabras y las verdades de la Biblia.",
                "required": true
            },
            {
                "id": 46,
                "content": "No solo estoy dispuesto/a, sino que encuentro satisfacción en ser llamado/a junto a otra persona que busca aliento, consejo o desafío.",
                "required": true
            },
            {
                "id": 47,
                "content": "Adquirir más conocimiento sobre Dios, la Biblia y la doctrina me emociona.",
                "required": true
            },
            {
                "id": 48,
                "content": "Cuando me enfrento a aconsejar a otra persona, tengo cierta confianza porque sinceramente creo que Dios me ayuda a ver soluciones a los problemas de los demás.",
                "required": true
            },
            {
                "id": 49,
                "content": "En una organización, prefiero ser un seguidor/a con tareas prácticas para ayudar a que las cosas sucedan y se desarrollen sin problemas.",
                "required": true
            },
            {
                "id": 50,
                "content": "Disfruto conocer a nuevas personas y hacer que se sientan amadas, aceptadas y cómodas.",
                "required": true
            },
            {
                "id": 51,
                "content": "En cuanto a asuntos financieros, me siento motivado/a a dar todo lo que pueda a personas/organizaciones que considero dignas.",
                "required": true
            },
            {
                "id": 52,
                "content": "Soy capaz y estoy dispuesto/a a aprender habilidades administrativas como planificación, organización y delegación.",
                "required": true
            },
            {
                "id": 53,
                "content": "Soy capaz de evaluar rápidamente las necesidades de un grupo y descubrir cómo se deben lograr sus objetivos.",
                "required": true
            },
            {
                "id": 54,
                "content": "Cuando me enfrento a aconsejar a otra persona, tiendo a identificarme profundamente con su situación.",
                "required": true
            },
            {
                "id": 55,
                "content": "Estoy convencido/a de que Dios responde a la oración y que Él obra específicamente a través de mi vida de oración.",
                "required": true
            },
            {
                "id": 56,
                "content": "En general, tiendo a ser sabio/a al discernir la personalidad, el carácter y la espiritualidad de los demás.",
                "required": true
            },
            {
                "id": 57,
                "content": "Dios ha quebrantado las leyes naturales en respuesta a mis oraciones, de manera que se han producido milagros.",
                "required": true
            },
            {
                "id": 58,
                "content": "Dios me ha utilizado para llevar sanidad física a otros más allá de los procesos de sanación naturales.",
                "required": true
            },
            {
                "id": 59,
                "content": "A través de la interpretación de lo que digo, puedo hablar de la bondad y la salvación de Dios en un idioma que nunca aprendí.",
                "required": true
            },
            {
                "id": 60,
                "content": "Cuando alguien habla en lenguas, siento un impulso abrumador de decir lo que significa y estar seguro/a de que es correcto.",
                "required": true
            },
            {
                "id": 61,
                "content": "Me gusta ayudar a organizar y comenzar nuevos ministerios.",
                "required": true
            },
            {
                "id": 62,
                "content": "Cuando comparto mi testimonio, tiendo a señalar algún aspecto doctrinal que ha cobrado vida para mí a través de una experiencia o versículo de la Biblia.",
                "required": true
            },
            {
                "id": 63,
                "content": "Me siento cómodo/a compartiendo cómo Cristo es mi Salvador y Señor con los demás.",
                "required": true
            },
            {
                "id": 64,
                "content": "Encuentro alegría y satisfacción cuando soy responsable del crecimiento de un grupo de cristianos.",
                "required": true
            },
            {
                "id": 65,
                "content": "Estoy convencido/a de que para que los cristianos y la iglesia crezcan, debe haber una enseñanza de calidad y un aprendizaje significativo.",
                "required": true
            },
            {
                "id": 66,
                "content": "Cuando aconsejo a otra persona, tiendo a identificarme profundamente con su situación y quiero ayudarle a superarla.",
                "required": true
            },
            {
                "id": 67,
                "content": "Me gustaría tener una respuesta bíblica para todo lo posible, tanto es así que tiendo a estudiar los asuntos en profundidad.",
                "required": true
            },
            {
                "id": 68,
                "content": "Tiendo a ser muy efectivo/a para resolver problemas utilizando principios bíblicos.",
                "required": true
            },
            {
                "id": 69,
                "content": "Cuando hay una tarea por hacer, prefiero hacerla yo mismo/a en lugar de delegarla a otra persona.",
                "required": true
            },
            {
                "id": 70,
                "content": "Sin preocuparme por la pulcritud o preparación de mi hogar, me siento cómodo/a recibiendo a personas en casa.",
                "required": true
            },
            {
                "id": 71,
                "content": "Cuando me llaman a servir, me siento motivado/a para ayudar en situaciones en las que hay necesidades materiales específicas, como comida, dinero, equipos, edificios, etc.",
                "required": true
            },
            {
                "id": 72,
                "content": "Disfruto y tengo la capacidad de organizar ideas, personas y proyectos para un ministerio más efectivo.",
                "required": true
            },
            {
                "id": 73,
                "content": "Disfruto y me resulta fácil motivar a otros para que lleven a cabo un proyecto de ministerio.",
                "required": true
            },
            {
                "id": 74,
                "content": "Si pudiera elegir los pasajes de la Biblia para estudiar, elegiría aquellos que son muy prácticos.",
                "required": true
            },
            {
                "id": 75,
                "content": "Siento una gran satisfacción y alegría al perseverar en la oración por necesidades específicas.",
                "required": true
            },
            {
                "id": 76,
                "content": "Por lo general, puedo detectar debilidades y obstáculos al evaluar oportunidades y situaciones.",
                "required": true
            },
            {
                "id": 77,
                "content": "A medida que Dios realiza milagros a través de mi vida y palabras, las personas llegan a la fe en Cristo y creen en el Evangelio.",
                "required": true
            },
            {
                "id": 78,
                "content": "Otros han sido ayudados y atraídos a Cristo a través de un lenguaje que puedo hablar pero no entiendo.",
                "required": true
            },
            {
                "id": 79,
                "content": "He visto que las personas son alentadas y bendecidas cuando interpreto las lenguas de otro cristiano que está hablando.",
                "required": true
            },
            {
                "id": 80,
                "content": "He visto que las personas se sienten alentadas y bendecidas cuando interpreto las lenguas de otro cristiano que está hablando.",
                "required": true
            },
            {
                "id": 81,
                "content": "Siento una compulsión regular de compartir las Buenas Nuevas con los demás y animar a los cristianos a hacer la obra de Dios.",
                "required": true
            },
            {
                "id": 82,
                "content": "Cuando evalúo la condición espiritual de otra persona, tiendo a señalar o ver los errores en su comprensión de la vida cristiana.",
                "required": true
            },
            {
                "id": 83,
                "content": "Me entusiasma el desafío y la oportunidad de compartir el Evangelio con personas que ni siquiera conozco.",
                "required": true
            },
            {
                "id": 84,
                "content": "Me preocupo y me siento protector/a cuando un cristiano o un grupo de cristianos está amenazado por enemigos de Cristo.",
                "required": true
            },
            {
                "id": 85,
                "content": "Disfruto hablando ante grupos pequeños o grandes, explicando las emocionantes verdades de la Biblia.",
                "required": true
            },
            {
                "id": 86,
                "content": "Cuando escucho a otros enseñar, me frustran los estudios que tienen poco o ningún tipo de aplicación.",
                "required": true
            },
            {
                "id": 87,
                "content": "Tengo un gran deseo de compartir con los demás todas estas cosas que estoy aprendiendo y descubriendo.",
                "required": true
            },
            {
                "id": 88,
                "content": "La gente tiende a acudir a mí en busca de ideas prácticas, soluciones para opciones en conflicto o alternativas en las discusiones.",
                "required": true
            },
            {
                "id": 89,
                "content": "Encuentro satisfacción al asumir un papel de ayuda en algún proyecto digno.",
                "required": true
            },
            {
                "id": 90,
                "content": "Es gratificante para mí ver que mi hogar, mis muebles, mi comida y suministros del hogar son utilizados por aquellos fuera de mi familia inmediata.",
                "required": true
            },
            {
                "id": 91,
                "content": "Me siento motivado/a a ayudar cuando me enfrento a las necesidades financieras urgentes de los demás.",
                "required": true
            },
            {
                "id": 92,
                "content": "Si estoy en una reunión de grupo y no hay evidencia de un líder asignado, me gustaría nombrar o pedir a alguien en el grupo que lidere.",
                "required": true
            },
            {
                "id": 93,
                "content": "Es un desafío y satisfacción para mí guiar a un grupo de personas para que alcancen sus metas y objetivos deseados.",
                "required": true
            },
            {
                "id": 94,
                "content": "En general, tiendo a ayudar a satisfacer necesidades evidentes sin evaluar la valía de la persona necesitada o evaluar cuáles podrían ser las verdaderas necesidades.",
                "required": true
            },
            {
                "id": 95,
                "content": "Parece que dependo de los recursos y la guía de Dios mucho más que la mayoría de las personas.",
                "required": true
            },
            {
                "id": 96,
                "content": "A menudo puedo ver las acciones de los demás y conocer sus verdaderos motivos y actitudes internas.",
                "required": true
            },
            {
                "id": 97,
                "content": "Cuando una situación parece humanamente imposible, Dios me da fe y confianza para hablar o actuar de manera que ocurra un milagro y las personas sean ayudadas.",
                "required": true
            },
            {
                "id": 98,
                "content": "Me siento fuertemente atraído/a hacia aquellos que están enfermos con diferentes tipos de enfermedades y dolencias, y sé que Dios puede sanarlos a través de mi ministerio.",
                "required": true
            },
            {
                "id": 99,
                "content": "Aunque no puedo entender este idioma, sé que estoy diciendo las palabras de Dios y haciendo Su voluntad.",
                "required": true
            },
            {
                "id": 100,
                "content": "Aunque no conozco muchos idiomas, parece que entiendo lo que alguien está diciendo cuando hablan en un idioma que los demás no conocen.",
                "required": true
            },
            {
                "id": 101,
                "content": "Me preocupo mucho porla gente que nunca ha escuchado el evangelio y constantemente pienso en la posibilidad de ir personalmente a compartirlo con ellos.",
                "required": true
            },
            {
                "id": 102,
                "content": "Cuando me acerco a mis devociones personales, generalmente prefiero relacionarme emocionalmente con los versículos para recibir una bendición y aplicación personal.",
                "required": true
            },
            {
                "id": 103,
                "content": "Observo que las personas están abiertas y me escuchan cuando comparto sobre mi relación con Cristo.",
                "required": true
            },
            {
                "id": 104,
                "content": "Es importante para mí conocer, mantener contacto y ser conocido por aquellos cristianos de los que soy responsable.",
                "required": true
            },
            {
                "id": 105,
                "content": "Cuando necesito preparar una charla o lección para enseñar, me motiva organizar cuidadosamente las verdades bíblicas para que el oyente las entienda claramente.",
                "required": true
            },
            {
                "id": 106,
                "content": "Cuando doy un testimonio, tiendo a animar o consolar a otros, en lugar de simplemente compartir una experiencia o versículo de la Biblia.",
                "required": true
            },
            {
                "id": 107,
                "content": "Me gusta estudiar temas y pasajes de las Escrituras que son difíciles de entender.",
                "required": true
            },
            {
                "id": 108,
                "content": "Disfruto o disfrutaría servir en una junta directiva o de toma de decisiones, ya que me gusta enfrentar problemas y ver cómo se pueden resolver para honrar a Dios y ayudar en la obra de Dios.",
                "required": true
            },
            {
                "id": 109,
                "content": "Prefiero ayudar en segundo plano, en lugar de estar en primer plano.",
                "required": true
            },
            {
                "id": 110,
                "content": "La gente parece sentirse cómoda y tranquila en mi hogar, incluso aquellos que necesitan refugio y sanidad.",
                "required": true
            },
            {
                "id": 111,
                "content": "A menudo estoy dispuesto/a a sacrificar deseos personales para dar más generosamente a la obra de Dios.",
                "required": true
            },
            {
                "id": 112,
                "content": "En cuanto a la toma de decisiones, a menudo tomo decisiones con facilidad y con cierto grado de confianza.",
                "required": true
            },
            {
                "id": 113,
                "content": "Casi automáticamente asumo responsabilidad o liderazgo cuando no se han designado otros líderes.",
                "required": true
            },
            {
                "id": 114,
                "content": "Tengo un fuerte deseo de hacer actos de amor y bondad para aquellos que no pueden o no quieren devolvérmelos.",
                "required": true
            },
            {
                "id": 115,
                "content": "Me emociono y me siento cómodo/a cerca de aquellos que oran mucho por situaciones y necesidades de las personas.",
                "required": true
            },
            {
                "id": 116,
                "content": "Hay momentos en los que percibo que ciertas enseñanzas son contrarias a la Biblia o están desequilibradas.",
                "required": true
            },
            {
                "id": 117,
                "content": "Cuando se me pide que sirva en un ministerio o en un puesto de liderazgo, tiendo a involucrarme en situaciones que requieren una gran fe en Dios y la manifestación de milagros.",
                "required": true
            },
            {
                "id": 118,
                "content": "He visto a personas venir a Cristo y/o fortalecer su fe a través de la sanidad que Dios ha obrado en sus vidas a través de mi ministerio hacia ellos.",
                "required": true
            },
            {
                "id": 119,
                "content": "Cuando he hablado en un idioma desconocido en presencia de otros cristianos, se ha interpretado como palabras de aliento de Dios.",
                "required": true
            },
            {
                "id": 120,
                "content": "Creo que soy portavoz de Dios cuando interpreto un mensaje en un idioma desconocido dado por otra persona.",
                "required": true
            },
            {
                "id": 121,
                "content": "He recibido el estímulo de otras personas para considerar la posibilidad de ser misionero/a.",
                "required": true
            },
            {
                "id": 122,
                "content": "Si tuviera la opción de elegir pasajes bíblicos para estudiar, en su mayoría elegiría aquellos que son muy prácticos.",
                "required": true
            },
            {
                "id": 123,
                "content": "Me gusta compartir lo que Cristo ha hecho por mí, tanto con cristianos como con no cristianos.",
                "required": true
            },
            {
                "id": 124,
                "content": "Tengo un fuerte deseo de entregarme a cristianos que están luchando o se han desviado, para que sean animados y reintegrados al grupo.",
                "required": true
            },
            {
                "id": 125,
                "content": "Cuando leo la Biblia devocionalmente o para estudio, prefiero buscar verdades que amplíen mi comprensión de la verdad.",
                "required": true
            },
            {
                "id": 126,
                "content": "Cuando hablo con otro cristiano o en una situación grupal, tiendo a desafiarlos a tomar ciertas acciones para avanzar en su caminar cristiano.",
                "required": true
            },
            {
                "id": 127,
                "content": "Cuando me enfrento a tener que aconsejar a otra persona, básicamente evito discusiones sobre sentimientos y en su lugar prefiero compartir conocimientos y verdades bíblicas.",
                "required": true
            },
            {
                "id": 128,
                "content": "Mi reacción ante las necesidades de los demás tiende a ser deliberada en lugar de responder emocionalmente, porque quiero asegurarme de que se hayan considerado los problemas.",
                "required": true
            },
            {
                "id": 129,
                "content": "Estoy contento/a de hacer trabajos humildes o trabajos que otros podrían considerar poco importantes.",
                "required": true
            },
            {
                "id": 130,
                "content": "Estaría feliz de hospedar a alguien temporalmente en mi hogar para brindar una mano de ayuda o satisfacer una necesidad en la vida de alguien.",
                "required": true
            },
            {
                "id": 131,
                "content": "Mis convicciones y mi vida demuestran que todo lo que poseo realmente pertenece a Dios y debe ser utilizado para Su gloria.",
                "required": true
            },
            {
                "id": 132,
                "content": "Si se me pidiera que sirviera en una iglesia o programa de ministerio, tendería a elegir un puesto que involucre una planificación detallada y la toma de decisiones.",
                "required": true
            },

            {
                "id": 133,
                "content": "Tengo un fuerte deseo de motivar a otros hacia objetivos piadosos.",
                "required": true
            },
            {
                "id": 134,
                "content": "Me siento satisfecho/a y contento/a al satisfacer las necesidades de los que sufren o de los que no merecen.",
                "required": true
            },
            {
                "id": 135,
                "content": "Lo que parece imposible para otros, me parece posible porque Dios puede hacerlo.",
                "required": true
            },
            {
                "id": 136,
                "content": "Creo que puedo diferenciar entre la influencia demoníaca y la enfermedad mental.",
                "required": true
            },
            {
                "id": 137,
                "content": "Dios ha hecho que muchas personas vengan a Cristo que de otra manera aparentemente no lo harían, a través de los milagros que Dios ha hecho a través de mí.",
                "required": true
            },
            {
                "id": 138,
                "content": "Cuando leo los pasajes en la Biblia que describen la sanidad, puedo identificarme fuertemente con ellos, porque he visto que se realizan de manera similar en mi ministerio.",
                "required": true
            },
            {
                "id": 139,
                "content": "A veces solo quiero alabar y agradecer a Dios y me encuentro diciendo palabras que nunca he escuchado o aprendido antes.",
                "required": true
            },
            {
                "id": 140,
                "content": "Cuando alguien está hablando en un lenguaje dado por Dios, los escucho alabando a Dios y hablando de Sus grandes obras.",
                "required": true
            },
            {
                "id": 141,
                "content": "La gente me ha animado a ayudar a iniciar una nueva iglesia o ser un plantador de iglesias.",
                "required": true
            },
            {
                "id": 142,
                "content": "Cuando me llaman para servir, mi motivación más natural es ayudar en situaciones en las que existen necesidades espirituales específicas.",
                "required": true
            },
            {
                "id": 143,
                "content": "Los incrédulos entienden y parecen responder cuando explico quién es realmente Jesús.",
                "required": true
            },
            {
                "id": 144,
                "content": "Descubro que los cristianos con los que trabajo y a quienes ministro son alimentados por mi ministerio y aplicación de las verdades bíblicas a sus vidas.",
                "required": true
            },
            {
                "id": 145,
                "content": "Cuando doy testimonio, tiendo a compartir alguna verdad que ha cobrado vida para mí a través de la experiencia o versículos que he leído.",
                "required": true
            },
            {
                "id": 146,
                "content": "Cuando leo la Biblia por mí mismo, prefiero estudiar pasajes con el propósito de cambiar áreas específicas de conducta o actitudes incorrectas.",
                "required": true
            },
            {
                "id": 147,
                "content": "Si tengo que enseñar o hablar frente a otra persona o un grupo, me siento más cómodo presentando un estudio exhaustivo y detallado de un tema o pasaje de la Biblia.",
                "required": true
            },
            {
                "id": 148,
                "content": "Puedo ver fácilmente cómo se debe aplicar la verdad bíblica a mi vida y a los demás.",
                "required": true
            },
            {
                "id": 149,
                "content": "Tareas prácticas como escribir, limpiar, arreglar, recibir y otras tareas de apoyo son significativas e importantes para mí.",
                "required": true
            },
            {
                "id": 150,
                "content": "No me siento amenazado por extraños o personas nuevas. De hecho, siento la atracción de acercarme a ellos para ayudarles a sentirse bienvenidos.",
                "required": true
            },
            {
                "id": 151,
                "content": "En cuanto a asuntos financieros, me preocupo de que el dinero de Dios y el ministerio se utilicen de la manera más eficiente posible para lograr más para las personas.",
                "required": true
            },
            {
                "id": 152,
                "content": "Soy capaz de liderar una junta, grupo o comité en armonizar diversos puntos de vista para tomar decisiones juntos.",
                "required": true
            },
            {
                "id": 153,
                "content": "Por lo general, sé hacia dónde deberíamos dirigirnos como grupo y puedo motivar a otros en esa dirección también.",
                "required": true
            },
            {
                "id": 154,
                "content": "Realizo con alegría tareas que a otros les resultan desagradables para ministrar a las personas que sufren y están desesperadamente necesitadas.",
                "required": true
            },
            {
                "id": 155,
                "content": "Estoy convencido de que, a pesar de las circunstancias, Dios va a cumplir sus promesas, y mi vida lo demuestra.",
                "required": true
            },
            {
                "id": 156,
                "content": "Parece que puedo juzgar bien entre lo malo y lo bueno, lo aceptable y lo inaceptable.",
                "required": true
            },
            {
                "id": 157,
                "content": "Cuando Dios hace un milagro a través de mi ministerio, es tan obvio que la gente se maravilla de Dios y se asombra de su poder.",
                "required": true
            },
            {
                "id": 158,
                "content": "Me preocupo profundamente por aquellos que están enfermos de cualquier manera y me molesta que la iglesia no haga más para llevarles sanidad.",
                "required": true
            },
            {
                "id": 159,
                "content": "Cuando hablo en este nuevo idioma, me siento realizado y bendecido, como si Dios me hubiera hablado y hablara a través de mí.",
                "required": true
            },
            {
                "id": 160,
                "content": "Me da una gran alegría interpretar las palabras de otro cristiano que está hablando en lenguas.",
                "required": true
            },
            {
                "id": 161,
                "content": "La idea de viajar a nuevas áreas para vivir y trabajar me resulta atractiva y desafiante.",
                "required": true
            },
            {
                "id": 162,
                "content": "Cuando se me presenta la oportunidad de aconsejar a alguien, tiendo a darles la mejor solución bíblica que se me ocurre.",
                "required": true
            },
            {
                "id": 163,
                "content": "Me frustro cuando veo que la iglesia u otros cristianos no parecen preocuparse tanto como yo por las personas perdidas.",
                "required": true
            },
            {
                "id": 164,
                "content": "Las personas que se han alejado de Cristo o de la iglesia parecen responder bien a mi amor y preocupación, y a menudo regresan.",
                "required": true
            },
            {
                "id": 165,
                "content": "Parece que tengo el deseo y la habilidad de profundizar en la Biblia, buscar el significado de palabras y frases, ver cómo se ajustan a las grandes doctrinas de la fe y querer aplicarlas a situaciones de la vida diaria.",
                "required": true
            },
            {
                "id": 166,
                "content": "Mi reacción a las necesidades de los demás tiende a ser rápida porque generalmente puedo percibir lo que necesita hacerse y puedo decirles lo que necesitan hacer.",
                "required": true
            },
            {
                "id": 167,
                "content": "Cuando sirvo en una iglesia o grupo cristiano, me motiva servir en áreas donde hay necesidades que me desafían mentalmente.",
                "required": true
            },
            {
                "id": 168,
                "content": "Parece que puedo tomar decisiones correctas de manera consistente para mi vida y mi familia.",
                "required": true
            },
            {
                "id": 169,
                "content": "Sé que los líderes clave solo pueden hacer su trabajo si alguien se encarga de las responsabilidades de apoyo, y ese es mi lugar.",
                "required": true
            },
            {
                "id": 170,
                "content": "Sería un gozo para mí organizar almuerzos, cenas o cafés para los nuevos residentes de mi área o de mi iglesia.",
                "required": true
            },
            {
                "id": 171,
                "content": "Me alegra y con gusto doy para la obra de Dios y para su pueblo.",
                "required": true
            },
            {
                "id": 172,
                "content": "Disfruto y tengo la capacidad de reclutar cristianos para que utilicen sus talentos y dones en el ministerio.",
                "required": true
            },
            {
                "id": 173,
                "content": "Me doy cuenta de que a través de mi estilo de vida, acciones e ideas parezco motivar a otros cristianos a seguirme.",
                "required": true
            },
            {
                "id": 174,
                "content": "Disfruto siendo un agente de bendición para aquellos en hospitales y hogares de ancianos.",
                "required": true
            },
            {
                "id": 175,
                "content": "Cuando siento que Dios me ha guiado, tengo la inusual certeza de que se cumplirá, sin importar qué.",
                "required": true
            },
            {
                "id": 176,
                "content": "Tanto en entornos cristianos omo seculares, puedo detectar errores y enseñanzas falsas y relacionarlas con la verdad.",
                "required": true
            },
            {
                "id": 177,
                "content": "Encuentro una gran alegría al ver cómo los milagros de Dios obran a través de mí para que las personas tengan una fe mayor en Dios.",
                "required": true
            },
            {
                "id": 178,
                "content": "Dios utiliza mis oraciones y/o mi toque de sanidad para traer sanidad a otros.",
                "required": true
            },
            {
                "id": 179,
                "content": "Cuando hablo en este nuevo lenguaje, tengo una gran confianza y seguridad de que Dios está hablando a través de mí a los demás.",
                "required": true
            },
            {
                "id": 180,
                "content": "Dios me utiliza como intérprete de idiomas para que otros puedan ser ayudados.",
                "required": true
            },
            {
                "id": 181,
                "content": "Cuando Dios me guía para comenzar algo desde cero, puedo perseverar sin importar lo que pueda interferir.",
                "required": true
            },
            {
                "id": 182,
                "content": "Las personas me dicen que se sienten alentadas, consoladas o edificadas cuando hablo con ellas o cuando enseño/predico.",
                "required": true
            },
            {
                "id": 183,
                "content": "Algunos de los momentos más alegres y gratificantes de mi vida han sido guiar a otros a Cristo.",
                "required": true
            },
            {
                "id": 184,
                "content": "A menudo soy consciente de las necesidades de un grupo de cristianos con los que estoy y me gustaría dar orientación al grupo si se me permite.",
                "required": true
            },
            {
                "id": 185,
                "content": "Tengo la capacidad de explicar bien a los demás de qué se trata la Biblia y el cristianismo.",
                "required": true
            },
            {
                "id": 186,
                "content": "A menudo encuentro que animo a aquellos que son débiles, vacilantes, dudosos o que están pasando por dificultades.",
                "required": true
            },
            {
                "id": 187,
                "content": "Aprender información y verdades bíblicas me resulta fácil.",
                "required": true
            },
            {
                "id": 188,
                "content": "Los consejos que doy a grupos o individuos parecen funcionar bien.",
                "required": true
            },
            {
                "id": 189,
                "content": "Cuando se me llama a servir, me siento más cómodo y motivado para ayudar en situaciones de necesidades materiales específicas.",
                "required": true
            },
            {
                "id": 190,
                "content": "Muestro un amor genuino y un interés en cada invitado en mi hogar o grupo de ministerio.",
                "required": true
            },
            {
                "id": 191,
                "content": "Cuando pienso en cuánto amo a Dios, quiero darle todo el dinero y las cosas que puedo para Él y su obra.",
                "required": true
            },
            {
                "id": 192,
                "content": "Encuentro gran alegría y satisfacción al organizar un proyecto o ministerio para que otros sepan qué hacer y sean efectivos en hacerlo.",
                "required": true
            },
            {
                "id": 193,
                "content": "Incluso en grupos con diferentes niveles de madurez, parezco ser capaz de liderar con una respuesta positiva por parte del grupo.",
                "required": true
            },
            {
                "id": 194,
                "content": "Aunque las personas pueden traer problemas sobre sí mismas e incluso merecerlo justamente, Dios me utiliza para ayudarles y evitarles algunas de las consecuencias",
                "required": true
            },
            {
                "id": 195,
                "content": "Cuando planifico el futuro de mi iglesia o grupo cristiano, me preocupo más por ver los resultados finales que por ocuparme de los detalles para llegar allí.",
                "required": true
            },
            {
                "id": 196,
                "content": "Por lo general, puedo detectar a una persona falsa antes que los demás.",
                "required": true
            },
            {
                "id": 197,
                "content": "En situaciones en las que nadie sería convencido de que Dios es real, he visto cómo Él hace milagros a través de mí.",
                "required": true
            },
            {
                "id": 198,
                "content": "Otros cristianos me buscan para que ore por la sanidad.",
                "required": true
            },
            {
                "id": 199,
                "content": "He visto a personas llegar a la fe en Cristo al escuchar este lenguaje y su interpretación.",
                "required": true
            },
            {
                "id": 200,
                "content": "A veces, cuando interpreto lenguas, descubro que otros llegan a Cristo a través del mensaje que transmito.",
                "required": true
            },
        ],
        success: {
            congratulations: "Felicidades.",
            assessmentArrived: "¡Su Evaluación de Dones Espirituales Ha Llegado!",
            directing: "Redireccionando a su cuestionario..."
        },
        error: {
            TransactionFailed: "Transacción Fallida",
            tryagain1: "Por favor haz click ",
            here: "AQUÍ",
            tryagain2: " para volver a intentarlo",
            or: ' o ',
            contactus: "Contáctenos.",
        }
    }

}

export default spiritualAssessmentData;