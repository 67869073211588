import React from 'react';
import {Link} from 'react-router-dom';
import './link-hover-underline.css';

export class LinkHoverUnderline extends React.Component {
    render() {
        const {linkText, url, handleOnClick, fontSize, openInNewTab} = this.props;
        if (openInNewTab) {
            return (
                <div className={`link-container ${fontSize}`} onClick={handleOnClick}>
                    <a href={url} target="_blank" rel="noopener noreferrer" className="custom-link">
                        {linkText}
                    </a>
                </div>
            )
        } else {
            return (
                <div className={`link-container ${fontSize}`} onClick={handleOnClick}>
                    <Link to={url} className="custom-link">
                        {linkText}
                    </Link>
                </div>
            )
        }
    }
}
