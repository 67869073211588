const START_LOADING = () => (dispatch) => {
    dispatch({type: 'START_LOADING', payload: null});
};

const STOP_LOADING = () => (dispatch) => {
    dispatch({type: 'STOP_LOADING', payload: null});
};

const SET_LOGIN_STATE = () => (dispatch) => {
    dispatch({type: 'SET_LOGIN_STATE', payload: null});
};

const SET_LOGOUT_STATE = () => (dispatch) => {
    dispatch({type: 'SET_LOGOUT_STATE', payload: null});
};

export {START_LOADING, STOP_LOADING, SET_LOGIN_STATE, SET_LOGOUT_STATE};