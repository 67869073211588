import React from 'react';
import './logo-footer.css';

export class LogoFooter extends React.Component {
    render() {
        return (
            <div className="footerLogoText">
                ScriptureCast®
            </div>
        );
    }
}
