import React from 'react';
import {withRouter} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {START_LOADING, STOP_LOADING} from "../../redux/actions/loader.js"
import {connect} from 'react-redux';
import {BsArrowRight} from "react-icons/bs";
import {AccountService} from '../../services/account.service.js';
import searchService from '../../services/search.service.js';
import topicsService from '../../services/topics.service.js';
import {Spinner} from '../common/reusable/spinner/spinner.jsx';
import {emitter} from '../common/function/event-emitter/event-emitter.jsx';
import {remove_special_char,addParamsToURL} from '../../utils.js';
import './bible.css'
import Pagination from '../common/reusable/pagination-buttons/pagination-buttons.jsx';
import {replaceWithHighlight, recognizeVerseName, extract_verse_info} from '../../utils.js'


class SearchResult extends React.Component {
    constructor(props) {
        super(props);
        this.props.START_LOADING();
        const searchParams = new URLSearchParams(window.location.search);
        const query = decodeURIComponent(searchParams.get('query'));
        if (!query) {
            window.location.href = "404"
        } else {
            if (recognizeVerseName(query)) {
                const extract_result = extract_verse_info(query);
                if (extract_result) {
                    window.location.href = `/chapter-overview/${encodeURI(extract_result["doc_chapter_info"])}#${encodeURI(extract_result["html_doc_id"])}`;
                } else {
                    window.location.href = `/chapter-overview/${encodeURI(query)}`
                }
            }
        }
        const search_by = searchParams.get('search_by') || "";
        const current_page = searchParams.get('page') || 1;
        this.state = {
            isLoading: true,
            search_text: query,
            total_pages: 0,
            current_page: current_page,
            paginated_result: [],
            number_of_result: 0,
            number_of_result_dict: {},
            result_dict: {},
            result_length_dict: {},
            keyword: query,
            search_by: search_by,
            item_per_page : 10,
            result_loading: false,
        }
        this.setCurrentPage = this.setCurrentPage.bind(this);
    }

    async componentDidMount() {
        const isLogin = AccountService.userValue ? true : false;
        const search_by = this.state.search_by
        let user_id = ""
        if (isLogin) {
            const user = AccountService.userValue.data;
            user_id = user.id.toString()
        }
      
        this.getInitalResult(search_by)
        
        this.setState({
            user_id,  
            isLoading: false,
        })
        this.props.STOP_LOADING();
    }

    async getResultByVerse() {    
        const isLogin = AccountService.userValue ? true : false;
        let user_id = ""
        var verse_content = {}
        if (isLogin) {
            const user = AccountService.userValue.data;
            user_id = user.id.toString()
        }
        const res = await searchService.getTopicBySearchingInVerse(user_id, this.state.search_text)
        if (res && res.data !== 'failed') {
            if (user_id) {
                emitter.emit('updateSearchHistory', user_id);
            }
            verse_content = res.data   
           this.setState({
             verse_content: verse_content,
           })    
        }
        return verse_content;
    }

    createPagination(originalObject,search_by="") {    
            const item_per_page = this.state.item_per_page
            const keys = Object.keys(originalObject);     
            var number_of_result = keys.length   || 0
            var paginatedArrays = [];
            for (let i = 0; i < keys.length; i += item_per_page) {
                const sliceKeys = keys.slice(i, i + item_per_page);
                const subArray = sliceKeys.map(key => originalObject[key]);
                paginatedArrays.push(subArray);
            }

            if (search_by === 'verse') {
                const transformedPaginatedArrays = [];
                for (const entry of paginatedArrays) {
                    const transformedObject = entry.reduce((result, item) => {
                        const {parent_topic, id, topic} = item;

                        if (!result[parent_topic]) {
                            result[parent_topic] = [];
                        }
                        result[parent_topic].push({id, topic});
                        return result;
                    }, {});
                    transformedPaginatedArrays.push(transformedObject)
                }
                paginatedArrays = transformedPaginatedArrays
            }     
            this.setState({
                total_pages: paginatedArrays.length,
                paginated_result: paginatedArrays,
                number_of_result : number_of_result
            })
        }

    handleClickOnVerse(topic_id_number) {
        const topic_id = topic_id_number.toString()
        this.updateSearchHistory(topic_id);
        this.moveToVerse(topic_id)
    }

    async moveToVerse(topic_id) {
        const topic_res = await topicsService.getTopicById(topic_id);
        const topic_detail = topic_res.data.data[0];
        const subject = remove_special_char(topic_detail.subject)
        const category = remove_special_char(topic_detail.category)
        const topic = remove_special_char(topic_detail.topic)
        const {subject_id, category_id} = topic_detail
        const url = `/bible/${subject}-${subject_id}/${category}-${category_id}/${topic}-${topic_id}?search_text=${this.state.keyword}`
        this.props.history.push(url);
    }

    moveToLink(item) {
        var subject = ''
        var category = ''
        var topic=''
        if (item.subject_id && item.subject_name) {
            subject = `/${remove_special_char(item.subject_name)}-${item.subject_id}`
        }
        if (item.category_id && item.category_name) {
            category = `/${remove_special_char(item.category_name)}-${item.category_id}`
        }
        if (item.topic_id && item.topic_name) {
            topic = `/${remove_special_char(item.topic_name)}-${item.topic_id}`
        }
       
        const url = `/bible${subject}${category}${topic}?search_text=${this.state.keyword}`
        this.props.history.push(url);
    }

    async updateSearchHistory(topic_id) {
        const {user_id, search_text} = this.state
        if (user_id) {
            await searchService.updateSearchHistory(user_id, search_text, topic_id);
            emitter.emit('updateSearchHistory', this.state.user_id);
        }

    }

    setCurrentPage(page) {
        const param = {
            page: page
        }
        addParamsToURL(this,param)
        this.setState({current_page: page})
    }

    async changeSearchBy(search_by,page=1) {    
        this.setState({search_by, current_page : page ,paginated_result: {}, result_loading : true})
         let result ;
        if (search_by === "verse") {
            result = this.state.result_dict[search_by] ?? {};
        } else {
            result = this.state.result_dict[search_by] ?? [];
        }
        this.createPagination(result,search_by);
        const param = {
            search_by : search_by,
            page: page,
        }    
        this.setState({result_loading: false})
        addParamsToURL(this,param)   
    }

    async getInitalResult(search_by) {
        this.setState({paginated_result: {}, result_loading: true}) 
        const result_dict = {
            verse: await this.getResultByVerse(),
            category: await this.getResultByCategory(),
            subject: await this.getResultBySubject(),
            topic: await this.getResultByTopic()
        }
        const result_length_dict = {}
        for (let key in result_dict) {
            if (key === "verse") {
                result_length_dict[key] = Object.keys(result_dict[key]).length;
            } else {
                result_length_dict[key] = result_dict[key].length;
            }
        }
        if (search_by) {
            this.createPagination(result_dict[search_by],search_by);
        } else {
            for (let key in result_length_dict) {
                if (result_length_dict[key] > 0) {
                    search_by = key;
                    break;
                }
            }
            if (!search_by) {
                search_by = "verse";
            }
            this.setState({"search_by": search_by});
            this.createPagination(result_dict[search_by],search_by);
        }
        this.setState({result_dict: result_dict})
        this.setState({result_length_dict: result_length_dict})
        this.setState({result_loading: false})
    }

   

     async getResultBySubject() {
        const search_result = await searchService.getSearchResultSubject(this.state.search_text);
        this.setState({subject_content : search_result.data})
        return search_result.data
    }

     async getResultByCategory() {
        const search_result = await searchService.getSearchResultCategory(this.state.search_text);
        this.setState({category_content : search_result.data})
        return search_result.data
    }

     async getResultByTopic() {
        const search_result = await searchService.getSearchResultTopic(this.state.search_text);
        this.setState({topic_content : search_result.data})
        return search_result.data
    }
    render() {
        const {
            search_text,
            isLoading,
            paginated_result,
            current_page,
            total_pages,
            number_of_result,
            result_length_dict,
            search_by,
            result_loading
        } = this.state
        const search_by_list = ["Verse","Topic","Category","Subject"]
        return (
            <>
                {isLoading ? <Spinner/> :
                    <div className='search-result-page'>
                        <Helmet>
                            <title> Search Result | ScriptureCast®</title>
                        </Helmet>
                        <div className='search-result-content bible-content'>
                            <div className='left-section'>
                                <div className='left-item'>
                                    <div>Keyword: <strong>{search_text.toUpperCase()}</strong></div>  
                                    <div className="sc-title">{search_by.toUpperCase()}</div>
                                    <div className="sc-content">Please select to view <BsArrowRight/></div>        
                                </div>
                            </div>
                            <div className='right-section'>
                             <div className='tab-group'>
                                        {search_by_list.map((item,index)=> (
                                            <div key={index} 
                                            onClick={()=>this.changeSearchBy(item.toLowerCase())} 
                                            className={(search_by === item.toLowerCase())?"selected":""}>{item} ({result_length_dict[item.toLowerCase()]??".."})</div>
                                            ))
                                        }
                                    </div>
                                <div>{number_of_result} Result(s)</div>
                                <Pagination total_pages={total_pages} current_page={current_page}
                                            setCurrentPage={this.setCurrentPage}/>
                                <div className="bible-link-menu">
                                   {result_loading? <div>Loading ... </div> : 
                                   <>
                                     {Object.keys(paginated_result).length >0 ?                      
                                        ( 
                                            <div className='scripture-link-box'>
                                            {
                                            (search_by === "verse")? 
                                            <>
                                                  {Object.entries(paginated_result[current_page - 1]).map(([key, topics]) => (
                                                    <div key={key}>
                                                        <div className='font-size-large'>{key}</div>
                                                        <div className='normal-list'>
                                                            {topics.map(({id, topic}) => {
                                                                    return (
                                                                        <div
                                                                            className='scripture-link'
                                                                            key={id}
                                                                            onClick={() => this.handleClickOnVerse(id)}
                                                                        >{replaceWithHighlight(topic, search_text)}</div>
                                                                    );
                                                                }
                                                            )}
                                                        </div>
                                                    </div>
                                                ))}
                                            </>
                                            : 
                                            <div> {
                                                Array.from(paginated_result[current_page - 1]).map((item,index)=>(
                                                <div key={index}   className='scripture-link' onClick={()=>this.moveToLink(item)}>
                                                {replaceWithHighlight(item[`${search_by}_name`], search_text)}
                                                </div>
                                                ))
                                            }
                                            </div>}
                                            </div>                                  
                                        ):
                                        (
                                            <p>No search results found.</p>
                                        ) }
                                   
                                   </>}
                                   
                                </div>

                            </div>
                        </div>
                    </div>
                }</>
        );
    }
}

export default withRouter(
    connect(null, {START_LOADING, STOP_LOADING})(SearchResult)
);
