import React from 'react';
import {withRouter} from 'react-router-dom';
import './reset-password-page.css';
import {Helmet} from 'react-helmet';
import {connect} from 'react-redux';
import {START_LOADING, STOP_LOADING, SET_LOGIN_STATE} from '../../../redux/actions/loader';
import {Link} from 'react-router-dom';
import {Formik, Field, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import {AccountService} from '../../../services/account.service';
import queryString from 'query-string';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';


const TokenStatus = {
    Validating: 'Validating',
    Valid: 'Valid',
    Invalid: 'Invalid',
};

const PasswordMode = {
    ChangePassword: 'ChangePassword',
    ResetPassword: 'ResetPassword'
};

class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.props.START_LOADING();
        this.state = {
            token: null,
            tokenStatus: TokenStatus.Validating,
            passwordMode: PasswordMode.ResetPassword,
            showNewPassword: false,
            showConfirmPassword: false
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.handleShowNewPasswordToggle = this.handleShowNewPasswordToggle.bind(this)
        this.handleShowConfirmPasswordToggle = this.handleShowConfirmPasswordToggle.bind(this)
    }

    handleShowNewPasswordToggle(event) {
        this.setState((prevState) => ({
            showNewPassword: !prevState.showNewPassword,
        }));
    }

    handleShowConfirmPasswordToggle(event) {
        this.setState((prevState) => ({
            showConfirmPassword: !prevState.showConfirmPassword,
        }));
    }

    componentDidMount() {
        if (this.props.location?.pathname === '/account/change-password') {
            this.setState({
                passwordMode: PasswordMode.ChangePassword,
            });
        } else {
            this.setState({
                passwordMode: PasswordMode.ResetPassword,
            });
        }

        if (this.state.passwordMode === PasswordMode.ResetPassword) {
            // Get Reset Token from querry param in URL
            const {token} = queryString.parse(this.props?.location?.search);
            AccountService.validateResetToken(token)
                .then(() => {
                    this.setState({
                        token: token,
                        tokenStatus: TokenStatus.Valid,
                    })
                })
                .catch((err) => {
                    console.log(err.toString())
                    this.setState({tokenStatus: TokenStatus.Invalid})
                });
        }
        this.props.STOP_LOADING();
    }

    handleCancel() {
        this.props.history.goBack();
    }

    onSubmit({password, confirmPassword}, {setSubmitting}) {
        this.props.START_LOADING();
        if (this.state.passwordMode === PasswordMode.ChangePassword) {
            AccountService.changePassword(password, confirmPassword)
                .then(() => {
                    this.props.SET_LOGIN_STATE();
                    this.props.history.goBack()
                    this.props.STOP_LOADING();
                    setSubmitting(false);
                })
                .catch((error) => {
                    setSubmitting(false);
                    this.props.STOP_LOADING();
                });
        } else {
            AccountService.resetPassword(this.state.token, password, confirmPassword)
                .then((res) => {
                    if (res.status === 200) {
                        this.props.STOP_LOADING();
                        this.props.SET_LOGIN_STATE();
                        this.props.history.push({
                            pathname: `/`,
                            state: {},
                        });
                    }
                })
                .catch((error) => {
                    setSubmitting(false);
                    this.props.STOP_LOADING();
                });
        }
    }

    getButtonsGroup(isSubmitting) {
        if (this.state.passwordMode === PasswordMode.ChangePassword) {
            return (
                <div className="reset-password-page">
                    <div className="flex gap-4 align-center">
                        <div
                            type="submit"
                            disabled={isSubmitting}
                            className="button button-style1"
                        >
                            {isSubmitting && (
                                <span className="spinner-border spinner-border-sm mr-1"></span>
                            )}
                            Change Password
                        </div>
                        <div onClick={() => this.handleCancel()} className="link-style1">
                            Cancel
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="form-row">
                    <div className="flex gap-4 align-center">
                        <div
                            type="submit"
                            disabled={isSubmitting}
                            className="button button-style1"
                        >
                            {isSubmitting && (
                                <span className="spinner-border spinner-border-sm mr-1"></span>
                            )}
                            Reset Password
                        </div>
                        <Link to="login" className="link-style1">
                            Login
                        </Link>
                    </div>
                </div>
            );
        }
    }

    getForm() {
        const initialValues = {
            password: '',
            confirmPassword: '',
        };

        const validationSchema = Yup.object().shape({
            password: Yup.string()
                .min(6, 'Password must be at least 6 characters')
                .required('Password is required'),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
                .required('Confirm Password is required'),
        });

        return (
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={this.onSubmit}
            >
                {({errors, touched, isSubmitting}) => (
                    <Form>
                        <div className="form-group">
                            <label className="pw-label3">Password</label>
                            <button type="button" className="show-password-icon3"
                                    onClick={this.handleShowNewPasswordToggle}>
                                <FontAwesomeIcon icon={this.state.showNewPassword ? faEyeSlash : faEye}/>
                            </button>
                            <Field
                                name="password"
                                type={this.state.showNewPassword ? 'text' : 'password'}
                                className={
                                    'form-control' +
                                    (errors.password && touched.password ? ' is-invalid' : '')
                                }
                            />
                            <ErrorMessage
                                name="password"
                                component="div"
                                className="error-yellow"
                            />

                        </div>
                        <div className="form-group">
                            <label className="pw-label3">Confirm Password</label>
                            <button type="button" className="show-password-icon3"
                                    onClick={this.handleShowConfirmPasswordToggle}>
                                <FontAwesomeIcon icon={this.state.showConfirmPassword ? faEyeSlash : faEye}/>
                            </button>
                            <Field
                                name="confirmPassword"
                                type={this.state.showConfirmPassword ? 'text' : 'password'}
                                className={
                                    'form-control' +
                                    (errors.confirmPassword && touched.confirmPassword
                                        ? ' is-invalid'
                                        : '')
                                }
                            />
                            <ErrorMessage
                                name="confirmPassword"
                                component="div"
                                className="error-yellow"
                            />

                        </div>
                        <>{this.getButtonsGroup(isSubmitting)}</>
                    </Form>
                )}
            </Formik>
        );
    }

    getCardHeader() {
        if (this.state.passwordMode === PasswordMode.ResetPassword) {
            return 'Reset Password';
        } else {
            return 'Change Password';
        }
    }

    getBody() {
        if (this.state.passwordMode === PasswordMode.ResetPassword) {
            // eslint-disable-next-line default-case
            switch (this.state.tokenStatus) {
                case TokenStatus.Valid:
                    return this.getForm();
                case TokenStatus.Invalid:
                    return (
                        <div>
                            Token validation failed, if the token has expired you can get a
                            new one at the <Link to="forgot-password">forgot password</Link>{' '}
                            page.
                        </div>
                    );
                case TokenStatus.Validating:
                    return <div>Validating token...</div>;
            }
        } else {
            return this.getForm();
        }
    }

    render() {
        const {passwordMode} = this.state;
        return (
            <>
                <Helmet>
                    {passwordMode === PasswordMode.ChangePassword && (
                        <title> Change Password | ScriptureCast</title>
                    )}

                    {passwordMode === PasswordMode.ResetPassword && (
                        <title> Reset Password | ScriptureCast</title>
                    )}
                </Helmet>

                <div className="box-style1">
                    <div>
                        <h3 className="card-header">{this.getCardHeader()}</h3>
                        <div className="card-body">{this.getBody()}</div>
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(
    connect(null, {START_LOADING, STOP_LOADING, SET_LOGIN_STATE})(ResetPassword)
);
