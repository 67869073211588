import axios from 'axios';
import {API_URL} from '../config/config';


class TextToSpeech {
    async makespeech(text, name) {
        const res = await axios.post(`${API_URL}/voices/getAudioEncodedString`,
            {
                input_text: text,
                voice_name: name,
            },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        const content = res.data.audioContent;
        return content;
    }

    make_chapter_speech(chapter_id, voice_name) {
        const file_name = `${chapter_id}_${voice_name}.mp3`
        return `https://scripturecast-website.s3.us-west-1.amazonaws.com/PublicMedia/bible_voice_mp3/${file_name}`;
    }
}


const textToSpeechInstance = new TextToSpeech();
export default textToSpeechInstance;
