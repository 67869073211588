import React from 'react';
import {withRouter} from 'react-router-dom';
import {LinkHoverUnderline} from '../../common/reusable/link-hover-underline/link-hover-underline';
import {connect} from 'react-redux';
import {START_LOADING, STOP_LOADING} from "../../../redux/actions/loader";

class HowToUse extends React.Component {

    render() {
        return (
                <div className='how-to-use-basic-content'>
                    <div className='how-to-use-basic-content bullet-list'>
                        <LinkHoverUnderline fontSize='font-size-large' url="/about/how-to-use-basic"
                                            linkText="ScriptureCast Fast Start"/>
                        <LinkHoverUnderline fontSize='font-size-large' url="/about/how-to-use-advanced"
                                            linkText="ScriptureCast Advanced Features"/>
                        <LinkHoverUnderline fontSize='font-size-large' url="/about/how-to-use-bookmarks"
                                            linkText="How to organize bookmarks in custom Folders"/>
                    </div>
                </div>
        );
    }
}


export default withRouter(connect(null, {START_LOADING, STOP_LOADING})(HowToUse));
