import React from 'react';
import '../link-hover-underline/link-hover-underline.css'

export class TextbuttonHoverUnderline extends React.Component {
    render() {
        const {text, fontSize} = this.props;
        return (
            <div className={`link-container ${fontSize}`} {... this.props}>
                <div className="custom-link">{text}</div>
            </div>
        );
    }
}
