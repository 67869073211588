import React from 'react';
import './form-label.css';

class CustomFormLabel extends React.Component {
    render() {
        const {isRequired, name} = this.props;

        return (
            <label className="field-label">
                {name}
                {isRequired && <span className="field-required">*</span>}
            </label>
        );
    }
}

export default CustomFormLabel;