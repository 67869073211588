import React from 'react';
import {withRouter} from 'react-router-dom';
import SubjectsService from '../../services/subjects.service';
import {__debug, remove_special_char} from '../../utils.js';
import {Helmet} from 'react-helmet';
import {START_LOADING, STOP_LOADING} from "../../redux/actions/loader"
import {connect} from 'react-redux';
import {BsArrowRight} from "react-icons/bs";
import {replaceWithHighlight, formatLink, addParamsToURL} from '../../utils.js'


class SubjectList extends React.Component {
    constructor(props) {
        super(props);
        this.props.START_LOADING();
        this.retrieveSubjects = this.retrieveSubjects.bind(this);
        const searchParams = new URLSearchParams(window.location.search)
        let previous_subject = formatLink(searchParams.get('subject') || '');
        const keyword = searchParams.get('search_text')?.toUpperCase() || ''
        this.state = {
            subjectList: [],
            keyword,
            previous_subject
        };
    }

    async componentDidMount() {
        await this.retrieveSubjects();
        this.props.STOP_LOADING();
    }

    async retrieveSubjects() {
        await SubjectsService.getAllSubject()
            .then((response) => {
                this.setState({
                    subjectList: response.data?.data,
                });
                __debug(response.data?.data, 'p');
                if(this.state.previous_subject) {
                    setTimeout(()=>this.scrollToSubject(this.state.previous_subject),500)
                }            
            })
            .catch((err) => {
                __debug(err, 'e');
            });
    }


    onClick = (index, currentSubject) => {
        addParamsToURL(this,{subject : currentSubject})
        this.props.history.push({
            pathname: `/bible/${remove_special_char(currentSubject)}-${index}`,
            state: {
                index: index,
                currentSubject: currentSubject,
            },
        });
    };

     scrollToSubject = (subject) => {
        const targetElement = document.getElementById(`link-${subject}`)
        if (targetElement) {
            targetElement.classList.add('clicked')
            targetElement.scrollIntoView({
                behavior: 'instant',
                block: 'center',
                inline: 'center'
            });
        } else {
            console.log(`Element with subject-category='${subject}' not found`);
        }
    }


    render() {
        const {subjectList,keyword} = this.state;

        return (
            <div className="subject-list-page">
                <Helmet>
                    <title>List of Subjects | ScriptureCast®</title>
                </Helmet>
                <div className='subject-list-content bible-content'>
                    <div className='left-section'>
                        <div className='left-item'>
                             {keyword && <div>Keyword: <strong>{keyword}</strong></div>} 
                            <div className="sc-title">Subject</div>
                            <div className="sc-content">Click on a subject to view related categories <BsArrowRight/>
                            </div>
                        </div>
                    </div>
                    <div className='right-section'>
                        <div className="bible-link-menu" id='subject-list'>
                            {subjectList && subjectList.map((item, index) => (
                                <div
                                    key={item['id']}
                                    id={`link-${formatLink(item['subject'])}`}
                                    className='scripture-link'
                                    onClick={() => {
                                        this.onClick(item['id'], item['subject']);
                                    }}
                                >{keyword? replaceWithHighlight(item['subject'],keyword): item['subject']}   
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(connect(null, {START_LOADING, STOP_LOADING})(SubjectList));
