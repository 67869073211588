import React from 'react';
import './copyright.css';


export default class Copyright extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentYear: new Date().getFullYear(),
            organization: null
        };
    }


    render() {
        const {
            organization
        } = this.props
        let content
        let copyrightYears
        if (organization === "Biblica") {
            content = "All Rights Reserved Worldwide. 300 General Palmer Dr #4, Palmer Lake, CO 80133"
            copyrightYears = "2011"
        } else if (organization === "ScriptureCast®") {
            content = "All Rights Reserved"
            copyrightYears = "2023"
        }
        let copy_right_year_text = "-" + this.state.currentYear
        return (
            <>
                <div
                    className="copyright">© {copyrightYears}{!(copyrightYears === this.state.currentYear.toString()) && copy_right_year_text} {organization}. {content}</div>
            </>
        )
    }
}

