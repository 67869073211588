import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';
import store from './redux/store/store';
import {Router} from 'react-router-dom';
import {history} from './history';
import {AccountService} from './services/account.service';
import ReactGA from "react-ga4";
import TagManager from 'react-gtm-module';
import { hotjar } from 'react-hotjar';


// Attempt silent token refresh before startup
AccountService.refreshToken().finally(startApp);

function startApp() {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    TagManager.initialize({gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID});
    hotjar.initialize({id: process.env.REACT_APP_HOTJAR_ID, sv:process.env.REACT_APP_HOTJAR_SV, debug: false});
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
        <Provider store={store}>
            <Router history={history}>
                <App/>
            </Router>
        </Provider>
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
