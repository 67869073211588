import React from 'react';
import {withRouter} from 'react-router-dom';
import CategoryService from '../../services/categories.service.js';
import {remove_special_char, __debug} from '../../utils.js';
import {Helmet} from 'react-helmet';
import {START_LOADING, STOP_LOADING} from "../../redux/actions/loader.js"
import {connect} from 'react-redux';
import './bible.css'
import {replaceWithHighlightIncluded, formatLink, addParamsToURL} from '../../utils.js';

class CategoryList extends React.Component {
    constructor(props) {
        super(props);
        this.props.START_LOADING();
        const searchParams = new URLSearchParams(window.location.search);
        let previous_category = formatLink(searchParams.get('category') || '');
        this.retrieveCategoryList = this.retrieveCategoryList.bind(this);
        this.state = {
            categoryList : {},
            categoryData : {},
            keyword : '',
            previous_category
        };
    }

    async componentDidMount() {
        await this.retrieveCategoryList();
        this.props.STOP_LOADING();
    }

    async retrieveCategoryList() {
        await CategoryService.getAllCategories()
            .then((response) => {
                let categoryList = {}
                const categoryList_data = Array.from(response.data?.data)
                categoryList_data.forEach(item => {
                    const current_category = item.category.trim()
                    if (!Object.keys(categoryList).includes(current_category)) {
                        categoryList[current_category] = [item]
                    } else {
                        categoryList[current_category].push(item)
                    }
                })
                this.setState({
                    categoryList,
                    categoryData : categoryList
                });
            })
            .then (() => {
                if (this.state.previous_category) {
                    setTimeout(()=>this.scrollToCategory(this.state.previous_category),500)
                }
            })
            .catch((err) => {
                __debug(err, 'e');
            });
    }

    onClick = async (category) => {
        this.props.history.push({
            pathname: `/bible/${remove_special_char(category['subject'])}-${category['subject_id']}/${remove_special_char(category['category'])}-${category['id']}`,
            state: {},
        });
    };

    openPopup = (category) => {
        const category_links = document.querySelectorAll(".category-link")
        category_links.forEach(item => item.classList.remove('clicked'))
        addParamsToURL(this,{category})
        const popup = document.getElementById(category)
        const link = document.getElementById(`link-${formatLink(category)}`)
        link.classList.add('clicked')
        popup.classList.remove('d-none')
        const backdrops = document.querySelectorAll(".bible-menu-backdrop")
        backdrops.forEach(item => item.classList.remove('d-none'))
        const parentDiv = document.getElementById('category-list');
        this.scrollToShowPopup(parentDiv,popup)
    }

    scrollToShowPopup = (parentDiv, targetElement) => {
        if (targetElement&&parentDiv) {
                const parentRect = parentDiv.getBoundingClientRect();
                const targetRect = targetElement.getBoundingClientRect();

                const targetBottom = targetRect.bottom;
                const parentBottom = parentRect.bottom;

                if (targetBottom > parentBottom) {
                    const scrollAmount = targetBottom - parentBottom;
                    parentDiv.scrollTop += scrollAmount;
            }
        }
    }

    closePopUp = () => {
        const popups = document.querySelectorAll(".subject-div")
        popups.forEach(item => item.classList.add('d-none'))
        const category_links = document.querySelectorAll(".category-link")
        category_links.forEach(item => item.classList.remove('clicked'))
        const backdrops = document.querySelectorAll(".bible-menu-backdrop")
        backdrops.forEach(item => item.classList.add('d-none'))
    }

    changeKeyword = (e) => {
        const keyword = e.target.value
        this.setState({keyword})
        let categoryList = this.filter(keyword)
        this.setState({categoryList})
    }

    filter = (keyword) => {
        const categories = this.state.categoryData
        return Object.keys(categories).reduce((filtered, category) => {
            const filteredItems = categories[category].filter(item =>
                category.toLowerCase().includes(keyword.toLowerCase())
            );

            if (filteredItems.length > 0 || category.toLowerCase().includes(keyword.toLowerCase())) {
                filtered[category] = filteredItems;
            }

            return  filtered;
        }, {});
    }

    scrollToCategory = (category) => {
        const targetElement = document.getElementById(`link-${category}`)
        if (targetElement) {
            targetElement.classList.add('clicked')
            targetElement.scrollIntoView({
                behavior: 'instant',
                block: 'center',
                inline: 'center'
            });
        } else {
            console.log(`Element with data-category='${category}' not found`);
        }
    }

    render() {
        const {categoryList,keyword} = this.state;

        return (
            <div className="category-list-page">
                <Helmet>
                    <title>Table of Categories | ScriptureCast®</title>
                </Helmet>

                <div className='category-list-content bible-content'>
                    <div className='left-section'>
                        <div className='left-item'>
                            <div className="sc-title">Table of Categories</div>
                            <div className="sc-content">Scroll to View</div>
                            <input type='text' value={keyword} placeholder='Search' id='category-search' onChange={(e)=>this.changeKeyword(e)} className='mt-3'></input>
                        </div>
                    </div>
                    <div className='right-section'>
                        <div id='category-list' className="bible-link-menu">
                            {categoryList &&
                                Object.keys(categoryList).map((category, index) => (
                                    <div className='category-div' data-category={category} key={index}>
                                        <div className='bible-menu-backdrop d-none' onClick={()=>this.closePopUp()}></div>
                                        <div className="scripture-link category-link" id={`link-${formatLink(category)}`} onClick={() => this.openPopup(category)}>{replaceWithHighlightIncluded(category, keyword)}</div>
                                        <div className='d-none subject-div' id={category}>
                                            <div onClick={()=>this.closePopUp()} className='float-right btn-close'>&times;</div>
                                            <div className='mb-3'>Please choose a subject:</div>             
                                        {Array.from(categoryList[category]).map((item,index2) => (
                                                <div
                                                    key={item['id']}
                                                    className="link-style1 mb-2"
                                                    onClick={() => {
                                                        this.onClick(item);
                                                    }}
                                                >
                                                   &bull; {replaceWithHighlightIncluded(item['subject'], keyword)}
                                                </div>
                                        ))}
                                        
                                        </div>
                                    </div>   
                                ))}  
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default withRouter(connect(null, {START_LOADING, STOP_LOADING})(CategoryList));