import React from 'react';
import {withRouter, Link} from 'react-router-dom';
import './donation-error.css';
import {connect} from 'react-redux';
import {START_LOADING, STOP_LOADING} from '../../../redux/actions/loader';
import {BsXCircle} from "react-icons/bs";

class DonationError extends React.Component {
    constructor(props) {
        super(props);
        this.props.START_LOADING();
    }

    componentDidMount() {
        this.props.STOP_LOADING();
    }

    render() {
        return (
            <div className="donation-error-page">
                <p className="confirm-icon"><BsXCircle/></p>
                <h1>Transaction Failed</h1>
                <p className="try-again">Please try again at the <Link to='/donate'>Donation Page </Link>
                    or <Link to="/about/contact">contact us.</Link></p>

            </div>
        );
    }
}

export default withRouter(
    connect(null, {START_LOADING, STOP_LOADING})(DonationError)
);
