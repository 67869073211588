import React from 'react';
import {withRouter} from 'react-router-dom';
import './chapter-overview-of-verse.css';
import './scripture-content.css';
import TopicService from '../../../services/topics.service';
import ChapterService from '../../../services/chapters.service';
import {Helmet} from 'react-helmet';
import {START_LOADING, STOP_LOADING} from "../../../redux/actions/loader"
import {connect} from 'react-redux';
import TextToSpeech from '../../../services/text-to-speech';
import { emitter } from '../../common/function/event-emitter/event-emitter';
import DOMPurify from 'dompurify';


class ChapterOverview extends React.Component {
    constructor(props) {
        super(props);
        this.props.START_LOADING();
        let all_chapters = this.props.allChapters || {},
            all_docs = [],
            selected_chapter=1, 
            selected_doc = '', 
            verseIDString = null; 
        if (Object.values(all_chapters).length>0) {
            all_docs = Object.keys(all_chapters)
        }
        if (this.props.match && this.props.match.params) {
            verseIDString = decodeURI(this.props.match.params.verseId);
            const hashIndex = verseIDString.indexOf('#');
            if (hashIndex !== -1) {
                verseIDString = verseIDString.substring(0, hashIndex);
            }
            selected_doc = verseIDString.split(' ')[0]
        }
        this.state = {
            verseInfos: null,
            audioUrl: null,
            auDisplay: false,
            voice: sessionStorage.getItem('currentVoiceName') || 'en-US-Standard-B',
            tooltip: false,
            all_chapters, all_docs, selected_doc,selected_chapter,  
            verseIDString 
        };
        this.handleShareClick = this.handleShareClick.bind(this);
        this.copyToClipboard = this.copyToClipboard.bind(this);
        this.scrollToVerse = this.scrollToVerse.bind(this);
    }

    async componentDidMount() {
        emitter.on('voiceChange', voice_name => {
            this.reloadAudio(this.state.chapter_id, voice_name)
        });
        await this.getData(this.state.verseIDString)
        this.props.STOP_LOADING();
        document.addEventListener('click', this.tooltipHandler);
        this.handleHashChange();
        window.addEventListener('hashchange', this.handleHashChange);
    }

    componentWillUnmount() {
        window.removeEventListener('hashchange', this.handleHashChange);
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.props.location !== prevProps.location) {
            let verseIDString
          if (this.props.match && this.props.match.params) {
                verseIDString = decodeURI(this.props.match.params.verseId);
            }
            await this.getData(verseIDString)
        }
        if ((this.state.all_chapters !== prevState.all_chapters) || (this.state.chapter_number !== prevState.chapter_number)) {
            this.handleHashChange();
        }
    }

    async getData(verseIDString) {
        let verseInfos = null;
        let is_valid = false;
        if (verseIDString) {
            let all_chapters = this.state.all_chapters
            if (!Object.values(all_chapters).length>0) {
                const all_chapters = await ChapterService.getAllChapters()
                const all_docs =  Object.keys(all_chapters)
                this.setState({
                    all_chapters,all_docs
                })
            }  
            const verseData = await TopicService.getTopicDetailByName(verseIDString);
            if (verseData?.data && verseData.data.verse_info) {
                verseInfos = verseData.data?.verse_info[0];
                if (verseInfos["verse_found"] && verseInfos["verse_string_valid"]) {
                    const chapter_number = verseInfos.chapter_number
                    const doc_name = verseInfos.doc_name
                    const selected_doc = doc_name
                    const selected_chapter = chapter_number             
                    this.setState({
                        chapter_id: verseInfos.chapter_id,
                        verseInfos: verseInfos,
                        chapter_number,
                        doc_name,
                        selected_doc, selected_chapter
                    });   
                     
                    is_valid = true
                }
            }
        }
        if (!is_valid) {
            this.props.history.push({
                pathname: `/error`,
            });
        }
    }
    
    tooltipHandler(event)  {
        const target = event.target;
        const tooltip = target.parentNode.querySelector('.tooltip') || null;
        const tooltip_isshowing = target.parentNode.querySelector('.tooltip.show') || null;
        const tooltips = document.querySelectorAll('.tooltip.show');
        tooltips.forEach( item => {item.classList.remove('show')});
        if (!tooltip_isshowing && target.classList.contains('ref-icon') && tooltip) {
            var target_rect = target.getBoundingClientRect();
            var outter_div = document.querySelector(".verse-content")
            var outter_div_rect = outter_div.getBoundingClientRect()
            const difference = outter_div_rect.right-target_rect.right
            if ((difference)<260) {
                tooltip.style.left = (difference-260).toString() + "px"
            } else {
                tooltip.style.left = "0"
            }
        tooltip.classList.add('show');
        }
    }

    replaceWithIcon (html) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');

      // Select all span elements with class "f"
      const spans = doc.querySelectorAll('span.f');

      // Replace each span with an icon and tooltip
      spans.forEach(span => {
        const text = span.textContent;
        const content = `
                            <img class='ref-icon' src="/img/ref.svg" title="${text}" alt="ref" width='16px'>
                            <span class='tooltip'>${text}</span>
                        `
        // Replace the span with the icon
        span.innerHTML= content

      });

      return doc.body.innerHTML;
    };

    UNSAFE_componentWillMount() {
        emitter.off('voiceChange');
        document.removeEventListener('click', this.tooltipHandler);
    }

    handleShareClick() {
        this.copyToClipboard(window.location.href);
    }

    copyToClipboard(text) {
        navigator.clipboard.writeText(text)
            .then(() => {
                this.setState({
                    tooltip: true,
                })
                setTimeout(() => {
                    this.setState({
                        tooltip: false,
                    })
                }, 1500)
            })
            .catch((error) => {
                console.error('Error copying to clipboard:', error);
            });
    }

    goToBiblicaChapter = () => {
        const biblicaURL = this.state?.verseInfos?.chapter_url;
        var win = window.open(biblicaURL, '_blank');
        win.focus();
    };

    handleOnclickReadIcon = async (chapter_id) => {
        const voice = sessionStorage.getItem('currentVoiceName') || 'en-US-Standard-B';
        this.setState({
            auDisplay: true
        })
        if (this.state.voice !== voice) {
            this.reloadAudio(chapter_id, voice);
        } else {
            if (chapter_id !== this.state.chapter_id) {
                this.reloadAudio(chapter_id, voice);
            } else {
                const audioElement = document.getElementById('myAudio');
                if (audioElement) {
                    if (audioElement.paused) {
                        audioElement.play();
                    } else {
                        audioElement.pause();
                    }
                } else {
                    this.reloadAudio(chapter_id, voice);
                }
            }
        }
    }

    reloadAudio = (chapter_id, voice) => {
        if (voice && chapter_id) {
            setTimeout(async () => {
                this.setState({
                    voice: voice
                })
                const url = TextToSpeech.make_chapter_speech(chapter_id.toString(), voice)
                this.setState({
                    audioUrl: url,
                    auDisplay: true
                });
            }, 200)
        }
    }

    cancelAudio = () => {
        this.setState({
            auDisplay: false,
        })
    }

   async moveToDoc(doc,lastpoint=false) {
        this.stop_audio()
        let string = ''
        let doc_number = 0
        let selected_doc = this.state.selected_doc
        const all_docs = Array.from(this.state.all_docs) || []
        const all_chapters = this.state.all_chapters
        let index = all_docs.indexOf(selected_doc); 
        if (doc==='next') {  
            if (index < (all_docs.length-1)) {
                doc_number = index + 1
            }
            selected_doc = all_docs[doc_number]
            string = selected_doc + ' 1'
        } else if (doc==='previous') {
            let target_chapter = 1
            if (index>0) {
                doc_number = index-1
            } else {
                doc_number = all_docs.length -1
            }
            selected_doc = all_docs[doc_number]
            if (lastpoint) {
                target_chapter =  Array.from(Object.values(all_chapters[selected_doc]['chapter'])).length
            } 
            string = selected_doc + " " + target_chapter
        } else {
            string = doc + ' 1'
        }
        this.props.history.push({
            pathname: `/chapter-overview/${string}`,
        });
        
    }

    stop_audio() {
        const audioElement = document.getElementById('myAudio');
        if (audioElement) {
            if (!audioElement.paused) {
                audioElement.pause();
            }
        }
        this.setState({
            audioUrl: null,
            auDisplay: false
        });
    }

    moveToChapter(status) {
        this.stop_audio()
        let string = ''
        let selected_doc = this.state.selected_doc
        let chapter = parseFloat(this.state.chapter_number)
        const all_chapters = this.state.all_chapters
        let chapter_number_oblist = Object.values(all_chapters[selected_doc]['chapter'])
        let chapter_number_list = chapter_number_oblist.map(chapter => chapter.chapter_id);
        let number_of_chapters = Object.keys(chapter_number_list).length
        if (status==='next') {
            if (chapter>number_of_chapters-1) {
                return this.moveToDoc('next')
            } else {
                chapter += 1
            }
        } else if (status==='previous') {
             if (chapter===1) {
                return this.moveToDoc('previous',true)
            } else {
                chapter -= 1
            }
        } else {
            chapter = status 
        }
        string = selected_doc + ' ' + chapter
         this.props.history.push({
            pathname: `/chapter-overview/${string}`,
        });
        this.getData(string)
    }

    handleChapterSelectChange (event) {
        const value = event.target.value
        this.moveToChapter(value)     
    };

    handleSelectChange (event) {
        const selected_doc = event.target.value
        this.moveToDoc(selected_doc)     
    };

    handleHashChange = () => {
        const hash = window.location.hash.substring(1); // Remove the leading #
        if (hash) {
            this.scrollToVerse(hash);
        }
    }

    scrollToVerse(verseID) {
        const targetElements = document.querySelectorAll(`[data-verse-id="${verseID}"]`);
        let next_verse_id = null;
        if (targetElements.length > 0) {
            let verse_id_splited = verseID.split(".")
            let verse_id_number = parseInt(verse_id_splited[verse_id_splited.length - 1]);
            verse_id_splited[verse_id_splited.length - 1] = (verse_id_number + 1).toString();
            let next_verse_id = verse_id_splited.join(".");
            console.log(next_verse_id);
            targetElements[0].scrollIntoView({
                behavior: 'instant',
                block: 'center',
                inline: 'center'
            });
            let current_process_paragraph = null
            let list_highlighted_elements = [];
            targetElements.forEach(element => {
                element.classList.add('highlight');
                list_highlighted_elements.push(element);
                const parentP = element.closest('p');
                if (parentP.id !== current_process_paragraph) {
                    current_process_paragraph = parentP.id;
                }
                const spans = parentP.querySelectorAll('span');
                let is_start = false;
                for (const span_element of spans) {
                    const data_verse_id = span_element.getAttribute('data-verse-id');
                    if (!is_start) {
                        if (data_verse_id === verseID) {
                            is_start = true;
                        }
                    } else {
                        if (data_verse_id === next_verse_id) {
                            break;
                        } else if (data_verse_id !== verseID) {
                            if (span_element.textContent) {
                                span_element.classList.add('highlight');
                                list_highlighted_elements.push(span_element)
                            }
                        }
                    }
                }
            });
            setTimeout(() => {
                list_highlighted_elements.forEach(element => {
                    element.classList.remove('highlight');
                });
            }, 12000); // Remove highlight after 12 seconds
        }
    }

    processHighlightVerseWithTheSameParagraph(element) {

    }

    render() {
        const {auDisplay,verseInfos, all_chapters, all_docs,doc_name, chapter_number,selected_doc, selected_chapter, chapter_id} = this.state;
        const verse_chapter_overview = verseInfos?.verse_chapter_overview;
        const verse_chapter_overview_format =  DOMPurify.sanitize(verse_chapter_overview);
        const audioUrl = this.state.audioUrl;
        return (
            <div className='verse-page'>
                <Helmet>
                    <title> Bible Chapter Summary | ScriptureCast®</title>
                </Helmet>
                <div className="verse-audio">
                    {auDisplay && 
                    <div>
                        <audio id="myAudio" src={audioUrl} preload="auto" controls autoPlay/>     
                    </div>}
                </div>
               
                <div className='overview-header'>
                {all_chapters &&
                <div className=' d-flex flex-row gap-4 justify-center'>
                    <button  onClick={()=>this.moveToDoc('previous')}>{"<<"}</button>
                    <button onClick={()=>this.moveToChapter('previous')}>{"<"}</button>
                    {/*<select className="form-select" value={selected_doc} onChange={(e)=>this.handleSelectChange(e)}>*/}
                    {/*    {all_docs && all_docs.map ((item,index) => (*/}
                    {/*         <option key={index} value={item} >{item}</option>*/}
                    {/*    ))}*/}
                    {/*</select>*/}
                    {/*{all_chapters[selected_doc] && */}
                    {/*<select className="form-select"  value={selected_chapter} onChange={(e)=>this.handleChapterSelectChange(e)}>*/}
                    {/*    {Object.entries(all_chapters[selected_doc]['chapter']).map(([key, value]) => (*/}
                    {/*        <option key={key} value={key}>{key}</option>*/}
                    {/*    ))}*/}
                    {/*</select>}*/}
                    <button onClick={()=>this.moveToChapter('next')}>{">"}</button>
                    <button onClick={()=>this.moveToDoc('next')}>{">>"}</button>
                </div>}
                {verseInfos &&
                <div className='title-bar'>
                    <div className='font-bold'>New International Version</div>
                    <div className='right-section'>
                        <div className="font-bold">{selected_doc} {selected_chapter}</div>
                        <div className="listen-button">
                            <div>
                                <span
                                    onClick={() => {
                                        this.handleOnclickReadIcon(chapter_id)
                                    }}
                                    className="fa fa-volume-up"
                                    aria-label="Listen to this text"
                                    role="img"></span>
                            </div>
                        </div>
                        <div className="copy-button" onClick={this.handleShareClick}>
                            <div>
                                <span
                                    className="fa fa-share"
                                    aria-label="Share this text"
                                    role="img"></span>
                            </div>
                            <div className={this.state.tooltip ? 'copy-tooltip' : 'tooltip-hidden'}>Copied
                                Link
                            </div>
                        </div>
                    </div>
                </div>}
                </div>

                {verseInfos &&
                    <div className="verse-page-content verse-font">
                        <div className='verse-content'>
                            <div className="scripture-styles content"
                                 dangerouslySetInnerHTML={{__html: this.replaceWithIcon(verse_chapter_overview_format)}}></div>
                            <div className='font-size-medium text-center'>
                            <strong>NIV</strong> : {doc_name + ' ' + chapter_number}
                            </div>
                        </div>
                    </div>}
            </div>
        );
    }
}

export default withRouter(connect(null, {START_LOADING, STOP_LOADING})(ChapterOverview));
