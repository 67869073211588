import React from 'react';
import './spinner.css';
import LoadingScreen from 'react-loading-screen';

export const Spinner = (props) => {

    return (
        <LoadingScreen
            loading={true}
            bgColor="rgba(0,0,0,0.8)"
            spinnerColor="#9aa6b2"
            textColor="#676767"
            logoSrc=""
            text=""
        >
            {' '}
        </LoadingScreen>
    );
};
