import Container from '@mui/material/Container'
import React from 'react';
import {withRouter} from 'react-router-dom';
import './contact-us.css';
import {Helmet} from 'react-helmet';
import {START_LOADING, STOP_LOADING} from '../../../redux/actions/loader';
import {connect} from 'react-redux';
import CustomFormLabel from '../../common/reusable/form-label/form-label';
import ReCAPTCHA from "react-google-recaptcha";
import {Formik} from "formik";
import * as yup from "yup";
import ContactUsService from '../../../services/contact-us.service';

/* local: */
// const google_recaptcha_api_key = "6LcpkKsUAAAAADxV8pSA8Pklfkl-UUxUV3xOuqKE"
const google_recaptcha_api_key = process.env.REACT_APP_GOOGLE_RECAPTCHA_API_KEY

class ContactUs extends React.Component {
    constructor(props) {
        super(props);
        this.props.START_LOADING();
        this.state = {
            errors: [],
            isSubmitted: false
        };
    }

    async componentDidMount() {
        this.props.STOP_LOADING();
    }

    showNamesError(errors, touched) {
        if (
            errors.firstName &&
            touched.firstName &&
            errors.lastName &&
            touched.lastName
        ) {
            return <div className="error-yellow">First Name and Last Name are required.</div>;
        }
        if (errors.firstName && touched.firstName) {
            return (<div className="error-yellow">{errors.firstName}</div>);
        }
        if (errors.lastName && touched.lastName) {
            return (<div className="error-yellow">{errors.lastName}</div>);
        }
    }

    componentWillUnmount() {
    }

    render() {
        const {isSubmitted} = this.state;
        return (
            <Container>
                <Helmet>
                    <title> Contact Us | ScriptureCast®</title>
                </Helmet>

                <Formik
                    initialValues={{
                        firstName: '',
                        lastName: '',
                        email: '',
                        subject: '',
                        message: '',
                        recaptcha: '',
                    }}
                    onSubmit={async (values) => {
                        this.props.START_LOADING();
                        await ContactUsService.sendContactUs(values).then(r => this.setState({isSubmitted: true}));
                        this.props.STOP_LOADING();
                    }}
                    validationSchema={yup.object().shape({
                        firstName: yup.string().required('First Name is required.'),
                        lastName: yup.string().required('Last Name is required.'),
                        email: yup
                            .string()
                            .email('Please enter a valid email address')
                            .required('Email is required.'),
                        subject: yup.string().required('Message Subject is required.'),
                        message: yup.string().required('Message is required.'),
                        recaptcha: yup.string('The reCAPTCHA was invalid.').required('The reCAPTCHA was invalid.'),
                    })}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleSubmit,
                          handleChange,
                          setFieldValue,
                      }) => (
                        <div className="contact-us-row">
                            <div className="contact-us-col">
                                <div className="elementor-widget-wrap">
                                    <div className="col col-md-12 version version-primary">
                                        <div className="header-element">
                                            <h1>Contact Us</h1>
                                        </div>
                                        <div className="content-element">
                                            {/* In case of when submitted successful, show only thank and hide submit form */}
                                            {!isSubmitted && (
                                                <>
                                                    <p>
                                                        If you are having a problem with this site, or have a question
                                                        you'd like to ask us, or just want to know a bit more about us,
                                                        don't hesitate to get in touch.
                                                    </p>
                                                    <h5>
                                                        <strong>Contact Form</strong>
                                                    </h5>
                                                </>
                                            )}
                                            <form onSubmit={handleSubmit}>
                                                <div className="form-body">
                                                    {/* In case of when submitted successful, show only thank and hide submit form */}
                                                    {isSubmitted && (
                                                        <p>
                                                            Thanks for contacting us! We will get in touch
                                                            with you shortly.
                                                        </p>
                                                    )}
                                                    {/* In case of firt time go to contact us page */}
                                                    <div className="form-fields">
                                                        {!isSubmitted && (
                                                            <>
                                                                {/* First name and Last name fields */}
                                                                <div className="fields-container">
                                                                    <CustomFormLabel
                                                                        name="Name"
                                                                        isRequired={true}
                                                                    ></CustomFormLabel>
                                                                    <div className="input-container">
                                    <span className="name-first">
                                      <input
                                          id="firstName"
                                          name="firstName"
                                          value={values.firstName}
                                          onChange={handleChange}
                                          type="text"
                                          placeholder="First Name"
                                          className="input-first-name form-control"
                                      />
                                    </span>
                                                                        <span className="name-last">
                                      <input
                                          id="lastName"
                                          name="lastName"
                                          value={values.lastName}
                                          onChange={handleChange}
                                          type="text"
                                          placeholder="Last Name"
                                          className="form-control"
                                      />
                                    </span>
                                                                        {this.showNamesError(errors, touched)}
                                                                    </div>
                                                                </div>
                                                                {/* Email field */}
                                                                <div className="fields-container">
                                                                    <CustomFormLabel
                                                                        name="Email"
                                                                        isRequired={true}
                                                                    ></CustomFormLabel>
                                                                    <div className="input-container">
                                                                        <input
                                                                            id="email"
                                                                            name="email"
                                                                            value={values.email}
                                                                            onChange={handleChange}
                                                                            type="email"
                                                                            placeholder="Enter Your Email Address"
                                                                            className="form-control"
                                                                        />
                                                                        {errors.email && touched.email && (
                                                                            <div className="error-yellow">
                                                                                {errors.email}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>

                                                                {/* Message Subject field */}
                                                                <div className="fields-container">
                                                                    <CustomFormLabel
                                                                        name="Message Subject"
                                                                        isRequired={true}
                                                                    ></CustomFormLabel>
                                                                    <div className="input-container">
                                                                        <input
                                                                            id="message-subject"
                                                                            name="subject"
                                                                            value={values.subject}
                                                                            onChange={handleChange}
                                                                            type="text"
                                                                            placeholder="Enter a Title For Your Message"
                                                                            className="form-control"
                                                                        />
                                                                        {errors.subject && touched.subject && (
                                                                            <div className="error-yellow">
                                                                                {errors.subject}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>

                                                                {/* Message Content field */}
                                                                <div className="fields-container">
                                                                    <CustomFormLabel
                                                                        name="Message"
                                                                        isRequired={true}
                                                                    ></CustomFormLabel>
                                                                    <div className="input-container">
                                    <textarea
                                        id="message-content"
                                        name="message"
                                        value={values.message}
                                        onChange={handleChange}
                                        type="text"
                                        className="text-area form-control"
                                    />
                                                                        {errors.message && touched.message && (
                                                                            <div className="error-yellow">
                                                                                {errors.message}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>

                                                                {/* Google Recapcha */}
                                                                <div className="fields-container">
                                                                    <ReCAPTCHA
                                                                        style={{display: 'inline-block'}}
                                                                        theme="light"
                                                                        size="normal"
                                                                        sitekey={google_recaptcha_api_key}
                                                                        onChange={(response) => {
                                                                            setFieldValue('recaptcha', response);
                                                                        }}
                                                                    />
                                                                    {errors.recaptcha && touched.recaptcha && (
                                                                        <div className="error-yellow">
                                                                            {errors.recaptcha}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </form>
                                            {!isSubmitted && (
                                                <div className="form-footer">

                                                    <button type="submit" className='button button-style2'
                                                            onClick={handleSubmit}>
                                                        SUBMIT
                                                    </button>

                                                </div>
                                            )}
                                        </div>
                                        {/* <div className="footer-element">
                      <h5>
                        <strong>Scripture Cast</strong>
                      </h5>
                      <p>
                        The Mount Business &amp; Conference Centre,<br></br>2
                        Woodstock Link, Belfast,<br></br>
                        BT6 8DD, United Kingdom<br></br>
                        +44 (0)28 9073 5875<br></br>
                        europe@biblica.com
                      </p>
                      <p>
                        Maps:{' '}
                        <a href="https://www.bing.com/maps?osid=66404758-b475-4378-abfd-532cdd14bc1a&amp;cp=54.594937~-5.906349&amp;lvl=16&amp;v=2&amp;sV=2&amp;form=S00027">
                          Bing
                        </a>{' '}
                        |{' '}
                        <a href="https://www.google.com/maps/place/The+Mount+Business+Centre,+2+Woodstock+Link,+Belfast+BT6+8DD,+UK/@54.5949662,-5.9084412,917m/data=!3m2!1e3!4b1!4m5!3m4!1s0x48610907ca2c4fef:0xf7b9ee6aeda57d8e!8m2!3d54.5949631!4d-5.9062525?hl=en&amp;shorturl=1">
                          Google
                        </a>
                      </p>
                    </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Formik>
            </Container>
        );
    }
}

export default withRouter(
    connect(null, {START_LOADING, STOP_LOADING})(ContactUs)
);
