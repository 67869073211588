import React from 'react';
import {withRouter} from 'react-router-dom';
import TopicService from '../../services/topics.service';
import {__debug, remove_special_char} from '../../utils.js';
import CategoryService from '../../services/categories.service';
import {Helmet} from 'react-helmet';
import {START_LOADING, STOP_LOADING} from "../../redux/actions/loader"
import {connect} from 'react-redux';
import {BsArrowRight} from "react-icons/bs";
import {LinkHoverUnderline} from '../common/reusable/link-hover-underline/link-hover-underline.jsx';
import './bible.css'
import {replaceWithHighlight} from '../../utils.js'
import {addParamsToURL, formatLink} from '../../utils.js';

class TopicByCategory extends React.Component {
    constructor(props) {
        super(props);
        this.props.START_LOADING();
        const searchParams = new URLSearchParams(window.location.search)
        let previous_topic = formatLink((searchParams.get('topic') || ''));
        const keyword = searchParams.get('search_text')?.toUpperCase() || ''
        this.state = {
            categoryBySubjectList: [],
            index: props.location.state?.index,
            currentSubject: props.location.state?.currentSubject,
            currentCategory: props.location.state?.currentCategory,
            subjectId: '',
            categoryId: '',
            subjectUrl: '',
            keyword,
            previous_topic
        };
        this.retrieveTopicByCategory = this.retrieveTopicByCategory.bind(this);
    }

    async componentDidMount() {
        let subject_path = null;
        let category_path = null;
        let is_valid = false;
        let subjectUrl = ''
        if (this.props.match && this.props.match.params) {
            subject_path = this.props.match.params.subjectId;
            subjectUrl = `/bible/${subject_path}`
            category_path = this.props.match.params.categoryId;
            this.setState({
                subject_path: subject_path,
                category_path: category_path
            })
        }
        if (subject_path != null && category_path != null) {
            let element_list = subject_path.split("-");
            let subjectId = element_list[element_list.length - 1];
            let subject_prefix = element_list.slice(0, element_list.length - 1).join("-")
            element_list = category_path.split("-");
            let categoryId = element_list[element_list.length - 1];
            let category_prefix = element_list.slice(0, element_list.length - 1).join("-")
            const response = await CategoryService.getCategoryById(categoryId);
            const found_category = response.data?.data;
            if (found_category) {
                if ((category_prefix === remove_special_char(found_category["category"])) && (subjectId === found_category["subject_id"].toString()) && (subject_prefix === remove_special_char(found_category["subject"]))) {
                    is_valid = true
                    subjectUrl += `?category=${formatLink(found_category['category'])}`
                    this.setState({
                        currentSubject: found_category["subject"],
                        currentCategory: found_category['category'],
                        subjectId: found_category['subject_id'],
                        categoryId: categoryId,
                        categoryPath: `/bible/${subject_path}/${category_path}`
                    });
                    await this.retrieveTopicByCategory(categoryId);
                }
            }
        }
        if (!is_valid) {
            this.props.history.push({
                pathname: `/error`,
            });
        }
        this.setState({subjectUrl})
        this.props.STOP_LOADING();
    }

    async retrieveTopicByCategory(categoryId) {
        await TopicService.getTopicListByCategory(categoryId)
            .then((response) => {
                this.setState({
                    topicByCategoryList: response.data?.data,
                });
                __debug('response.data?.data', 'p');
                __debug(response.data?.data, 'p');
                if (this.state.previous_topic) {
                    setTimeout(()=>this.scrollToTopic(this.state.previous_topic),500)
                }
               
            })
            .catch((err) => {
                __debug(err, 'e');
            });
    }

    onClick = (topic_name, topicId, parent) => {
        addParamsToURL(this,{topic : topic_name})
        let path = `${remove_special_char(topic_name)}-${topicId}`
        if (parent !== null && parent !== "") path = `${remove_special_char(parent)}-${path}`;
        this.props.history.push({
            pathname: `${this.state.categoryPath}/${path}`,
            state: {
                currentSubject: this.state.currentSubject,
                currentCategory: this.state.currentCategory,
                topicId: topicId,
            },
        });
    };

    handleChildTopic = (topicList) => {
        if (topicList) {
            var dataWithParentTopic = topicList.filter((el) => {
                return el['parent_topic'] !== '';
            });
            var dataWithoutParentTopic = topicList.filter((el) => {
                return el['parent_topic'] === '';
            });
            var dataParent = [];
            var dataNoParent = [];
            var parentTopicList = [];

            for (const el of dataWithoutParentTopic) {
                const item = {sortField: el['topic'], hasParent: 0, ...el};
                dataNoParent.push(item);
            }
            for (const el of dataWithParentTopic) {
                if (!parentTopicList.includes(el['parent_topic'])) {
                    parentTopicList.push(el['parent_topic']);
                }
            }

            for (const parent of parentTopicList) {
                const sameParentTopicsArray = [];
                for (const item of dataWithParentTopic) {
                    if (item['parent_topic'] === parent) {
                        sameParentTopicsArray.push(item);
                    }
                }
                const data = {
                    sortField: parent,
                    hasParent: 1,
                    topicsArray: sameParentTopicsArray,
                };
                dataParent.push(data);
            }
            const handledTopicByCategoryList = [...dataNoParent, ...dataParent];
            return handledTopicByCategoryList;
        } else {
            return null;
        }
    };
    listItemsHasParentTopic = (topic) => {
        const listItems = topic?.topicsArray.map((item) => (

            <div
                key={item['id']}
                id={`link-${formatLink(item['topic'])}`}
                className="scripture-link"
                onClick={() => {
                    this.onClick(item['topic'], item['id'], item['parent_topic']);
                }}
            >
                {item['topic']}
            </div>

        ));
        return <div className='normal-list'>{listItems}</div>;
    };

     scrollToTopic = (topic) => {
        const targetElement = document.getElementById(`link-${topic}`)
        if (targetElement) {
            targetElement.classList.add('clicked')
            targetElement.scrollIntoView({
                behavior: 'instant',
                block: 'center',
                inline: 'center'
            });
        } else {
            console.log(`Element with topic='${topic}' not found`);
        }
    }

    render() {
        const {topicByCategoryList, currentSubject, currentCategory, subjectUrl,keyword} = this.state;
        const handledTopicByCategoryList =
            this.handleChildTopic(topicByCategoryList);
        return (
            <div className='topic-list-page'>
                <Helmet>
                    <title> Topics Related To Category | ScriptureCast®</title>
                </Helmet>

                <div className='topic-list-content bible-content'>
                    <div className='left-section'>
                     {keyword && <div>Keyword: <strong>{keyword}</strong></div>} 
                        <div className='left-item'>
                            <div className="sc-title">Subject:</div>
                            <div className="sc-content">
                                <LinkHoverUnderline
                                    url={subjectUrl}
                                    linkText={currentSubject}
                                /></div>
                        </div>
                        <div className='left-item'>
                            <div className="sc-title">Category:</div>
                            <div className="sc-content">
                            <LinkHoverUnderline
                                    url={subjectUrl}
                                    linkText={currentCategory}
                                /></div>
                        </div>
                        <div className='left-item'>
                            <div className="sc-title">Topic:</div>
                            <div className="sc-content">Please select to view verses <BsArrowRight/></div>
                        </div>
                    </div>
                    <div className='right-section'>
                        <div id="title-content" className="bible-link-menu">
                            {handledTopicByCategoryList && handledTopicByCategoryList.map((item, index) => {
                                if (item['hasParent'] === 1) {
                                    return (
                                        <div className="liHasParent" key={item['sortField']}>
                                            <span className='topic-parent'>{item['sortField'] + ':'}</span>
                                            {this.listItemsHasParentTopic(item)}
                                        </div>
                                    );
                                }
                                return (
                                    <div
                                        key={item['id']}
                                        id={`link-${formatLink(item['topic'])}`}
                                        className="scripture-link"
                                        onClick={() => {
                                            this.onClick(item['topic'], item['id'], item['parent_topic']);
                                        }}
                                    >{keyword? replaceWithHighlight(item['topic'],keyword): item['topic']}
                                       
                                    </div>
                                );
                            })}

                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default withRouter(connect(null, {START_LOADING, STOP_LOADING})(TopicByCategory));