import axios from "axios";
import {API_URL} from "../config/config";

class SubjectService {
    async getAllSubject() {
        const res = await axios.post(`${API_URL}/subject/get`);
        return res;
    }

    async getSubjectById(id) {
        const params = {
            'id': id,
        };
        const res = await axios.post(`${API_URL}/subject/getById`, params);
        return res;
    }
}


const subjectServiceInstance = new SubjectService();

export default subjectServiceInstance;