const ProgressBar = ({progressBarRef, audioRef}) => {
    const handleProgressChange = () => {
        audioRef.current.currentTime = progressBarRef.current.value;
    };

    return (
        <div>
            <input
                type="range"
                ref={progressBarRef}
                defaultValue="0"
                onChange={handleProgressChange}
            />
        </div>
    );
};
export default ProgressBar;