import React from 'react';
import {withRouter} from 'react-router-dom';
import './spiritual-gifts-assessment-success.css';
import {connect} from 'react-redux';
import {START_LOADING, STOP_LOADING} from '../../../../redux/actions/loader'
import {BsFillCircleFill} from "react-icons/bs";
import {Redirect} from 'react-router-dom';
import spiritualAssessmentData from '../spiritualGiftsAssessmentData';

class SpiritualGiftsAssessmentSuccess extends React.Component {
    constructor(props) {
        super(props);
        this.props.START_LOADING();
        this.state = {
            redirect: false,
            url: '',
            translate: spiritualAssessmentData.en.success,
            language: "en",
        }
    }

    componentDidMount() {
        const searchParams = new URLSearchParams(window.location.search);
        const uuid = searchParams.get('uuid');
        this.setState({
            url: "/spiritual-gifts-assessment/questionnaire?uuid=" + uuid,
        })
        setTimeout(() => {
            this.setState({redirect: true})
        }, 3000);
        let defaultLanguage = searchParams.get('lang');
        if (defaultLanguage) {
            if (defaultLanguage in spiritualAssessmentData) {
                this.setState({
                    language: defaultLanguage,
                    translate: spiritualAssessmentData[defaultLanguage]["success"],
                })
            }
        }
        this.props.STOP_LOADING();
    }

    render() {
        const {url, redirect, translate} = this.state;
        return (
            <div className="spiritual-gifts-assessment-link-page">
                <h2>{translate.congratulations}</h2>
                <h1>{translate.assessmentArrived}</h1>
                <div>{translate.directing}</div>
                <div className='spiritual-gifts-assessment-spinner'>
                    <span className='spinner1'><BsFillCircleFill/></span>
                    <span className='spinner2'><BsFillCircleFill/></span>
                    <span className='spinner1'><BsFillCircleFill/></span>
                </div>
                {redirect && <Redirect to={url}/>}
            </div>
        );
    }
}

export default withRouter(
    connect(null, {START_LOADING, STOP_LOADING})(SpiritualGiftsAssessmentSuccess)
);
