import React from 'react';
import './ImageModal.css';

export default class ImageModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            setIsOpen: false
        }
    }


    handleClick() {
        this.setState({
            setIsOpen: true,
        })
    };

    handleClose() {
        this.setState({
            setIsOpen: false,
        })
    };

    render() {
        const {img} = this.props;
        const {setIsOpen} = this.state;

        return (
            <div>
                <img className='main-image-modal' src={img} alt="Result" onClick={() => this.handleClick()}/>
                {setIsOpen && (
                    <div className="image-modal" onClick={() => this.handleClose()}>
                        <img src={img} alt="Result"/>
                    </div>
                )}
            </div>
        );
    }


}
  