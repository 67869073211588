import React from 'react';
import './donation-button.css';
import {Link} from 'react-router-dom';


export default class DonationButton extends React.Component {

    render() {
        return (
            <>
                <Link to="/donate">
                    <div id="main-donate-button">
                        Donate
                    </div>
                </Link>
            </>
        )
    }
}
