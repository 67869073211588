import React from 'react';
import {withRouter} from 'react-router-dom';
import './about-us-summary.css';
import {Helmet} from 'react-helmet';
import {connect} from 'react-redux';
import {START_LOADING, STOP_LOADING} from '../../../../redux/actions/loader';
import {LinkHoverUnderline} from '../../../common/reusable/link-hover-underline/link-hover-underline';


class AboutUsSummary extends React.Component {
    constructor(props) {
        super(props);
        this.props.START_LOADING();
    }

    componentDidMount() {
        this.props.STOP_LOADING();
    }

    render() {
        return (
            <>
                <Helmet>
                    <title> About Us Summary | ScriptureCast®</title>
                </Helmet>
                <div className='aboutus-content'>
                    <div className="font-size-small-title">About Us</div>

                    <div className="bullet-list fit-content">
                        <LinkHoverUnderline
                            fontSize='font-size-large'
                            url="/about-us/introduce-scripturecast"
                            linkText="What is ScriptureCast"
                        />
                        <LinkHoverUnderline
                            fontSize='font-size-large'
                            url="/about-us/spiritual-consultation"
                            linkText="Spiritual Consultation"
                        />
                        <LinkHoverUnderline
                            fontSize='font-size-large'
                            url="/podcasts"
                            linkText="The Shepherd and The Shrink Podcast"/>
                    </div>
                </div>

            </>
        );
    }
}

export default withRouter(
    connect(null, {START_LOADING, STOP_LOADING})(AboutUsSummary)
);
