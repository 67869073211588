import React, {Component} from 'react';
import {AccountService} from '../../../../services/account.service';
import moment from 'moment-timezone';


class TimeLimit extends Component {
    componentDidMount() {
        this.interval = setInterval(() => {
            this.monitorElapsedTime()
        }, 60000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    getTodayTimestampOfPDT() {
        const pdtTimestamp = moment.tz('America/Los_Angeles').startOf('day').valueOf();
        return pdtTimestamp;
    };

    monitorElapsedTime() {
        const user = AccountService.userValue;
        if (user) {
            localStorage.removeItem('firstVisitToday');
            localStorage.removeItem('timeVisitToday');
        } else {
            let firstVisit = localStorage.getItem('firstVisitToday');
            let todayTimestamp = this.getTodayTimestampOfPDT();
            let now = Date.now();
            try {
                firstVisit = parseInt(firstVisit);
            } catch {
                firstVisit = null;
            }
            let isTimeVisitUpdate = true
            if (firstVisit && firstVisit > todayTimestamp) {
                isTimeVisitUpdate = false;
            }
            if (isTimeVisitUpdate) {
                localStorage.setItem('firstVisitToday', now);
                localStorage.setItem('timeVisitToday', 0);
            }
            let timeVisitToday = localStorage.getItem('timeVisitToday');
            try {
                timeVisitToday = parseInt(timeVisitToday);
            } catch {
                timeVisitToday = 0;
            }
            timeVisitToday += 1;
            localStorage.setItem('timeVisitToday', timeVisitToday);
        }
    }

    render() {
        return (
            <></>
        );
    }
}

export default TimeLimit;
