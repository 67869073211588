import axios from 'axios';
import {API_URL} from '../config/config';

export class SpiritualGiftsAssessmentService {

    async createCheckoutSession(name, email, accountId, language_code) {
        const params = {
            'name': name,
            'email': email,
            'accountId': accountId,
            'defaultLanguage': language_code
        };
        const res = await axios.post(`${API_URL}/spiritual-gifts-assessment/createCheckoutSession`, params);
        return res.data;
    }

    async getForm(id) {
        const params = {
            'uuid': id,
        };
        const res = await axios.post(`${API_URL}/spiritual-gifts-assessment/getForm`, params);
        return res.data;
    }

    async updateForm(responseInfo) {
        const params = responseInfo;
        const res = await axios.put(`${API_URL}/spiritual-gifts-assessment/updateForm`, params);
        return res;

    }

    async submitForm(responseInfo) {
        const params = responseInfo;
        const res = await axios.post(`${API_URL}/spiritual-gifts-assessment/submitForm`, params);
        return res;
    }

    async switchLanguage(params) {
        const res = await axios.put(`${API_URL}/spiritual-gifts-assessment/switchLanguage`, params);
        return res;
    }
}


const spiritualGiftsAssessmentServiceInstance = new SpiritualGiftsAssessmentService();
export default spiritualGiftsAssessmentServiceInstance;