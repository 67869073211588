import React, {useState} from 'react';
import {Route, useHistory} from 'react-router-dom';
import CustomModal from '../components/common/reusable/custom-modal/custom-modal';


function isTrialTimeExpired() {
    let timeVisitToday = localStorage.getItem('timeVisitToday');
    if (timeVisitToday) {
        try {
            timeVisitToday = parseInt(timeVisitToday)
        } catch {
            timeVisitToday = 0
        }
        if (timeVisitToday > 60) {
            return true
        }
    }
    return false;
}

function PrivateRoute({component: Component, roles, ...rest}) {
    let history = useHistory();
    const [show, setShow] = useState(false);

    const handleLoginButton = (fromLink) => {
        history.push({
            pathname: "/account/login",
            state: {fromLink: fromLink}
        });
    }
    const handleRegisterButton = () => {
        history.push({
            pathname: "/account/register",
        });
    }

    return (
        <Route {...rest} render={props => {
            if (isTrialTimeExpired()) {
                setShow(true);
                return (
                    <>
                        <CustomModal
                            isShowModal={show}
                            modalHeading={'Trial Period Has Expired'}
                            modalBody={
                                'You have reached the end of your temporary access period. Please create an account to continue using our website.'
                            }
                            leftButtonName={'Sign in'}
                            buttonName={'Sign up'}
                            handleCloseModal={() => handleLoginButton(props.location.pathname)}
                            handleOKButton={handleRegisterButton}
                        ></CustomModal>
                    </>
                );
            }
            // authorized so return component
            return <Component {...props} {...rest} />
        }}/>
    );
}

export {PrivateRoute};