import axios from 'axios';
import {API_URL} from '../config/config';

class ContactUsService {
    async sendContactUs(params) {
        const res = await axios.post(`${API_URL}/forms/contactUs`, params);
        return res;
    }
}

const contactUsServiceInstance = new ContactUsService();
export default contactUsServiceInstance;
