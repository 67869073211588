import React from 'react';
import './footer.css';
import {Route, Switch} from 'react-router-dom';
import {LogoFooter} from '../logo-footer/logo-footer';
import {withRouter} from 'react-router-dom';
import Copyright from "../copyright/copyright";
import DonationButton from '../donation-button/donation-button';

class Footer extends React.Component {
    render() {
        return (
            <footer className="footer-bottom">
                <DonationButton/>
                <Switch>
                    <Route exact path="/"></Route>
                    <Route exact path="/">
                        <LogoFooter/>
                    </Route>
                    <Route exact path="/about-us/summary">
                        <Copyright organization="ScriptureCast®"/>
                        <LogoFooter/>
                    </Route>
                    <Route path="/chapter-overview">
                        <Copyright organization="Biblica"/>
                        <LogoFooter/>
                    </Route>
                    <Route path="*">
                        <LogoFooter/>
                    </Route>
                </Switch>
            </footer>
        );
    }
}

export default withRouter(Footer);
