import React from 'react';
import './custom-dropdown-item.css';
import NavDropdown from 'react-bootstrap/NavDropdown';

class CustomDropdownItem extends React.Component {
    render() {
        const {iconHelp, textHelp, toPath} = this.props;

        return (
            <NavDropdown.Item id="drop-item" href={toPath}>
                <span className="icon-help">{iconHelp}</span> {textHelp}
            </NavDropdown.Item>

        );
    }
}

export default CustomDropdownItem;