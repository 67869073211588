import http from "../http-common";
import chapter_mapping from "../config/all_chapters.json";

class ChapterService {
    getAllChapters() {
        return chapter_mapping;
    }
}

const chapterServiceInstance = new ChapterService();
export default chapterServiceInstance;
