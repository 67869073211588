import {useState, useEffect} from 'react';
// icons
import {
    IoMdVolumeHigh,
    IoMdVolumeOff,
    IoMdVolumeLow,
} from 'react-icons/io';


const Volume = ({audioRef}) => {
    const [volume, setVolume] = useState(60);
    const [muteVolume, setMuteVolume] = useState(false);

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.volume = volume / 100;
            audioRef.current.muted = muteVolume;
        }
    }, [volume, audioRef, muteVolume]);


    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.volume = volume / 100;
        }
    }, [volume, audioRef]);


    return (
        <div className="volume">
            <button onClick={() => setMuteVolume((prev) => !prev)}>
                {muteVolume || volume < 5 ? (
                    <IoMdVolumeOff/>
                ) : volume < 40 ? (
                    <IoMdVolumeLow/>
                ) : (
                    <IoMdVolumeHigh/>
                )}
            </button>
            <input
                id='volume-range'
                type="range"
                min={0}
                max={100}
                value={volume}
                onChange={(e) => setVolume(e.target.value)}
                style={{
                    background: `linear-gradient(to right, #f50 ${volume}%, #ccc ${volume}%)`,
                }}/>
        </div>
    );
};

export default Volume;