import React from 'react';
import './header.css';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {AccountService} from '../../../../services/account.service.js';
import MenuIcon from '@mui/icons-material/Menu';
import searchService from '../../../../services/search.service.js';
import topicsService from '../../../../services/topics.service.js';
import {remove_special_char} from '../../../../utils.js';
import {emitter} from '../../function/event-emitter/event-emitter.jsx';
import {LinkHoverUnderline} from '../../reusable/link-hover-underline/link-hover-underline.jsx';


class NavHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectTopic: false,
            checkboxData: [],
            user_id: '',
            user: '',
            search_history: [],
            items: [],
            textitems: [],
        }
        this.getSearchHistory = this.getSearchHistory.bind(this);
    }

    async componentDidMount() {
        const user = AccountService.userValue;
        if (user) {
            await this.getSearchHistory(user?.data.id.toString())
            this.setState({
                user: user.data,
            })
        }

        emitter.on('updateSearchHistory', async (id) => {
                await this.getSearchHistory(id)
            }
        );
    }

    async componentWillUnmount() {
        emitter.off('updateSearchHistory');
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            const nav = document.getElementById('left-panel')
            nav.classList.remove('move-right')
            this.handleUnselectAll()
            this.setState({
                selectTopic: false,
                selectAll: false,
            })
        }
    }

    async getSearchHistory(user_id) {
        let search_history = []
        let search_history_data

        search_history_data = await searchService.getSearchHistory(user_id)
        search_history = search_history_data?.data || []
        this.setState({
            search_history: search_history
        })
    }

    handleToggleNav() {
        const nav = document.getElementById('left-panel')
        nav.classList.toggle('move-right')
    }

    moveToSearchResult(text) {
        const inputValue = text;
        const url = `/search-result?query=${encodeURIComponent(inputValue)}`
        // this.props.history.push()
        window.location.href = url
    }

    async moveToVerse(topic_id, search_text) {
        try {
            const topic_res = await topicsService.getTopicById(topic_id);
            const topic_detail = topic_res.data.data[0];
            const subject = remove_special_char(topic_detail.subject)
            const category = remove_special_char(topic_detail.category)
            const topic = remove_special_char(topic_detail.topic)
            const {subject_id, category_id} = topic_detail
            const text = `/bible/${subject}-${subject_id}/${category}-${category_id}/${topic}-${topic_id}?search_text=${search_text}`
            const url = encodeURI(text)
            window.location.href = url
        } catch (error) {
            console.error('Error in moveToVerse:', error);
        }
    }

    handleSelectTopic() {
        const selectTopic = this.state.selectTopic;
        if (selectTopic) {
            this.handleUnselectAll()
        }
        this.setState({
            selectTopic: !selectTopic
        })
    }


    async handleDeleteMultipleTopics() {
        const items = this.state.items
        const textitems = this.state.textitems
        const user = this.state.user
        const user_id = user.id.toString()
        await searchService.deleteMultipleSearchHistory(user_id, items)
        await searchService.deleteMultipleSearchHistory(user_id, textitems)
        await this.getSearchHistory(user_id)
        this.handleUnselectAll()
    }

    removePrefix(sentence) {
        let regex = /.*-\s*/;
        return sentence.replace(regex, "");
    }

    handleSelectAll() {
        const checkboxes = document.getElementsByClassName('search-text-checkbox')
        const topic_checkboxes = document.getElementsByClassName(' topic-checkbox')
        const items = []
        const textitems = []
        for (let checkbox of checkboxes) {
            checkbox.checked = true
        }
        for (let checkbox of topic_checkboxes) {
            checkbox.checked = true
        }
        const search_history = this.state.search_history
        search_history.forEach((item) => {
            textitems.push({search_text: item.search_text})
            const topic_id_list = item.click_info.map(info => info[0]);
            const search_text = item.search_text
            items.push({search_text, topic_id_list})
        })
        this.setState({
            selectAll: true,
            items,
            textitems
        })
    }

    handleUnselectAll() {
        const checkboxes = document.getElementsByClassName('search-text-checkbox')
        const topic_checkboxes = document.getElementsByClassName('topic-checkbox')
        for (let checkbox of checkboxes) {
            checkbox.checked = false
        }
        for (let checkbox of topic_checkboxes) {
            checkbox.checked = false
        }
        this.setState({
            selectAll: false,
            items: [],
            textitems: []
        })
    }

    addTopicToItems = (list, search_text, id) => {
        let items = list
        const findObjectIndex = items.findIndex(item => item.search_text === search_text);
        if (findObjectIndex !== -1) {
            const topic_id_list = items[findObjectIndex].topic_id_list;
            if (!topic_id_list.includes(id)) {
                items[findObjectIndex].topic_id_list = [...topic_id_list, id];
            }
        } else {
            items.push({search_text, topic_id_list: [id]});
        }
        return (items)

    }

    removeTopicFromItems = (list, search_text, id) => {
        let items = list;
        const findObjectIndex = items.findIndex(item => item.search_text === search_text);
        if (findObjectIndex !== -1) {
            const topic_id_list = items[findObjectIndex].topic_id_list
            const updatedList = topic_id_list.filter(topicId => topicId !== id);
            if (updatedList.length === 0) {
                items.splice(findObjectIndex, 1);
            } else {
                items[findObjectIndex].topic_id_list = updatedList;
            }
        }
        return items
    }

    addTextToTextItems = (list, search_text) => {
        let textitems = list
        const findObjectIndex = textitems.findIndex(item => item.search_text === search_text);
        if (findObjectIndex === -1) {
            textitems.push({search_text});
        }
        return textitems
    }

    removeTextFromTextItems = (list, search_text) => {
        let textitems = list
        const findObjectIndex = textitems.findIndex(item => item.search_text === search_text);
        if (findObjectIndex !== -1) {
            textitems.splice(findObjectIndex, 1);
        }
        return textitems
    }

    handleCheckboxChange = (event, search_text, id) => {
        let items = this.state.items.slice();
        let textitems = this.state.textitems.slice();
        const checkbox = event.currentTarget;
        const textCheckbox = document.getElementById(`${search_text}-checkbox`)
        if (checkbox.checked) {
            items = this.addTopicToItems(items, search_text, id)
        } else {
            if (textCheckbox.checked) {
                textCheckbox.checked = false
                textitems = this.removeTextFromTextItems(textitems, search_text)
            }
            items = this.removeTopicFromItems(items, search_text, id)
        }
        this.setState({items, textitems});
    };

    handleTextCheckboxChange(text) {
        let items = this.state.items.slice();
        const textCheckbox = document.getElementById(`${text}-checkbox`)
        const checkboxes = document.getElementsByClassName(`${text}-topic-checkbox`)
        let textitems = this.state.textitems.slice()
        if (textCheckbox.checked) {
            for (let checkbox of checkboxes) {
                if (!checkbox.checked) {
                    items = this.addTopicToItems(items, text, checkbox.id)
                    checkbox.checked = true
                }
            }
            textitems = this.addTextToTextItems(textitems, text)
        } else {
            textitems = this.removeTextFromTextItems(textitems, text)
        }
        this.setState({textitems, items})
    }

    render() {
        const {isLogin} = this.props;
        const {search_history, selectTopic, user, selectAll} = this.state

        return (
            <>
                <div id='toggle-nav-button' className='link-style1' onClick={this.handleToggleNav}><MenuIcon/></div>
                <div id='left-panel' className="left-panel">
                    <div className="left-panel-content">
                        {isLogin ?
                            <>
                                <LinkHoverUnderline fontSize='font-size-xlarge' url="/" linkText="Homepage"/>
                                <div>Welcome back, {user.firstName}</div>
                                <div>
                                    <div className='font-size-large'>Previous Searches</div>
                                    {search_history.length > 0 &&
                                        <>
                                            {(!selectTopic) ?
                                                <div className='flex justify-end'>
                                                    <div className='link-style1'
                                                         onClick={() => this.handleSelectTopic()}>
                                                        Edit History
                                                    </div>
                                                </div>
                                                :
                                                <div className='flex justify-between'>

                                                    {(selectAll) ?
                                                        <div className='link-style1'
                                                             onClick={() => this.handleUnselectAll()}>Unselect All</div>
                                                        : <div className='link-style1'
                                                               onClick={() => this.handleSelectAll()}>Select All</div>

                                                    }
                                                    <div className='flex gap-4'>
                                                        <div className='link-style1'
                                                             onClick={() => this.handleDeleteMultipleTopics()}>Delete
                                                        </div>
                                                        <div className='link-style1'
                                                             onClick={() => this.handleSelectTopic()}>Cancel
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    }
                                </div>
                                <div className='search-history-box'>
                                    {(search_history.length > 0) ?
                                        <>{search_history.map((result) => (
                                            <div key={result.search_text}>
                                                <>
                                                    <div className='search-history-text'>
                                                        <div className={(this.state.selectTopic) ? 'checkbox' : 'checkbox-hidden'}>
                                                            <input type='checkbox' id={`${result.search_text}-checkbox`}
                                                                   className='search-text-checkbox'
                                                                   onChange={() => this.handleTextCheckboxChange(result.search_text)}></input>
                                                        </div>
                                                        <div className='title link-style1 search-text'
                                                             onClick={() => this.moveToSearchResult(result.search_text)}>{result.search_text}</div>
                                                    </div>

                                                    <div className='content'>
                                                        {result.click_info.map((item) => (
                                                            <div className='search-history-topic' key={item[0]}>
                                                                <div
                                                                    className={(this.state.selectTopic) ? 'checkbox' : 'checkbox-hidden'}>
                                                                    <input id={item[0]}
                                                                           className={`topic-checkbox ${result.search_text}-topic-checkbox`}
                                                                           type='checkbox'
                                                                           onChange={(e) => this.handleCheckboxChange(e, result.search_text, item[0])}></input>
                                                                </div>
                                                                <div className='topic-text link-style1'
                                                                     onClick={() => this.moveToVerse(item[0], result.search_text)}>{this.removePrefix(item[1].topic_name)}</div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </>

                                            </div>
                                        ))}
                                        </>
                                        :
                                        <div>There is no search history</div>}
                                </div>
                            </>
                            :
                            <>
                                <Link className="homeLink" to="/">Homepage</Link>
                                <div><Link className='link-style1' to="/account/login">SIGN IN</Link> to see Previous
                                    Searches
                                </div>
                                <div>Not a Member? Register <Link className='link-style1'
                                                                  to="/account/register">HERE</Link></div>
                            </>
                        }
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isLogin: state.isLogin,
        isLoading: state.isLoading,
    };
};

export default withRouter(
    connect(mapStateToProps)(NavHeader)
);
